/* ===MIXINS=== */

@mixin transitions {
  -webkit-transition-property: width;
  -moz-transition-property: width;
  transition-property: width;
  -webkit-transition-duration: 0.5s, 0.5s;
  -moz-transition-duration: 0.5s, 0.5s;
  transition-duration: 0.5s, 0.5s;
}
@mixin transitions-color {
  -webkit-transition: color 0.3s ease;
    -moz-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
}
@mixin transitions-background-color {
  -webkit-transition: background-color 0.4s ease;
    -moz-transition: background-color 0.4s ease;
    -o-transition: background-color 0.4s ease;
    transition: background-color 0.4s ease;
}
@mixin background-cover {
  -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
@mixin rotate-90deg {
  -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -sand-transform: rotate(90deg);
    transform: rotate(90deg);
  -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    -ms-transform-origin: left center;
}
@mixin rotate-270deg {
  -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -sand-transform: rotate(270deg);
    transform: rotate(270deg);
  -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    -ms-transform-origin: left center;
}
@mixin box-shadow {
  -webkit-box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 1px 6px 0px rgba(0,0,0,0.4);
    box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.4);
}
@mixin box-sizing {
  -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
@mixin footer-shadow {
  -webkit-box-shadow: 0px 0px 10px rgba(161, 177, 159, 0.8);
    -moz-box-shadow: 0px 0px 10px rgba(161, 177, 159, 0.8);
    box-shadow: 0px 0px 10px rgba(161, 177, 159, 0.8);
}
@mixin nav-circle {
  -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
}
@mixin wordBreak(){
  -ms-word-wrap: break-all;
      word-wrap: break-all;
      word-wrap: break-word;
}
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); /* IE6 and IE7 use the wrong syntax. */
  }
  clip: rect(1px, 1px, 1px, 1px);
}
/* Turns off the element-invisible effect. */
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}
@mixin boxit ($dir) {
  display:-moz-box;
  display:-webkit-box;
  display: box;
  box-orient:$dir;
  -moz-box-orient:$dir;
  -webkit-box-orient:$dir;
}
@mixin order ($num) {
  box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-box-ordinal-group: 1;
  box-ordinal-group: #{$num};
  -moz-box-ordinal-group: #{$num};
  -webkit-box-ordinal-group: #{$num};
}
/* ===VARIABLES=== */
$green: #8b9e2d;
$cyan: #2da598;
$orange: #e47736;

$red: #da3a2b;
$grey: #9d9c98;
$lightGrey: #e1e1e1;
$lightRed: #de504b;

$darkGreen: #5c6a1a;
$darkCyan: #3c6f69;
$deepCyan: #20726a;
$darkOrange: #a8501e;

$darkBlue: #001c25;
$darkRed: #a8291e;
$darkGrey: #a3acaf;
$deepGrey: #818d91;

/* ===FUNCTIONS=== */
@function em($target, $context: 16) {
    @return ($target / $context) * 1em;
}
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px);
  }
  clip: rect(1px, 1px, 1px, 1px);
}
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}
@mixin unpublished-div {
  height: 0;
  overflow: visible;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  word-wrap: break-word; // A very nice CSS3 property
}
