#breadcrumb {
	width: 100%;
	height: auto;
	display: block;
	background: #817e7b;
	-moz-box-shadow: inset 0 0 10px #716f6c;
	-webkit-box-shadow: inset 0 0 10px #716f6c;
	box-shadow: inset 0 0 10px #716f6c;
	padding-top: 20px;

	@media (min-width: 410px) {
	  padding-top: 0;
	}
	.container {
		@media (min-width: em(320)) {
			width: auto;
			height: auto;
			padding: 0 0 0 0;
		}
		@media (min-width: em(768)) {
			padding: 20px 0 20px 0px;
			width: 92%;
		}
		@media (min-width: em(1024)) {
			height: 50px;
		}
	}
	.last-menu-holder ul li a:after {
		content: "/";
    	color: #6b6765;
    	width: 10px;
    	margin: 0 5px 0 8px;
    }
	&.green-color {
		@media (min-width: em(320)) {
			background: #817e7b;
			-moz-box-shadow: inset 0 0 10px #716f6c;
			-webkit-box-shadow: inset 0 0 10px #716f6c;
			box-shadow: inset 0 0 10px #716f6c;
		}
		@media (min-width: em(768)) {
			background: $green;
			-moz-box-shadow: inset 0 0 10px $darkGreen;
			-webkit-box-shadow: inset 0 0 10px $darkGreen;
			box-shadow: inset 0 0 10px $darkGreen;
		}
		.last-menu-holder ul li a:after {
	    	@media (min-width: em(320)) { color: #6b6765; }
	    	@media (min-width: em(768)) { color: $darkGreen; }
	    }
	}
	&.cyan-color {
		@media (min-width: em(320)) {
			background: #817e7b;
			-moz-box-shadow: inset 0 0 10px #716f6c;
			-webkit-box-shadow: inset 0 0 10px #716f6c;
			box-shadow: inset 0 0 10px #716f6c;
		}
		@media (min-width: em(768)) {
			background: $deepCyan;
			-moz-box-shadow: inset 0 0 10px #135750;
			-webkit-box-shadow: inset 0 0 10px #135750;
			box-shadow: inset 0 0 10px #135750;
		}
		.last-menu-holder ul li a:after {
	    	@media (min-width: em(320)) { color: #6b6765; }
	    	@media (min-width: em(768)) { color: #1e3835; }
	    }
	}
	&.orange-color {
		@media (min-width: em(320)) {
			background: #817e7b;
			-moz-box-shadow: inset 0 0 10px #716f6c;
			-webkit-box-shadow: inset 0 0 10px #716f6c;
			box-shadow: inset 0 0 10px #716f6c;
		}
		@media (min-width: em(768)) {
			background: $orange;
			-moz-box-shadow: inset 0 0 10px $darkOrange;
			-webkit-box-shadow: inset 0 0 10px $darkOrange;
			box-shadow: inset 0 0 10px $darkOrange;
		}
		.last-menu-holder ul li a:after {
	    	@media (min-width: em(320)) { color: #6b6765; }
	    	@media (min-width: em(768)) { color: $darkOrange; }
	    }
	}
}
.block-collapsible-menu { position: relative;}
.collapsible-menu-wrapper {
	width: 100%;
	overflow: hidden;
	line-height: em(19);
}
#breadcrumb .collapsible-menu-wrapper .collapsed-menu-holder {
	position: relative;
	overflow: hidden;
	float: left;
	margin: 0 0 0 0;
	width: auto;
	@media (min-width: em(320)) {
		width: 100%;
	}
	@media (min-width: em(768)) {
		width: auto;
	}
}
#breadcrumb .format-list .collapsed-menu-holder ul.menu.menu-column-2,
#breadcrumb .format-list .collapsed-menu-holder ul.menu.menu-column-3 {
	@media (min-width: em(320)) {
		display: none;
	}
	@media (min-width: em(768)) {
		display: inline-block;
	}
}
#breadcrumb .format-list .collapsed-menu-holder ul.menu {
	display: inline-block;
}
#breadcrumb .node-type-academic-program .collapsible-menu-wrapper .last-menu-holder{
	@media (min-width: em(320)) {
		width: 100%;
	}
	@media (min-width: em(768)) {
		width: 38%;
	}
	@media (min-width: em(1024)) {
		width: 50%;
	}
}
#breadcrumb .collapsible-menu-wrapper.menu-level-1 .last-menu-holder{
	width: 100%;
	@media (min-width: em(320)) {
		width: 100%;
	}
}
.page-node-degree #breadcrumb .collapsed-menu-holder ul.menu.menu-column-1,
.page-node-student-work #breadcrumb .collapsed-menu-holder ul.menu.menu-column-1,
.page-node-lecture-series #breadcrumb .collapsed-menu-holder ul.menu.menu-column-1,
.node-type-faculty-page #breadcrumb .collapsed-menu-holder ul.menu.menu-column-1,
.page-node-alumni #breadcrumb .collapsed-menu-holder ul.menu.menu-column-1,

.page-node-degree #breadcrumb .collapsed-menu-holder ul.menu.menu-column-4,
.page-node-student-work #breadcrumb .collapsed-menu-holder ul.menu.menu-column-4,
.page-node-lecture-series #breadcrumb .collapsed-menu-holder ul.menu.menu-column-4,
.node-type-faculty-page #breadcrumb .collapsed-menu-holder ul.menu.menu-column-4,
.page-node-alumni #breadcrumb .collapsed-menu-holder ul.menu.menu-column-4{
	@media (min-width: em(320)) {
		display: none;
	}
	@media (min-width: em(768)) {
		display: inline-block;
	}
}
.node-type-academic-program #breadcrumb .collapsed-menu-holder ul.menu.menu-column-1,
.page-node-degree #breadcrumb .collapsed-menu-holder ul.menu.menu-column-2,
.page-node-student-work #breadcrumb .collapsed-menu-holder ul.menu.menu-column-2,
.page-node-lecture-series #breadcrumb .collapsed-menu-holder ul.menu.menu-column-2,
.node-type-faculty-page #breadcrumb .collapsed-menu-holder ul.menu.menu-column-2,
.page-node-alumni #breadcrumb .collapsed-menu-holder ul.menu.menu-column-2{
	@media (min-width: em(320)) {
		display: inline-block;
	}
}
#breadcrumb .collapsible-menu-wrapper .last-menu-holder{
	@media (min-width: em(320)) {
		overflow: hidden;
		position: relative;
		clear: left;
		display: block;
		text-align: center;
		margin: 0 0 0 0;

		ul {
			padding: 25px 20px;
			height: auto;
		}
		ul li { line-height: em(24);}
	}
	@media (min-width: em(768)) {
		clear:none;
		float: left;
		text-align: left;
		margin: -4px 0 -4px 0;

		ul { padding: 0 0; }
		ul li { line-height: em(19);}
	}
	ul {
		@media (min-width: em(320)) {
			vertical-align: middle;
			display: table-cell;
			height: auto;
		}
		@media (min-width: em(768)) {
			height: 60px;
		}
	}
	ul li {
		display: inline;
		margin: 0 0 0 0;
		padding: 0;
		a {

			&:link, &:visited {
		        color: #fff;
		        font-size: em(12);
		    }
		    &:hover, &:active, &:focus {
		    	color: $darkBlue;
		    }
		}
		&.last a,
		&:last-child a {
			&:after {
		    	display: none;
		    }
		}
	}
}
#breadcrumb .collapsible-menu-wrapper a.external-link {
    background-position: 80% 18px;
}
#breadcrumb .last-menu-holder a.external-link {
    background-position: 100% 3px;
}
#breadcrumb .last-menu-holder.col-one-half {
	@media (min-width: em(320)) {
    	width: 100%;
	}
	@media (min-width: em(580)) {
    	width: 100%;
	}
    @media (min-width: em(768)) {
    	width: 33.5%;
	}
	@media (min-width: em(1024)) {
    	width: 45%;
	}
}
.page-taxonomy #breadcrumb .last-menu-holder,
.node-type-landing-page #breadcrumb .last-menu-holder{
	@media (min-width: em(320)) {
    	width: 100%;
	}
    @media (min-width: em(768)) {
    	width: 64.55%;
	}
	@media (min-width: em(1024)) {
		width: 73%;
	}
}
.page-academic-programs #breadcrumb .last-menu-holder{
	@media (min-width: em(320)) {
    	width: 100%;
	}
    @media (min-width: em(768)) {
    	width: 64.55%;
	}
	@media (min-width: em(1024)) {
		width: 75%;
	}
}
#breadcrumb .format-list .collapsed-menu-holder .menu {
	position: relative;
	border: none !important;
	background-color: $darkBlue;
	z-index: 100;
	width: auto;
	min-width: 200px;
	max-width: 230px;
  	float: left;
  	margin: 0 20px 0 0;
  	padding: 0 0 0 0;

  	@media (min-width: em(320)) {
  		width: 100%;
  		min-width: 100%;
	}
	@media (min-width: em(768)) {
		width: auto;
		min-width: 200px;
	}
	@media (min-width: em(1024)) {
		width: auto;
		min-width: 200px;
	}
}
#breadcrumb .format-list .collapsed-menu-holder .menu li {
	width: auto;
	clear: left;
	display: none;
	background-color: #fff;
	border-bottom: 1px solid $darkGrey;
}
#breadcrumb .format-list .collapsed-menu-holder .menu li:last-child {
	border-bottom: 0;
}
#breadcrumb .collapsible-menu-wrapper .collapsed-menu-holder a {
	&:link, &:visited {
        color: $darkBlue;
    }
    &:hover, &:active, &:focus {
    	color: $red;
    }
    @media (min-width: em(320)) {
    	line-height: em(20);
	  	height: 50px;
	  	vertical-align: middle;
	    display: table-cell;
  		padding: 0px 35px 0px 20px;
	}
	@media (min-width: em(768)) {
  		padding: 0px 35px 0px 15px;
	}
}
#breadcrumb .format-list .collapsed-menu-holder .menu li.active-trail {
	background-color: $darkBlue;
	border-bottom: none;
		padding: 10px 0;
}
@media (min-width: 410px){
  #breadcrumb .format-list .collapsed-menu-holder .menu li.active-trail {
    padding: 0 0 0 0 ;
  }
}
#breadcrumb .format-list .collapsed-menu-holder .menu.expanded li,
#breadcrumb .format-list .collapsed-menu-holder .menu li.active-trail {
	display: list-item;
	padding: 0 1.25rem 0 0;

}

#breadcrumb .format-list .collapsed-menu-holder .menu li.active-trail a {
	&:link, &:visited {
      color: #fff;
      background-color: $darkBlue;
  }
  &:hover, &:active, &:focus {
  	background-color: $darkBlue;
  	color: $lightRed;
  }
}
#breadcrumb .arrow-select {
	width: 25px;
	height: 25px;
	position: absolute;
	top: 12.5px;
	right: 3%;
	cursor: pointer;
	background: url("../images/ui/ui-arrows-sprites-2.png") no-repeat;
	background-position: 6px -964px;
	border-radius: 50%;
	background-color: white;

	/*@media (min-width: em(320)) {
		display: none;
	}*/
	@media (min-width: em(768)) {
		display: block;
	}
}
#breadcrumb .collapsed-menu-holder ul.expanded .arrow-select {
	background: url("../images/ui/ui-arrows-sprites-2.png") no-repeat;
	background-position: 6px -1186px;
	a {
		padding: 0;
	}
}
.collapsed-menu-holder .arrow-select {
	a:focus {
		padding: 0;
		display: block;
	}
}
#mobile-breadcrumb-toggle {
	/*@media (min-width: em(320)) {
		display: block;
		width: 63px;
		z-index: 102;
		height: 53px;
		right: 0;
		position: absolute;
	}
	@media (min-width: em(768)) {
		display: none;
	}*/
	//hiding carat altogether, per redesign RTTSA-53
	display: none;
	a {
		background: url("../images/ui/ui-mobile-breadcrumb-toggle.png") no-repeat;
		width: 63px;
		height: 53px;
		display: block;
		text-indent: -9000px;
		background-position: 0 0px;
		margin: -6px 0 0 0;
	}
	a.open{
		background-position: 0 0px;
		margin: -6px 0 0 0;
	}
	a.close{
		background-position: 0 -74px;
		margin: -2px 0 0 0;
	}
}
.block-collapsible-menu .line.close {
	background: url("../images/bg/bg-line-red.png") repeat-x;
	height: 5px;
	width: 100%;
	position: absolute;
	z-index: 101;
	top: 0;
}
.block-collapsible-menu .line,
.block-collapsible-menu .line.close {
	@media (min-width: em(320)) {
		display: block;
	}
	@media (min-width: em(768)) {
		display: none;
	}
}
#breadcrumb .collapsible-menu-wrapper .last-menu-holder ul li.program a:link,
#breadcrumb .collapsible-menu-wrapper .last-menu-holder ul li.program a:visited{
	line-height: 50px;
	height: 50px;
	vertical-align: middle;
	display: table-cell;
	padding: 0px 20px 0px 35px;
	font-weight: bold;
	font-size: 0.875em;
	background: #001c25 url("../images/ui/ui-arrows-sprites-2.png") no-repeat;
	background-position: 15px -1918px;
}
#breadcrumb .collapsible-menu-wrapper .last-menu-holder ul li.program a:hover,
#breadcrumb .collapsible-menu-wrapper .last-menu-holder ul li.program a:focus{
	color: $lightRed;
}
.node-type-gallery #breadcrumb .collapsible-menu-wrapper .last-menu-holder,
.node-type-alum #breadcrumb .collapsible-menu-wrapper .last-menu-holder,
.node-type-faculty #breadcrumb .collapsible-menu-wrapper .last-menu-holder,
.node-type-academic-degree #breadcrumb .collapsible-menu-wrapper .last-menu-holder{
	@media (min-width: em(320)) {
		width: 100%;
		ul {
			width: 100%;
			display: block;
			padding: 0 0 0 0;
		}
		ul li { text-align: left; width: 100%; }
		ul li a:link, a:visited { display: block;}
	}
	@media (min-width: em(768)) {
		ul { display: inline-block;}
		ul li a:link, a:visited { display: table-cell;}
	}
}
.page-facilities #breadcrumb,
.page-featured-work #breadcrumb{
  background: $green;
  -moz-box-shadow: inset 0 0 10px $darkGreen;
  -webkit-box-shadow: inset 0 0 10px $darkGreen;
  box-shadow: inset 0 0 10px $darkGreen;
}
