.node-continuing-education-program.view-mode-full {
	@media (min-width: em(320)) {
		margin-top: 10px;
	}
	@media (min-width: em(768)) {
		margin-top: 30px;
	}
}
.node-type-continuing-education-program #main{
	padding-bottom: 35px;
}
#main > .fader {
	background: rgba(0, 28, 37, 0.9);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 98;
}
#main.border > .fader {
	margin: -20px -20px 0;
	padding: 0 20px 20px;
}
#main > .course-popup {
	width: 68%;
	height: auto;
	right: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 99;
	margin: -20px -20px 0 0;
	@media (min-width: em(320)) {
		margin: 0 0 0 0;
		width: 100%;
	}
	@media (min-width: em(768)) {
		margin: -20px -20px 0 0;
		width: 96%;
	}
	@media (min-width: em(1024)) {
		width: 80%;
	}
	@media (min-width: em(1200)) {
		width: 68%;
	}
}
#main > .course-popup .popup-inner {
	position: relative;
	width: 100%;
	height: auto;
	background: #fff;
	float: right;
	padding: 0 0 50px 0;
}
#main > .course-popup .popup-inner::before {
	left: -10px;
	top: 430px;
	content: "";
	position: absolute;
	background: url("../images/bg/bg-cource-popup.png") no-repeat center;
	width: 10px;
	height: 100px;
}
#main > .course-popup .close {
	@media (min-width: em(320)) {
		position: absolute;
		top: 0;
		display: block;
	    right: 0;
	    bottom: 0;
	    width: 0px;
	    height: 0px;
	    text-indent: -9999px;
	    border-style: solid;
	    border-width: 0 75px 75px 0;
	    border-color: transparent $red transparent transparent;
	    _border-color: #000000 $red #000000 #000000;
	    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
	    &:after {
	    	content: 'X';
	    	text-indent: 0;
	    	color: #fff;
	    	display: block;
	    	position: absolute;
			top: 18px;
			right: -60px;
	    	font-size: em(26);
	    }
	}
	@media (min-width: em(768)) {
		text-indent: 0;
		cursor: pointer;
		display: inline-block;
		margin: 0;
		right: inherit;
		top: 555px;
		left: -30px;
		background-color: transparent;
		width: auto;
	    border-style: none;
	    border-width: none;
	    border-color: none;
		-webkit-transform: rotate(270deg); 
		-moz-transform: rotate(270deg);
		-o-transform: rotate(270deg);
		-ms-transform: rotate(270deg);
    	-sand-transform: rotate(270deg);
    	transform: rotate(270deg);
		-webkit-transform-origin: left center;
		-moz-transform-origin: left center;
		-o-transform-origin: left center;
		-ms-transform-origin: left center;
		 &:after {
		 	display: none;
		 }
	}
}
#main > .course-popup a.close:link {
	font-size: em(16);
	color: #fff;
}
#main > .course-popup a.close:hover {
	color: $red;
}
#main > .course-popup .node-section {
	border-top: 1px solid #375459;
	clear: both;
	@media (min-width: em(320)) {
		padding: 20px 25px 20px 25px;
	}
	@media (min-width: em(768)) {
		padding: 27px 40px 40px 40px;
	}
}
#main > .course-popup .node-section:first-child {
	border-top: none;
	@media (min-width: em(320)) {
		padding: 20px 25px 40px 25px;
	}
	@media (min-width: em(768)) {
		padding: 50px 40px 50px 40px;
	}
	@media (min-width: em(1024)) {
		padding: 50px 100px 70px 40px;
	}
}
#main > .course-popup h5{
	color: $darkBlue;
}
#main > .course-popup .field-featured-image img{
	height: 387px;
	@media (min-width: em(320)) {
		width: 100%;
	    height: auto;
	    display: block;
	    -ms-interpolation-mode: bicubic;
	}
	@media (min-width: em(768)) {
		width: auto;
		height: 387px;
	}
}
#main > .course-popup .course-properties h5{
	color: #817e7b;
	display: inline-block;
}
#main > .course-popup .course-properties > div {
	padding: 3px 0;
	display: inline-block;
}
#main > .course-popup .course-properties > div > div {
	display: inline;
}
#main > .course-popup .course-properties {
	margin: 6px 0 0 0;
	padding: 0 0 10px 0;
}
#main > .course-popup .course-properties > div > div {
	color: #051c24;
	margin: 0 7px 0 0;
	padding: 0 10px 0 0;
	background: url("../images/bg/bg-cource-property.png") no-repeat 100% 4px;
	font-weight: bold;
}
#main > .course-popup .course-properties a {

}
#main > .course-popup .course-properties > div > div:last-child {
	background: none;
}
#main > .course-popup .field-featured-image {
	@media (min-width: em(320)) {
		height: auto;
	}
	@media (min-width: em(768)) {
		height: 380px;
    margin-top: 1em;
	}
}
#main > .course-popup .video {
	width: 765px;
	position: relative;
	margin: 20px 0 0;
	@media (min-width: em(320)) {
		width: auto;
	}
	@media (min-width: em(1024)) {
		width: 765px;
	}
}
#main > .course-popup .video img {
	height: 428px;
	@media (min-width: em(320)) {
		width: 100%;
		height: auto;
	}
	@media (min-width: em(768)) {
		height: 382px;
	}
	@media (min-width: em(1024)) {
		width: auto;
		height: 428px;
	}
}
#main > .course-popup .video a.play-btn {
	background: url("../images/ui/ui-video-sprites.png") no-repeat scroll 0 0 transparent;
	display: block;
	height: 96px;
	left: 50%;
	margin: -48px 0 0 -47px;
	overflow: hidden;
	position: absolute;
	text-indent: -9999px;
	top: 50%;
	width: 95px;
}
#main > .course-popup .video a.play-btn:hover {
	background-position: 0 -152px; 
}
#main > .course-popup .node-section h2 {
	display: inline-block;
	padding-bottom: 20px;
	margin-right: 15px;
}
#main > .course-popup .info-boxes-wrapper .ico {
	margin: 0 3px -5px 0;
	background: url("../images/ui/ui-icons-sprites-2.png") no-repeat -1px -1190px;
}
#main > .course-popup .info-boxes-wrapper .ico.field_register_fax {
	background-position: -1px -1274px;
}
#main > .course-popup .info-boxes-wrapper .ico.field_register_mail {
	background-position: -1px -1358px;
}
#main > .course-popup .info-boxes-wrapper .ico.field_register_person {
	background-position: -1px -1442px;
}
#main > .course-popup .info-boxes-wrapper {
	margin: 0 0 0 0;
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter {
	@media (min-width: em(320)) {
		padding: 10px 0 10px 0px;
		border-left: none;
		border-bottom: 1px solid #E7E7E7;
	}
	@media (min-width: em(768)) {
		padding: 15px 0 15px 8px;
	}
	@media (min-width: em(1024)) {
		border-bottom: none;
		border-left: 1px solid #E7E7E7;
	}
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter {
  @media (min-width: em(320)) {
    width: 100%;
    height: auto;
  }
  @media (min-width: em(768)) {
    width: 50%;
    min-height: 145px;
  }
  @media (min-width: em(1024)) {
    width: 25%;
  }
}
#main > .course-popup .info-boxes-wrapper h5 {
	display: inline-block;
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter:first-child {
	border-left: none;
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter p {
	font-size: em(12);
	line-height: 18px;
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter p:last-child {
	font-size: em(13);
	line-height: 20px;
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter p:last-child em {
	color: #6a6a6a;
	font-family: proxima-nova;
	font-weight: 600;
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter p:last-child strong {
	font-family: proxima-nova;
	font-weight: 600;
	font-size: em(12);
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter address {
	font-size: em(14);
	line-height: 20px;
	font-style: normal;
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter a.button.more {
	font-size: em(14);
	line-height: 20px;
	border: none;
	background: url("../images/ui/ui-arrows-sprites.png") no-repeat scroll 90% -428px transparent;
	padding: 0 2.125em 0 0;
}
#main > .course-popup .supply-list {
	float: left;
	@include box-sizing;

	@media (min-width: em(320)) {
		border-right: none;
		padding: 0 20px 20px 0;
		border-bottom: 1px solid #e7e7e7;
	}
	@media (min-width: em(480)) {
		border-right: 1px solid #e7e7e7;
		border-bottom: none;
	}
}
#main > .course-popup .class-notes {
	float: left;
	@include box-sizing;
	@media (min-width: em(320)) {
		padding: 20px 0 0 0;
	}
	@media (min-width: em(480)) {
		padding: 0 0 0 20px;
	}
}
#main > .course-popup .class-notes h3 {
	font-size: em(11);
	line-height: 18px;
	text-transform: uppercase;
	display: inline-block;
	font-family: proxima-nova;
}
#main > .course-popup .class-notes p {
	font-size: em(12);
	line-height: 18px;
}
#main > .course-popup .field-supply-list ul,
#main > .course-popup .class-notes ul {
	font-size: em(15);
	line-height: 22px;
}
#main > .course-popup .field-supply-list ul li,
#main > .course-popup .class-notes ul li {
	font-size: em(15);
	line-height: 22px;
	background: url(../images/ui/ui-arrows-sprites.png) no-repeat 0 -288px;
	padding: 4px 0 4px 10px;
}
.section-programs.page-views .main-content.contextual-links-region,
.section-featured-work.page-views .main-content.contextual-links-region {
	position: static;
}
/* Instructor Slide */
#instructor-slide {
	margin: 45px 0 0 0;
}
#instructor-slide .flexslider {
	width: 100%;
	height: auto;
	img { padding-top: 0; }
}
#instructor-slide .slide-control {
	@media (min-width: em(320)) {
		top: -21px;
		right: -0;
	}
	@media (min-width: em(768)) {
		right: -20px;
	}
}
#instructor-slide .flexslider .flex-direction-nav {
	right: 5px;
	top: 15px;
}
#main > .course-popup .profile-box h2 {
	margin: 5px 0 0 0;
	padding: 0 0 0 0;
}
#main > .course-popup .profile-box .bio {
	margin: 15px 0 0 0;
}