/* FlexSlider Necessary Styles */
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides>li,
.gallerySlider .slides>li {
  display: none;
  -webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img,
.gallerySlider .slides img {
  height: auto;
  max-width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides>li:first-child {
  display: block;
}

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 0;
  }

  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 0;
  }
}

/* Flexslider */
.flexslider {
  position: relative;
  height: auto;
}

.flexslider {
  img {
    @media (min-width: em(320)) {
      padding-top: 25px;
      width: 96.888%;
      width: 100%;
    }

    @media (min-width: em(1024)) {
      width: 96.888%;
    }
  }
}

.flexCarousel ul.slides>li {
  @media (min-width: em(320)) {
    margin: 0 0 0 0;
  }

  @media (min-width: em(768)) {
    margin: 0 0 0 20px;
  }
}

.flexslider .slide-control {
	position: absolute;
	top: 0;
	right: -1px;
	width: 100%;
	height: 16%;
	overflow: hidden;
	display: flex;
	align-items: center;
	z-index: 10;
	background-color: #a82a1e;

	h2 {
		text-align: left;
		position: absolute;
		//top: 15%;
		left: 1.25rem;
		text-align: left;
	}

	@media (min-width: em(320)) {
		right: 0;
		width: 100%;
		//height: 190px;
		h2 {
			//top: 18%;
			//width: 38%;
			//right: 16.88%;
			font-size: 1.375em;
		}
	}
	
	@media (min-width: em(768)) {
		right: -22px;
		width: 301px;
		height: 301px;
		background: url(../images/bg/bg-slide-control.png) no-repeat;
		background-position: 0 -991px;
		width: 301px;
		top: 0;
		display: block;

		h2 {
			top: 15%;
			width: 55%;
			right: 40px;
			left: inherit;
			font-size: 1.500em;
			text-align: right;
		}
	}
	@media (min-width: em(1024)) {
		right: -1px;
	}
}

.col2 .flexslider .slide-control {
  @media (min-width: em(320)) {
    right: 0;
  }

  @media (min-width: em(768)) {
    right: -22px;
  }

  @media (min-width: em(1024)) {
    right: -8px;
  }
}

/* Direction Nav */

.flexslider ul.flex-direction-nav li,
.flex-slide ul.flex-direction-nav li,
.flexCarousel ul.flex-direction-nav li,
.gallerySlider ul.flex-direction-nav li {
  display: inline-block;
}

.flexslider ul.flex-direction-nav li a,
.flex-slide ul.flex-direction-nav li a,
.flexCarousel ul.flex-direction-nav li a,
.gallerySlider ul.flex-direction-nav li a,
  {
  width: 42px;
  height: 42px;
  overflow: hidden;
  display: block;
  text-indent: -9999px;
  background: url(../images/ui/ui-arrows-sprites-2.png) no-repeat;
  @include nav-circle;
}

.flexslider ul.flex-direction-nav li a.flex-prev,
.flex-slide ul.flex-direction-nav li a.flex-prev,
.flexCarousel ul.flex-direction-nav li a.flex-prev,
.gallerySlider ul.flex-direction-nav li a.flex-prev {
  background-position: 40% 7px;
}

.flexslider ul.flex-direction-nav li a.flex-next,
.flex-slide ul.flex-direction-nav li a.flex-next,
.flexCarousel ul.flex-direction-nav li a.flex-next,
.gallerySlider ul.flex-direction-nav li a.flex-next {
  background-position: 70% -409px;
}

.flexslider ul.flex-direction-nav li a.flex-prev:hover,
.flexslider ul.flex-direction-nav li a.flex-prev:focus,
.flex-slide ul.flex-direction-nav li a.flex-prev:hover,
.flex-slide ul.flex-direction-nav li a.flex-prev:focus,
.flexCarousel ul.flex-direction-nav li a.flex-prev:hover,
.flexCarousel ul.flex-direction-nav li a.flex-prev:focus,
.gallerySlider ul.flex-direction-nav li a.flex-prev:hover,
.gallerySlider ul.flex-direction-nav li a.flex-prev:focus {
  background-position: 40% -76px;
  background-color: #fff;
}

.flexslider ul.flex-direction-nav li a.flex-next:hover,
.flexslider ul.flex-direction-nav li a.flex-next:focus,
.flex-slide ul.flex-direction-nav li a.flex-next:hover,
.flex-slide ul.flex-direction-nav li a.flex-next:focus,
.flexCarousel ul.flex-direction-nav li a.flex-next:hover,
.flexCarousel ul.flex-direction-nav li a.flex-next:focus,
.gallerySlider ul.flex-direction-nav li a.flex-next:hover,
.gallerySlider ul.flex-direction-nav li a.flex-next:focus {
  background-color: #fff;
  background-position: 70% -491px;
}

ul.flex-direction-nav li a:hover,
ul.flex-direction-nav li a:focus {
  background-color: #fff;
}

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.flex-direction-nav {
  position: absolute;
  top: 0em;
  right: 0em;
  z-index: 60;
}

.flexslider .flex-direction-nav {
  top: 21.5%;
  right: 6%;

  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(768)) {
    right: 3.77%;
    display: block;
  }

  @media (min-width: em(1024)) {
    right: 6.55%;
  }
}

.flexCarousel .flex-direction-nav,
.flex-slide .flex-direction-nav {
  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(768)) {
    display: block;
  }
}

/* Prospective Students - Slide Nav arrow */
#audienceFloor1 .slide-control {
  background-position: 0 0;
}

#audienceFloor1 .flex-direction-nav li a.flex-prev:hover,
#audienceFloor1 .flex-direction-nav li a.flex-prev:focus {
  background-position: 40% -160px;
}

#audienceFloor1 .flex-direction-nav li a.flex-next:hover,
#audienceFloor1 .flex-direction-nav li a.flex-next:focus {
  background-position: 70% -575px;
}

/* Current Student - Slide Nav arrow */
#audienceFloor2 .slide-control {
  background-position: 0 -330px;
}

#audienceFloor2 .flex-direction-nav li a.flex-prev:hover,
#audienceFloor2 .flex-direction-nav li a.flex-prev:focus {
  background-position: 40% -244px;
}

#audienceFloor2 .flex-direction-nav li a.flex-next:hover,
#audienceFloor2 .flex-direction-nav li a.flex-next:focus {
  background-position: 70% -659px;
}

/* Alumni & Friends - Slide Nav arrow */
#audienceFloor3 .slide-control {
  background-position: 0 -661px;
}

#audienceFloor3 .flex-direction-nav li a.flex-prev:hover,
#audienceFloor3 .flex-direction-nav li a.flex-prev:focus {
  background-position: 40% -327px;
}

#audienceFloor3 .flex-direction-nav li a.flex-next:hover,
#audienceFloor3 .flex-direction-nav li a.flex-next:focus {
  background-position: 70% -742px;
}

#audienceFloor1 .flex-direction-nav li a:hover,
#audienceFloor2 .flex-direction-nav li a:hover,
#audienceFloor3 .flex-direction-nav li a:hover,
#audienceFloor1 .flex-direction-nav li a:focus,
#audienceFloor2 .flex-direction-nav li a:focus,
#audienceFloor3 .flex-direction-nav li a:focus {
  background-color: #fff;
}

/* Control Nav */
.flexCarousel .flex-control-nav,
.flex-slide .flex-control-nav {
  display: none;
}

.flex-control-nav {

	@media (min-width: em(320)) {
		position: absolute;
		right: 0;
		z-index: 55;
		text-align: left;
		//display: block;
		display: none;
		top: 93px;
		width: 70px;
	}
	@media (min-width: em(768)) {
		width: 24%;
		//display: none;
	}
}

.flex-control-nav li {
  margin: 0 3px;
  display: inline-block;
  zoom: 1;
}

.flex-control-paging li a {
  width: 7px;
  height: 7px;
  display: block;
  background: #f9f9f8;
  background: rgba(249, 249, 249, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}

.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}

.flex-control-paging li a.flex-active {
  width: 9px;
  height: 9px;
  background: #fff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.7);
  cursor: default;
}

/* Flexcaption */
.flex-caption {
	position: absolute;
	background: #fff; 
	height: 3.125em; 
	min-height: 3.125em; 
	margin: 0;
	//padding: 0 15px 0 0;
	padding: 0 0 0 2%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	h4 {
		font-size: 0.938em;
		margin-bottom: 0;
		text-transform: capitalize;
		display: block;
		max-width: 82%;
		@media (min-width: em(768)) {
			max-width: 99%;
		}
		a { letter-spacing: 0; }
	}
	a {
		font-size: em(16);
		//line-height: 3.425em;
		background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
		background-position: 100% -415px;
		padding: 0 ;
		display: inline-block;

        &:link, &:visited {
            color: $darkBlue;
        }
        &:hover, &:focus, &:active {
        	color: $red;
        }
    }
    @media (min-width: em(320)) {
    	position: relative;
			max-width: 100%;
		}
		@media screen and (min-width: em(475)) {
			padding: 0 0 0 1.250em;
		}
		@media screen and (min-width: em(768)) {
			line-height: 3.425em;
			padding: 0 1.500em 0 1.250em;
		}
	.flex-count {
		min-width: 16%;
		padding: 0;
		background-color: #001d25;
		color: white;
		vertical-align: middle;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-weight: 400;
		font-size: 1.125rem;
		span {
			color: #a82a1e;
			font-weight: 600;
		}
		@media (min-width: em(768)) {
			display: none;
		}
	}
	@media (min-width: em(768)) {
		position: absolute;
		min-width: 66%;
		max-width: 66%;
		padding: 0 15px 0 1.25rem;
	}
	@media (min-width: em(1024)) {
		min-width: 205px;
		max-width: 66%;
	}
}

/* Video Gallery START */
.video-gallery .video {
  @media (min-width: em(320)) {
    position: relative;
    float: left;
    margin: 0 12% 18px 12%;
  }

  @media (min-width: em(768)) {
    margin: 0 0 18px 18px;
  }

  @media (min-width: em(1024)) {
    margin: 0 0 18px 0;
  }
}

.video-gallery .video-wide {
  @media (min-width: em(320)) {
    width: 100%;
    float: left;
    margin: 0 20px 20px 0;
  }

  @media (min-width: em(1024)) {
    width: 620px;
  }
}

.video-wide .video-wrapper {
  width: 100%;

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    border: 1px solid #000;
  }
}

.video-gallery .video-wrapper {
  float: left;
  position: relative;
}

.video-gallery .video a.play-btn {
  left: 50%;
  margin: -48px 0 0 -47px;
  top: 50%;
}

.video-gallery .video .flex-info {
  background: #FFFFFF;
  float: left;
  height: 93px;
  padding: 12px 16px;
  width: 126px;
}

.video-gallery .video-wide .flex-info {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: auto;
}

.video-gallery .flex-info h3 {
  font-size: em(14);
  line-height: 19px;
  color: #da3a2b;
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 0;
}

.video-gallery .video p {
  font-size: em(12);
  line-height: 16px;
  color: #001c25;
}

.video-gallery .video-wide h3 {
  font-size: em(20);
  line-height: 26px;
}

.video-gallery .video-wide p {
  font-size: em(14);
  line-height: 20px;
  padding: 0 0;
  font-weight: bold;
}

.video-gallery-block {
  background: url("../images/bg/bg-video-gallery.png") no-repeat 0 0;
  background-size: 100% 100%;

  @media (min-width: em(320)) {
    background: none;
  }

  @media (min-width: em(1024)) {
    background: url("../images/bg/bg-video-gallery.png") no-repeat 0 0;
  }
}

.video-gallery-block.border {
  border-bottom: none;
}

.view-video-gallery {
  position: relative;
}

.view-video-gallery h1 {
  @media (min-width: em(320)) {
    margin: 40px 0 30px 0;
    font-size: em(28);
    padding: 0 0 0 25px;
  }

  @media (min-width: em(768)) {
    font-size: em(65);
    padding: 0 0 0 0;
  }

  @media (min-width: em(1024)) {
    margin: 40px 0 15px 0;
  }
}

.view-video-gallery h1 span {
  @media (min-width: em(320)) {
    right: 0;
    display: block;
    height: 3px;
    background: #fff;
    z-index: -1;
    position: relative;
    top: 10px;
    width: 100%;
  }

  @media (min-width: em(1024)) {
    position: absolute;
    top: 43px;
    width: 66%;
  }
}

.view-video-gallery .gallerySlider {
  min-height: 437px
}

.view-video-gallery .gallerySlider .slides {
  height: auto;
}

.view-video-gallery .gallerySlider .slides li.flex-active-slide {
  @media (min-width: em(320)) {
    margin-left: 0;
  }

  @media (min-width: em(1024)) {
    margin-left: 10px;
  }
}

.video-gallery .gallerySlider .flex-direction-nav {
  height: 42px;
  position: absolute;
  right: 0;
  top: -83px;
  z-index: 1;
  text-align: right;
}

.video-gallery .gallerySlider ul.flex-direction-nav li a {
  text-indent: 9999px;
}

.video-gallery .gallerySlider ul.flex-direction-nav li a.flex-prev {
  background-position: 40% -1782px;
}

.video-gallery .gallerySlider ul.flex-direction-nav li a.flex-next {
  background-position: 70% -1865px;
}

.video-gallery .gallerySlider ul.flex-direction-nav li a:hover {
  background-color: #001c25;
}

.video-gallery .gallerySlider ul.flex-direction-nav li a.flex-prev:hover,
.video-gallery .gallerySlider ul.flex-direction-nav li a.flex-prev:focus {
  background-position: 40% 7px;
}

.video-gallery .gallerySlider ul.flex-direction-nav li a.flex-next:hover,
.video-gallery .gallerySlider ul.flex-direction-nav li a.flex-next:focus {
  background-position: 70% -409px;
}

.gallerySlider .slides li.flex-active-slide img {
  display: block !important;
}

// The "FADE" way
.flexslider .slides { 
  li:not(.flex-active-slide) {
    display: none !important;
  }
	@media screen and (max-width: 767px) {
		li {
			//opacity: 1 !important;
			img {
				width: 92% !important;
			}	
		}
		li:not(.flex-active-slide) {
			img {
				margin: 0 0 0 92% !important;
			}
		}
		.flex-active-slide, .flex-active-slide + li {
			opacity: 1 !important;
		}
		.flex-active-slide {
			z-index: 3 !important;
		}
		.flex-active-slide + li {
			z-index: 2 !important;
		}
		li:first-child {
			&:not(.flex-active-slide) {
				opacity: 1 !important;
				z-index: 1;
			}
		}
	}
}

