footer {
	z-index: 1001;
	.region-footer {
		color: #c0c0c0;
		width: 100%;
		height: auto;
		position: relative;
		background: #222222;

		&:before {
			width: 100%;
			height: 98px;
			background: url("../images/bg/bg-footer.png") no-repeat;
			background-size: 100% 100%;
			content: "";
			display: block;
			position: absolute;
			top: -75px;
			left: 0;
			z-index: 100;
		}
	}
	p { font-size: em(12);}
}
#footer {
	margin-bottom: -1px;
}
footer .region-footer {
	@media (min-width: em(320)) {
		border-top: 15px solid #fff;
		height: auto;
		overflow: hidden;
		&:before {
			display: none;
		}
		.container {
			padding: 30px 0 0 0;
		}
	}
	@media (min-width: em(768)) {
		border-top: none;
		overflow: inherit;
		padding-bottom: 60px;
		&:before {
			display: block;
		}
		.container {
			padding: 65px 0 0 0;
		}
	}
	@media (min-width: em(1024)) {
		padding-bottom: 90px;
	}
}
#footer .container {
  margin: 0 auto; 
  max-width: 1200px;
  width: 940px;
  
  @media (min-width: em(320)) {
    width: 100%;
	text-align: center;
  }
  @media (min-width: em(768)) {
    width: 92%;
  }
  @media (min-width: em(1024)) {
    width: 940px;
  }
}
#footer {
	:not(#bottom-highlight) {
		.container {
			@media (min-width: em(1600)) {
				width: 93%;
				display: grid;
				grid: auto auto / 33% 1fr auto;
				.col-one-third {
					width: 100%;
				}
				.footer-nav {
					grid-column: 1/3;
				}
			  }
		}
	}
}
#footer .block-address {
	//float: left;
	display: inline-block;
	.content {
		padding-left: 1.75em;
		@media (min-width: em(320)) {
			padding-left: 0;
		}
		@media (min-width: em(768)) {
			padding-left: 1.75em;
		}
	}
	h1 {
		font-size: em(23);
		line-height: 1.2em;
		letter-spacing: em(1);
		margin: 0 0 7px 0;
		color: #ffffff;
		border-bottom: 0;
		font-family: "bebas-neue",sans-serif;
		font-weight: normal !important;
		font-style: normal !important;

		&:before{
			background: url(../images/ui/ui-footer-icon-location.png) no-repeat;
			background-position: 0 0;
			content: '';
			display: inline-block;
			width: 15px;
			height: 19px;
			padding-left: 10px;
			margin-left: -25px;
		}
	}
	h2 {
		text-transform: uppercase;
		color: #e2e2e2;
		font-size: em(11);
		letter-spacing: 0.125em;
		font-weight: 900;
		margin: 0 0 -5px 0;
		padding: 0 0;
		border: none;
	}
	@media (min-width: em(320)) {
		text-align: center;
	}
	@media (min-width: em(768)) {
		text-align: left;
	}
}
.block-footer-contact {
	//float: left;
	.content ul li { margin: 10px 0;}
	.content ul li a {
		padding: 2px 30px;
		background: url(../images/ui/ui-footer-sprites.png) no-repeat;
		background-position: 0 -221px;
		&:link, &:visited{
			font-weight: normal;
			font-size: em(12);
			color: #fff;
		}
		&:hover, &:focus{
			color: $red;
		}
		&.directory {
			background-position: 0 -221px;
		}
		&.maps {
			background-position: 0 -259px;
		}
		&.phone {
			background-position: 0 -300px;
		}
	}
	@media (min-width: em(320)) {
		text-align: center;
		border-top: 1px solid #303030;
		margin: 15px 0 0 0;
		padding: em(15) 0 0 0;
	}
	@media (min-width: em(480)) {
		text-align: center;
		border-top: none;
		border-left: 1px solid #303030;
		margin: 0 0 0 0;
		padding: 0 0 0 0;
	}
	@media (min-width: em(768)) {
		text-align: left;
		border-left: none;
	}
}
.block-footer-connect{
	//float: left;
}
.block-footer-connect .content{
	ul {
		width: 242px;
		margin: 0 auto;
	}
	ul li { 
		margin: 10px 7px 10px 7px;
		float: left;
	}
	ul li a {
		background: url(../images/ui/ui-footer-sprites.png) no-repeat;
		height: 32px;
		width: 31px;
		transition: all 0.2s; -moz-transition: all 0.2s; -webkit-transition: all 0.2s; -o-transition: all 0.2s;

		&:link, &:visited{
			text-indent: -9999px;
			font-weight: normal;
			font-size: em(12);
			color: #fff;
			display: block;
			overflow: hidden;
		}
		&.facebook {
			background-position: 0 -170px;
		}
		&.twitter {
			background-position: 0 -85px;
		}
		&.flickr {
			background-position: 0 -128px;
		}
		&.youtube {
			background-position: 0 -42px;
		}
		&.rss {
			background-position: 0 0px;
		}
	}
}
.block-footer-connect .content ul li:hover a {margin-top: -6px;}
.block-footer-connect {
	@media (min-width: em(320)) {
		border-top: 1px solid #303030;
		margin: 15px 0 0 0;
		padding: em(15) 0 em(15) 0;
	}
	@media (min-width: em(768)) {
		border-top: none;
		margin: 0;
		padding: 0;
	}
}
#footer .block-footer-connect.col-one-third {
  @media (min-width: em(320)) {
    width: auto;
  }
  @media (min-width: em(480)) {
	width: 100%;
    float: unset;
	justify-content: center;
  }
  @media (min-width: em(768)) {
    width: 37%;
	justify-content: flex-end;
  }
  @media (min-width: em(1600)) {
	width: auto;
  }
}
footer#footer {
	@media (min-width: em(320)) {
		.region-footer-bottom {
			overflow: hidden;
			position: relative;
			background: #070707;
			.container {
				padding: 0 0 0 0;
			}
		}
	}
	@media (min-width: em(768)) {
		.region-footer-bottom {
			width: 100%;
			left: 0;
			padding: em(15) 0;
		}
	}
}
#footer .col-one-third {
  @media (min-width: em(320)) {
    width: 100%;
  }
  @media (min-width: em(480)) {
    width: 49.8%;
	float: left;
  }
  @media (min-width: em(768)) {
    width: 33%;
	&.block-footer-contact {
		width: 29%;
	}
  }
  @media (min-width: em(1600)) {
	&.block-footer-contact {
		width: 33%;
	}
  }
}
.footer-nav {
	@media (min-width: em(320)) {
		float: left;
		width: 100%;
	}
	@media (min-width: em(768)) {
		padding: 0 0 15px 0;
		margin: 0 0 15px 0;
		border-bottom: 1px solid #1a1a1a;
	}
	@media (min-width: em(1024)) {
		width: 62%;
		padding: 0;
		margin: 0;
		border-bottom: none;
	}
}
.footer-nav ul.menu {
	@media (min-width: em(320)) {
		li {
			text-transform: uppercase;
			letter-spacing:1px;
			display: block;
			padding: 0;
			margin: 0;
		}
		a {
			display: block;
			padding: 15px 0;
			text-align: center;
			border-bottom: 1px solid #1a1a1a;

	        &:link, &:visited {
	            color: #fff;
	            font-size: em(10);
	            font-weight: 400;
	        }
	        &:hover, &:active {
	        	background-color: #202020;
	        }
	    }
	}
}
.footer-nav ul.menu {
	@media (min-width: em(768)) {
		li {
			display: inline-block;
		}
		a {
			padding: 0 10px;
			text-align: left;
			border-bottom: none;
			&:link, &:visited {
	        }
	        &:hover, &:focus, &:active {
	        	background-color: transparent;
	        	text-decoration: underline;
	        }
	    }
	}
}
.block-copyrights {
	color: #c0c0c0;
    p { 
    	padding: 0;
    }
    @media (min-width: em(320)) {
    	font-size: em(14);
		float: right;
    	background: #292929;
		display: block;
		width: 100%;
		text-align: center;
		padding: 15px 0;
	}
	@media (min-width: em(768)) {
		background: transparent;
		display: inline-block;
		width: 100%;
		text-align: center;
		padding: 0 0;
	}
	@media (min-width: em(1024)) {
		width: auto;
		text-align: right;
	}
}

.footer-social-links {
	display: inline-flex;
	&__icons {
		a {
			img {
				width: 48px;
			}
		}
	}
	.footer-social-links__subscribe {
		display: inline-flex;
		align-items: center;
		font-size: 14px;
		font-weight: 600;
		width: calc(100% - 1rem);
		border: 1px solid #c5c5c5;
		border-radius: 10px;
		justify-content: center;
		padding: .25rem 0;
		margin-top: 1.25rem;
		color: white;
		&:hover {
			color: white;
			background-color: $darkRed;
			cursor: pointer;
		}
		&:after {
			background: url(/sites/tyler/themes/tyler/images/ui/ui-arrows-sprites.png) no-repeat;
			background-position: -1px -1051px;
			content: '';
			width: 20px;
			height: 20px;
			display: inline-flex;
			left: 16px;
			position: relative;
			justify-content: center;
			align-items: center;
		}
		
	}
	@media screen and (max-width: 767px) {
		text-align: center;
	}
	@media screen and (min-width: 768px) {
		text-align: left;
	}
	a {
		margin: 0 .5rem;
	}
}