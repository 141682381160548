@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 320px),
only screen and (min--moz-device-pixel-ratio: 1.5) and (min-width: 320px),
only screen and (-o-min-device-pixel-ratio: 3 / 2) and (min-width: 320px),
only screen and (min-device-pixel-ratio: 1.5) and (min-width: 320px),
only screen and (min-resolution: 1.5dppx) and (min-width: 320px) {

  .logo .logo-temple {
    background: url("../images/TU_Primary_Red_Black.png") no-repeat;
    background-size: 75px;
  }

  .logo .logo-tyler {
    background: url("../images/x2/logo-tyler-@x2.png") no-repeat;
    background-size: 195px 26px;
  }

  #mobile-nav-toggle.control a {
    background: url("../images/x2/nav-control-sprites@2x.png") no-repeat;
    background-size: 100%;
    background-position: top right;
  }

  #mobile-nav-toggle.control.open a {
    background: url("../images/x2/nav-control-sprites@2x.png") no-repeat;
    background-size: 100%;
    background-position: 0 -66px;
  }

  #utility .navigation.utility ul.menu a {
    //background: url("../images/x2/ui-icons-sprites-@x2.png") no-repeat;
    background-size: 33px auto;
  }

  a.link.more,
  a.button.more,
  .navigation.primary ul.menu li.expanded>ul li a,
  .navigation.audience ul.menu li.expanded>ul li a,
  .image span.roll a,
  #show-hide a:link,
  #show-hide a:visited,
  .block-program-quick-links a,
  .block-quick-links .content ul li a {
    background: url("../images/x2/ui-arrows-sprites-@x2.png") no-repeat;
    background-size: 28px auto;
  }

  a.link.more {
    background-position: 100% -431px;
  }

  a.button.more {
    background-position: 98% -429px;
  }

  #block-events a.calendar,
  #block-blog a.blog,
  a.button.calendar {
    background: url("../images/x2/ui-icons-sprites-@x2.png") no-repeat;
    background-size: 33px auto;
  }

  span.label a.calendar {
    background: url("../images/x2/ui-icons-sprites-@x2.png") no-repeat;
    background-position: 150% -105px;
    background-size: 33px auto;
  }

  span.label a.blog {
    background: url("../images/x2/ui-icons-sprites-@x2.png") no-repeat;
    background-position: 150% -435px;
    background-size: 33px auto;
  }

  .block-search input[type="submit"],
  .block-search form input.form-submit {
    background: url("../images/x2/ui-arrows-sprites-@x2.png") no-repeat;
    background-size: 28px auto;
    background-position: -1px -1316px;
  }

  #mobile-quicklink-toggle {
    background: #fff url("../images/x2/ui-arrows-sprites-@x2.png") no-repeat;
    background-size: 28px auto;
    background-position: 96% -730px;
  }

  .navigation.primary ul.menu>li a {
    background: url("../images/x2/ui-arrows-sprites-2-@x2.png") no-repeat;
    background-size: 20px auto;
    background-position: 96% -955px;
  }

  .flexslider ul.flex-direction-nav li a,
  .flex-slide ul.flex-direction-nav li a,
  .flexCarousel ul.flex-direction-nav li a,
  .gallerySlider ul.flex-direction-nav li a {
    background: url("../images/x2/ui-arrows-sprites-2-@x2.png") no-repeat;
    background-size: 20px auto;
  }

  #main .ui-datepicker .ui-datepicker-prev,
  #main .ui-datepicker .ui-datepicker-next {
    background: url("../images/x2/ui-arrows-sprites-2-@x2.png") no-repeat 0 -416px;
    background-size: 20px auto;
  }

  #main .ui-datepicker .ui-datepicker-prev {
    background-position: 0 0;
  }

  .navigation.primary ul.menu li.expanded>ul li a,
  .navigation.audience ul.menu li.expanded>ul li a {
    background-position: 100% -434px;
  }

  #block-events a.calendar {
    background-position: 106% -197px;
  }

  #block-blog a.blog {
    background-position: 110% -525px;
  }

  .image span.roll a {
    background-position: 115% -1027px;
  }

  #show-hide a.more {
    background-position: 100% -173px;
  }

  #show-hide a.less {
    background-position: 100% -232px;
  }

  .block-program-quick-links a {
    background-position: 98% -429px;
  }

  .block-quick-links .content ul li a {
    background-position: 100% -431px;
    padding-right: 20px;
  }

  .block-quick-links .content ul.expanded li a {
    background: none;
    padding-right: 0;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 768px),
only screen and (min--moz-device-pixel-ratio: 1.5) and (min-width: 768px),
only screen and (-o-min-device-pixel-ratio: 3 / 2) and (min-width: 768px),
only screen and (min-device-pixel-ratio: 1.5) and (min-width: 768px),
only screen and (min-resolution: 1.5dppx) and (min-width: 768px) {

  .logo .logo-temple {
    background: url("../images/TU_Primary_Red_Black.png") no-repeat;
    background-size: 120px;
  }

  .logo .logo-tyler {
    background: url("../images/x2/logo-tyler-@x2.png") no-repeat top right;
    background-size: 210px 28px;
  }

  #utility .navigation.utility ul.menu {
    background: #e9e9e9 url("../images/x2/bg-top-menu-sprites@2x.png") repeat-x;
    background-position: 0 -55px;
    background-size: 82px auto;
  }

  #utility .navigation.utility ul.menu:before {
    background: url("../images/x2/bg-top-menu-sprites@2x.png") no-repeat;
    background-position: 0 -6px;
    background-size: 82px auto;
  }

  .block-search:before {
    background: url("../images/x2/bg-top-menu-sprites@2x.png") no-repeat;
    background-position: 0 -120px;
    background-size: 82px auto;
  }

  #audiencePrevNext a.audienceLinkNext {
    background: url("../images/x2/ui-arrows-sprites-@x2.png") no-repeat;
    background-size: 28px auto;
    background-position: 75% -579px;
  }

  #audiencePrevNext a.audienceLinkPrev {
    background: url("../images/x2/ui-arrows-sprites-@x2.png") no-repeat;
    background-size: 28px auto;
    background-position: 55% -492px;
  }

  .block-search input[type="submit"],
  .block-search form input.form-submit {
    background-position: -1px -1051px;
  }

  #block-events a.calendar {
    background-color: #ccd8cc;
  }

  .block-audience-title h1.title-prospective {
    background: url("../images/x2/bg-prospective-title-1024-@x2.png") no-repeat;
  }

  .block-audience-title h1.title-current {
    background: url("../images/x2/bg-current-title-1024-@x2.png") no-repeat;
  }

  .block-audience-title h1.title-alumni {
    background: url("../images/x2/bg-alumni-title-1024-@x2.png") no-repeat;
  }

  .block-audience-title h1.title-prospective,
  .block-audience-title h1.title-current,
  .block-audience-title h1.title-alumni {
    background-size: 188px auto;
    background-position: top center;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1024px),
only screen and (min--moz-device-pixel-ratio: 1.5) and (min-width: 1024px),
only screen and (-o-min-device-pixel-ratio: 3 / 2) and (min-width: 1024px),
only screen and (min-device-pixel-ratio: 1.5) and (min-width: 1024px),
only screen and (min-resolution: 1.5dppx) and (min-width: 1024px) {

  .logo .logo-temple {
    //background: url("../images/TU_Primary_Red_Black.png") no-repeat;
    //background-size: 219px 15px;
  }

  .logo .logo-tyler {
    background: url("../images/x2/logo-tyler-@x2.png") no-repeat;
    background-size: 300px 40px;
  }

  #jump-section>li a {
    background: url("../images/x2/ui-icons-sprites-@x2.png") no-repeat;
    background-size: 33px auto;
  }

  .navigation.audience ul.menu>li a.prospective {
    background: url("../images/x2/ui-arrows-sprites-2-@x2.png") no-repeat;
    background-size: 20px auto;
    background-position: 85% -1217px;
  }

  .navigation.audience ul.menu>li a.current {
    background: url("../images/x2/ui-arrows-sprites-2-@x2.png") no-repeat;
    background-size: 20px auto;
    background-position: 93% -1273px;
  }

  .navigation.audience ul.menu>li a.alumni {
    background: url("../images/x2/ui-arrows-sprites-2-@x2.png") no-repeat;
    background-size: 20px auto;
    background-position: 93% -1333px;
  }

  .block-audience-title h1.title-prospective,
  .block-audience-title h1.title-current,
  .block-audience-title h1.title-alumni {
    background-position: top right;
    background-size: 221px 100%;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3 / 2),
only screen and (min-device-pixel-ratio: 1.5),
only screen and (min-resolution: 1.5dppx) {
  #mobile-breadcrumb-toggle a {
    background: url("../images/x2/ui-mobile-breadcrumb-toggle-@x2.png") no-repeat;
    background-size: 63px auto;
  }

  #breadcrumb .arrow-select,
  #breadcrumb .collapsed-menu-holder ul.expanded .arrow-select {
    background: url("../images/x2/ui-arrows-sprites-2-@x2.png") no-repeat;
    background-size: 20px auto;
    background-color: white;
  }

  #breadcrumb .arrow-select {
    background-position: 6px -964px;
  }

  #breadcrumb .collapsed-menu-holder ul.expanded .arrow-select {
    background-position: 6px -1186px;
  }

  a.button.calendar {
    background-position: 108% -197px;
  }

  .sort-bar ul.view-switch li a {
    background: url("../images/x2/ui-sort-sprites-@x2.png") no-repeat;
    background-size: 33px auto;
  }

  .flex-caption a,
  .event-info-boxes a {
    background: url("../images/x2/ui-arrows-sprites-@x2.png") no-repeat;
    background-size: 28px auto;
  }

  .flex-caption a {
    background-position: 100% -415px;
  }

  .event-info-boxes a {
    background-position: 100% -431px;
  }

  .node-basic ul li,
  .node-type-academic-program .node-academic-program .region-content ul li {
    background: url("../images/x2/ui-bulletin-arrow-@x2.png") no-repeat;
    background-size: 7px auto;
    background-position: 0 7px;
  }

  .block-footer-contact .content ul li a,
  .block-footer-connect .content ul li a {
    //background: url("../images/x2/ui-footer-sprites-@x2.png") no-repeat;
    background-size: 32px auto;
  }

  #footer .block-address h1:before {
    background: url("../images/x2/ui-footer-icon-location-@x2.png") no-repeat;
    background-size: 15px auto;
  }

  .grid-view h2 a,
  .ico,
  .ico.calendar,
  .ico.lecture,
  .ico.news,
  .ico.twitter,
  .ico.location,
  .ico.connect,
  .ico.rss,
  #main>.course-popup .info-boxes-wrapper .ico {
    background: url("../images/x2/ui-icons-sprites-2-@x2.png") no-repeat;
    background-size: 25px auto;
  }

  .ico,
  .grid-view h2 a {
    background-position: -1px -313px;
  }

  .ico.calendar {
    background-position: -1px -1px;
  }

  .ico.lecture {
    background-position: -1px -79px;
  }

  .ico.news {
    background-position: -1px -157px;
  }

  .ico.twitter {
    background-position: -1px -235px;
  }

  .ico.location {
    background-position: -1px -391px;
  }

  .ico.connect {
    background-position: -1px -471px;
  }

  .ico.rss {
    background-position: -1px -157px;
  }

  ul.tyler-share li a,
  footer#footer ul.tyler-share li a,
  #main .share ul.follow li a {
    background: url("../images/x2/ui-social-sprites@2x.png") no-repeat;
    background-size: 23px auto;
  }

  .image span.roll .plus {
    background: url("../images/x2/ui-icons-sprites-2-@x2.png") no-repeat;
    background-size: 25px auto;
    background-position: 96% -1080px;
  }

  #audience-mobile ul.audience #activeArrow {
    background: url("../images/x2/ui-mobile-arrow-down-@x2.png") no-repeat;
    background-size: 30px auto;
  }

  #block-admissions-timeline .toggle-timeline ul.toggle-switch li a,
  #block-admissions-timeline .toggle-timeline ul.heading-key li.blue-dot:before,
  #block-admissions-timeline .toggle-timeline ul.heading-key li.red-dot:before,
  #block-admissions-timeline .toggle-timeline ul.toggle-switch li.active,
  #block-admissions-timeline .timeline-container .timeline>ul>li .events-cont .event .active-dot,
  #block-admissions-timeline .events-cont .event.active .popup a.close {
    background: url("../images/x2/sprite-timeline-@x2.png") no-repeat 0 0;
    background-size: 30px auto;
  }

  #block-admissions-timeline .toggle-timeline ul.heading-key li.blue-dot:before {
    background-position: 0 -90px;
  }

  #block-admissions-timeline .toggle-timeline ul.heading-key li.red-dot:before {
    background-position: 0 -78px;
  }

  #block-admissions-timeline .toggle-timeline ul.toggle-switch li.active {
    background-position: 60% -44px;
  }

  #block-admissions-timeline .timeline-container .timeline>ul>li .events-cont .event .active-dot {
    background-position: 0 -130px;
  }

  #block-admissions-timeline .events-cont .event.active .popup a.close {
    background-position: -4px -161px;
    width: 23px;
    height: 25px;
  }

  .sort-bar .filter-dropdown a span,
  .sort-bar .filter-dropdown.active a span,
  #block-student-work-sort label:after,
  #block-student-work-sort label.active:after {
    background: url("../images/x2/ui-arrows-sprites-@x2.png") no-repeat;
    background-size: 28px auto;
  }

  .sort-bar .filter-dropdown a span {
    background-position: -1px -984px;
  }

  .sort-bar .filter-dropdown.active a span {
    background-position: -1px -917px;
  }

  #block-student-work-sort label:after {
    background-position: 12px -972px
  }

  #block-student-work-sort label.active:after {
    background-position: 12px -905px;
  }
}
