header {
  &#header {
    position: relative;
    background-color: white;
    z-index: 120;

    @media (min-width: em(320)) {
      min-height: 76px;
      height: auto;
    }

    @media (min-width: em(1024)) {
      height: 165px;
    }

    .container {
      margin: 0 auto;
      @include boxit(vertical);

      @media (min-width: em(768)) {
        display: block;
      }
    }
  }
}

.logo {
  @media (min-width: em(320)) {
    position: relative;
    padding: 0 0 0 0;
    margin: 10px 60px 0 20px;
    height: 60px;
    width: 220px;
    min-width: 220px;
    z-index: 310;
  }

  @media (min-width: em(768)) {
    position: inherit;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 58px 0 0 0;
    margin: 0px 0 10px 0;
    width: 100%;
    height: 35px;
    z-index: none;
  }

  @media (min-width: em(1024)) {
    display: block;
    width: 310px;
    height: 93px;
    padding: 15px 0 0 0;
    margin: 0 0 0 0;
  }

  .logo-tyler {
    @media (min-width: em(320)) {
      display: block;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
      background: url("../images/logo-tyler-lg.png") no-repeat;
      width: 198px;
      height: 26px;
      background-size: 198px 26px;
      background-position: right top;
    }

    @media (min-width: em(768)) {
      width: 236px;
      height: 22px;
      background-size: 236px 22px;
      padding: 0 0 0 15px;
      border-left: 1px solid #F1F2F2;
    }

    @media (min-width: em(1024)) {
      width: 305px;
      height: 40px;
      background-size: 305px 40px;
      padding: 0;
      border-left: none;
    }
  }

  .logo-temple {
    @media (min-width: em(320)) {
      display: block;
      overflow: hidden;
      text-indent: -9999px;
      white-space: nowrap;
      background: url("../images/TU_Primary_Red_Black.png") no-repeat;
      width: 75px;
      height: 25px;
      background-size: 75px;
      margin: 0 0 6px 0;
    }

    @media (min-width: em(768)) {
      width: 120px;
      height: 50px;
      margin: 7px 15px 0 0;
      float: left;
      background-size: 120px;
    }

    @media (min-width: em(1024)) {
      margin: -10px 0 5px 0;
    }
  }
}

/* Mobile Nav Button Toggle */
#mobile-nav-toggle {
  @media (min-width: em(320)) {
    top: 0;
    position: absolute;
    width: 100%;
    height: 56px;
    float: right;
    background-color: #fff;
    display: none;
    display: block;
    z-index: 302;
  }

  @media (min-width: em(768)) {
    display: none;
  }

  &.control {
    padding-top: 19px;
  }

  &.control a {
    right: 0;
    position: absolute;
    display: block;
    text-indent: -9000px;
    cursor: pointer;
    width: 64px;
    height: 56px;
    background: url("../images/ui/nav-control-sprites.png") no-repeat;
    background-position: top right;
  }

  &.control.open a {
    right: 0;
    position: absolute;
    display: block;
    text-indent: -9000px;
    cursor: pointer;
    width: 64px;
    height: 56px;
    background: url("../images/ui/nav-control-sprites.png") no-repeat;
    background-position: 0 -72px;
  }

  &.control span.line {
    display: block;
    height: 4px;
    background: #a19898;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  &.open span.line {
    display: block;
    height: 4px;
    background: #da3a2b;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}

/* Mobile Bottom Menu Closer */
#mobile-menu-closer {
  @media (min-width: em(320)) {
    width: 100%;
    height: 31px;
    position: absolute;
    bottom: -31px;
    display: block;
  }

  @media (min-width: em(768)) {
    display: none;
  }
}

#mobile-menu-closer a {
  background: url(../images/ui/ui-mobile-menu-open.png) no-repeat bottom center;
  height: 31px;
  width: 100%;
  display: block;
  text-indent: -9999px;
}

/* Mobile Nav Wrapper */
#nav-wrapper {
  @media (min-width: em(320)) {
    width: 100%;
    height: auto;
    position: absolute;
    top: -520px;
    left: 0;
    z-index: 301;
  }

  @media (min-width: em(768)) {
    position: inherit;
    top: 0;
    display: block;
  }
}

/* Audience mobile Nav - Jump links */
.front #audience-mobile {
  @media (min-width: em(320)) {
    display: block;
    padding-top: 45px;
  }

  @media (min-width: em(768)) {
    display: none;
  }

  @media (min-width: 410px) {
    padding-top: 0;
  }
}

#audience-mobile {
  display: none;
  width: 100%;
  background: #fff url(../images/bg/bg-mobile-audience.png) repeat-x bottom;

  ul.audience {
    width: 100%;
    clear: right;
    border-left: none;
    border-top: 1px solid #fff;
    padding: 0;
    margin: 0;
    line-height: 2.500em;

    li {
      text-transform: uppercase;
      font-family: "proxima-nova", Helvetica, Arial, sans-serif;
      font-size: 0.688em;
      font-weight: 700;
      letter-spacing: 0.144em;
      margin: 0 -4px 0 0;
      text-align: center;
      background: none;
      display: inline-block;
      line-height: none;

      &:first-child {
        margin-left: 0;
        border-left: none;
      }

      a {
        cursor: pointer;
        border-top: none;
        padding: 0 0 0 0;
        background: none;
        color: #b4b0b1;
        display: block;

        span {
          display: none;
        }
      }

      &.prospective {
        width: 33.333%;

        a {
          color: #b4b0b1;
          border-right: 1px solid #f0f1f1;

          &:before {
            content: "";
            display: block;
            border-top: 4px solid $green;
          }

          &:hover,
          &:active,
          &.audienceLinkActive {
            color: #839912;
          }
        }
      }

      &.current {
        width: 33.333%;

        a {
          color: #b4b0b1;
          border-right: 1px solid #f0f1f1;

          &:before {
            content: "";
            display: block;
            border-top: 4px solid $cyan;
          }

          &:hover,
          &:active,
          &.audienceLinkActive {
            color: #1c897c;
          }
        }
      }

      &.alumni {
        width: 33.333%;
        margin-right: 0;

        a {
          color: #b4b0b1;
          border-right: none;

          &:before {
            content: "";
            display: block;
            border-top: 4px solid $orange;
          }

          &:hover,
          &:active,
          &.audienceLinkActive {
            color: #c18608;
          }
        }
      }
    }

    #activeArrow {
      display: block;
      margin-left: 14.5%;
      bottom: -10px;
      background: url("../images/ui/ui-mobile-arrow-down.png") no-repeat;
      width: 30px;
      height: 14px;
      border-left: none;
      border-right: none;
      border-top: none;

      .prospectiveArrow {
        margin-left: 15.5%;
      }

      .currentArrow {
        margin-left: 47.5%;
      }

      .alumniArrow {
        margin-left: 81.7%;
      }
    }
  }
}

/* Primary Nav - Active Trail Style */
#header .primary ul,
#header .audience ul {
  li.active-trail::after {
    content: "";
    display: block;
    position: absolute;
    margin: 0;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 8px solid #fff;

    @media (min-width: em(320)) {
      display: none;
    }

    @media (min-width: em(768)) {
      display: block;
    }
  }

  li ul>li.active-trail:after {
    display: none;
  }
}

#header .audience ul {
  li.prospective.active-trail:after {
    left: 17%;
  }

  li.current.active-trail:after {
    left: 50%;
  }

  li.alumni.active-trail:after {
    left: 82%;
  }
}

body.page-node-112 #header .navigation.audience ul li.active-trail::after {
  display: none;
}

/* ======== Primary Nav - Base Style ======== */
.navigation.primary {
  @media (min-width: em(320)) {
    margin: 0;
    width: 100%;
    height: auto;
    clear: left;
    display: block;
  }

  @media (min-width: em(768)) {
    margin: 0 0 0 -1.55%;
    border-top: 0px solid #F6F7F7;
    text-align: left;
  }

  @media (min-width: em(1024)) {
    float: left;
  }
}

.navigation.primary ul.menu {
  position: relative;

  @media (min-width: em(320)) {
    margin-right: 0;
  }

  @media (min-width: em(1024)) {
    margin-right: -50px;
  }
}

/* Primary Nav - Home icon */
.navigation.primary ul.menu>li {
  a.main-menu--home-link {
    width: 25px;
    padding-bottom: 0;
    height: 20px;
    background-image: none;
    margin-left: 10px;
  }

  .home-icon {
    top: 2px;
    width: 28px;
    height: 12px;
    display: inline-block;
    background-image: url("/sites/tyler/themes/tyler/images/home-icon.svg");
    background-repeat: no-repeat;
  }

  .home-icon:hover,
  .home-icon:focus {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  }
}

/* Primary Nav - 320 Breakpoint */
.navigation.primary ul.menu>li {
  @media (min-width: em(320)) {
    width: 100%;
    margin: 0;
    padding: 0;
    font-weight: 600;
    display: inline;
    position: relative;

    a {
      background: url("../images/ui/ui-arrows-sprites-2.png") no-repeat;
      background-color: #fff;
      background-position: 96% -955px;
      border-top: 1px solid #736a6a;
      font-size: 1.000em;
      display: block;
      cursor: pointer;
      padding: 0 3.6% 0 25px;
      line-height: 3.125em;

      &:link,
      &:visited {
        color: $darkBlue;
        background-color: #fff;
        background-position: 96% -955px;
      }

      &:hover {
        color: $darkBlue;
        background-color: #fff;
        background-position: 96% -955px;
      }

      &.open,
      &:active,
      &:focus {
        color: $darkBlue;
        background-color: #fff;
        background-position: 96% -1175px;
      }

      &.active:hover {
        color: $red;
      }
    }
  }
}

/* Primary Nav - 768 Breakpoint */
.navigation.primary ul.menu>li {
  @media (min-width: em(768)) {
    a {
      background-position: 95% -1583px;
      border-top: none;
      display: inline-block;
      font-size: 0.938em;
      padding: 0 25px 0 2.3%;
      line-height: 2.563em;

      &:link,
      &:visited {
        background-position: 95% -1583px;
      }

      &:hover {
        color: #fff;
        background-color: $darkBlue;
        background-position: 95% -1583px;
      }

      &:active {
        color: #fff;
        background-color: $darkBlue;
        background-position: 95% -1643px;
      }

      &.active {
        color: $red;
      }

      &.active:hover {
        color: #fff;
      }
    }

    &:hover>a,
    &:hover.dropdown>a,
    &:focus>a,
    &:focus.dropdown>a {
      color: #fff;
      background-color: $darkBlue;
      background-position: 95% -1583px;
    }

    &:hover.dropdown ul.menu,
    &:focus ul.menu,
    ul.menu.show-menu {
      display: block;
      z-index: 200;
    }
  }
}

/* Primary Nav - 1024 Breakpoint */
.navigation.primary ul.menu>li {
  @media (min-width: em(1024)) {
    a {
      background-position: 95% -1575px;
      display: inline-block;
      font-size: em(14);
      padding: 0 3.6% 0 2.6%;
      margin-right: -4px;
      line-height: 57px;

      &:link,
      &:visited {
        background-position: 95% -1575px;
      }

      &:hover {
        color: #fff;
        background-color: $darkBlue;
        background-position: 95% -1575px;
      }

      &:active {
        color: #fff;
        background-color: $darkBlue;
        background-position: 95% -1635px;
      }

      &.active {
        color: $red;
      }

      &.active:hover {
        color: #fff;
      }
    }

    &:hover>a,
    &:hover.dropdown>a,
    &:focus>a,
    &:focus.dropdown>a {
      background-position: 95% -1635px;
    }
  }
}

.navigation.primary ul.menu li.expanded>ul li {
  height: auto;
  display: inline-block;

  a {
    line-height: 1.500em;
  }
}

/* ======== Audience Nav - Base Style ======== */
.navigation.audience {
  @media (min-width: em(320)) {
    position: relative;
    width: 100%;
    clear: right;
    border-left: none;
    border-top: 1px solid #fff;
    padding: 0;
    margin: 0;
    height: auto;
    line-height: auto;
  }

  @media (min-width: em(768)) {
    width: 100%;
    height: em(42);
    margin-top: 13px;
    border-left: 1px solid #EBEAEA;
    border-right: 1px solid #EBEAEA;
    border-top: 0px solid #f6f7f7;
  }

  @media (min-width: em(1024)) {
    width: 33.55%;
    height: em(57);
    float: right;
    margin-top: 0px;
    border-right: none;
    border-left: 1px solid #f4f4f4;
    border-top: none;
  }
}

/* Audience Nav - 320 Breakpoint */
.navigation.audience ul.menu>li {
  @media (min-width: em(320)) {
    display: inline-block;
    text-transform: uppercase;
    font-family: "proxima-nova", Helvetica, Arial, sans-serif;
    font-weight: 700;
    letter-spacing: em(1);
    margin: 0 -4px 0 0;
    padding: 0;
    text-align: left;
    background: none;
    display: block;
    letter-spacing: em(2);
    height: auto;

    &:first-child {
      margin-left: 0;
      border-left: none;
    }

    a {
      line-height: em(65);
      font-size: em(14);
      height: auto;
      padding: 0 25px 0 25px;
      border-top: 1px solid #736a6a;
      display: block;
      cursor: pointer;
    }

    &.prospective {
      width: 100%;

      a {
        color: #fff;
        border-right: 0px solid #ebeaea;
        background-color: $green;
        background-position: 96.5% -783px;
        padding: 0 25px 0 25px;

        &:before {
          content: "";
          display: block;
          border-top: 0px solid $green;
        }

        &:hover,
        &:active {
          color: #fff;
          background-position: 96.5% -783px;
        }

        &.open {
          background-position: 96.5% -1007px;
        }
      }
    }

    &.current {
      width: 100%;

      a {
        color: #fff;
        border-right: 0px solid #ebeaea;
        background-color: $cyan;
        background-position: 96.5% -838px;

        &:before {
          content: "";
          display: block;
          border-top: 0px solid $cyan;
        }

        &:hover,
        &:active {
          color: #fff;
          background-position: 96.5% -838px;
        }

        &.open {
          background-position: 96.5% -1060px;
        }
      }
    }

    &.alumni {
      width: 100%;
      margin-right: 0;

      a {
        color: #fff;
        border-right: none;
        background-color: $orange;
        background-position: 96.5% -893px;

        &:before {
          content: "";
          display: block;
          border-top: 0px solid $orange;
        }

        &:hover,
        &:active {
          color: #fff;
          background-position: 96.5% -893px;
        }

        &.open {
          background-position: 96.5% -1116px;
        }
      }
    }
  }
}

/* Audience Nav - 768 Breakpoint */
.navigation.audience ul.menu>li {
  @media (min-width: em(768)) {
    border-right: none;
    text-align: center;
    display: inline-block;

    a {
      font-size: em(12);
      background-image: none;
      margin: 0;
      padding: 0;
      border-top: none;
      height: 42px;
      line-height: 42px;
    }

    &.prospective {
      width: 33.333%;

      a {
        border-right: 1px solid #ebeaea;
        background: none;
        color: $darkGreen;
        padding: 0 0 0 0;

        &:before {
          content: "";
          display: block;
          border-top: 2px solid $green;
        }

        &:hover,
        &:active {
          color: #fff;
          background-color: $green;
          background-position: 85% -1217px;
        }

        &:focus {
          color: #fff;
          background-color: $green;
          background-position: 85% -1392px;
        }

        &.open {
          background-position: 85% -1392px;
        }
      }
    }

    &.current {
      width: 33.333%;

      a {
        border-right: 1px solid #ebeaea;
        background: none;
        color: $darkCyan;

        &:before {
          content: "";
          display: block;
          border-top: 2px solid $cyan;
        }

        &:hover,
        &:active {
          color: #fff;
          background-color: $cyan;
          background-position: 93% -1273px;
        }

        &:focus {
          color: #fff;
          background-color: $cyan;
          background-position: 93% -1448px;
        }

        &.open {
          background-position: 93% -1448px;
        }
      }
    }

    &.alumni {
      width: 33.333%;

      a {
        background: none;
        color: $darkOrange;

        &:before {
          content: "";
          display: block;
          border-top: 2px solid $orange;
        }

        &:hover,
        &:active {
          color: #fff;
          background-color: $orange;
          background-position: 93% -1333px;
        }

        &:focus {
          color: #fff;
          background-color: $orange;
          background-position: 93% -1508px;
        }

        &.open {
          background-position: 93% -1508px;
        }
      }
    }
  }

  &:hover>a.prospective,
  &:hover.dropdown>a.prospective {
    color: #fff;
    background-color: $green;
    background-position: 85% -1392px;
  }

  &:hover>a.current,
  &:hover.dropdown>a.current {
    color: #fff;
    background-color: $cyan;
    background-position: 93% -1448px;
  }

  &:hover>a.alumni,
  &:hover.dropdown>a.alumni {
    color: #fff;
    background-color: $orange;
    background-position: 93% -1508px;
  }

  &:hover.dropdown ul.menu,
  &:focus ul.menu,
  ul.menu.show-menu {
    display: block;
  }
}

/* Audience Nav - 1024 Breakpoint */
.navigation.audience ul.menu>li {
  @media (min-width: em(1024)) {
    margin: 0 -4px 0 0;
    padding: 0;
    text-align: left;
    border-left: none;
    letter-spacing: em(1);

    a {
      font-size: em(11);
      height: 45px;
      line-height: em(25);
      display: inline-block;
      cursor: pointer;
      padding: 12px 0 0 15px;
    }

    &.prospective {
      width: 38%;

      a {
        background: url(../images/ui/ui-arrows-sprites-2.png) no-repeat;
        background-position: 85% -1217px;
        border-right: none;
        padding: 12px 0 0 20px;

        &:before {
          border-top: none;
        }

        &:hover,
        &:active {
          background-position: 85% -1217px;
        }

        &.open,
        &:focus {
          background-position: 85% -1392px;
        }
      }
    }

    &.current {
      width: 33%;

      a {
        background: url(../images/ui/ui-arrows-sprites-2.png) no-repeat;
        background-position: 93% -1273px;
        border-right: none;

        &:before {
          border-top: none;
        }

        &:hover,
        &:active {
          background-position: 93% -1273px;
        }

        &.open,
        &:focus {
          background-position: 93% -1448px;
        }
      }
    }

    &.alumni {
      width: 29%;
      margin-right: 0;

      a {
        background: url(../images/ui/ui-arrows-sprites-2.png) no-repeat;
        background-position: 93% -1333px;

        &:before {
          border-top: none;
        }

        &:hover,
        &:active {
          background-position: 93% -1333px;
        }

        &.open,
        &:focus {
          background-position: 93% -1508px;
        }
      }
    }
  }

  &:hover>a.prospective,
  &:hover.dropdown>a.prospective {
    background-position: 85% -1392px;
  }

  &:hover>a.current,
  &:hover.dropdown>a.current {
    background-position: 93% -1448px;
  }

  &:hover>a.alumni,
  &:hover.dropdown>a.alumni {
    background-position: 93% -1508px;
  }
}

/* ======== Audience Nav - Active Arrow Style ======== */
.navigation.audience #activeArrow {
  position: absolute;
  margin-left: 16.5%;
  bottom: -8px;
  padding: 0px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 8px solid #fff;

  &.prospectiveArrow {
    margin-left: 15.5%;
  }

  &.currentArrow {
    margin-left: 50.5%;
  }

  &.alumniArrow {
    margin-left: 82.5%;
  }
}

/* ======== Primary & Audience Nav - Dropdown Base Style ======== */
.navigation.primary ul.menu li.expanded ul {
  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 260px;
  }
}

.navigation.audience ul.menu li.expanded ul {
  @media (min-width: em(320)) {
    width: 100%;
    left: 0;
    position: relative;
  }

  @media (min-width: em(768)) {
    width: 33%;
    left: inherit;
    position: absolute;
  }

  @media (min-width: em(1024)) {
    width: 84%;
    left: 0;
  }
}

.navigation.primary ul.menu li.expanded ul,
.navigation.audience ul.menu li.expanded ul {
  height: auto;
  padding: 25px 25px 25px 25px;
  border-left: none;
  position: absolute;
  display: none;
  background: #fff;
  margin: 0 0 0 0;
  @include box-shadow;
  z-index: 500;
}

.navigation.primary ul.menu li.expanded ul {
  float: left;
  left: 0;
}

.navigation.primary ul.menu li.expanded>ul li,
.navigation.audience ul.menu li.expanded>ul li {
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.094em;
  color: $darkBlue;
  margin: 6px 0;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;

  &.first,
  &:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.last,
  &:last-child {
    margin-bottom: 0;
  }

  a {
    font-size: em(12);
    height: auto;
    color: $darkBlue;
    background: url("../images/ui/ui-arrows-sprites.png") no-repeat;
    background-position: 100% -434px;
    padding: 0 25px 0 0;
    margin: 0;
    border: none;
    display: inline;

    &:before {
      content: "";
      display: block;
      border-top: none;
    }

    &:link,
    &:visited {
      font-weight: 700;
    }

    &:hover,
    &:active,
    &:focus {
      color: $red;
      background-position: 100% -434px;
    }
  }
}

.navigation.primary ul.menu li.expanded>ul li.expanded>ul,
.navigation.audience ul.menu li.expanded>ul li.expanded>ul {
  width: 100%;
  float: none;
  display: block;
  margin: 6px 0 0 10px;
  padding: 0;
  position: relative;
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  li {
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    margin: 3px 0px;
    font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;

    &.last,
    &:last-child {
      margin-bottom: 0px;
    }

    a {
      background: none;
      padding: 0;
      font-size: em(14);

      &:link,
      &:visited {
        font-weight: 400;
      }

      &:hover,
      &:active,
      &:focus,
      &.active-trail {
        color: $red;
        font-weight: 600;
        border-bottom: 1px dotted $red;
        border-left: none;
      }
    }
  }
}

.front .navigation.audience ul.menu li.expanded>ul li.overview a.active-trail {
  @media (min-width: em(320)) {
    color: $red;
    font-weight: 600;
    background-position: 96.5% -418px;
  }

  @media (min-width: em(768)) {
    background-position: 100% -434px;
  }
}

.front .navigation.audience ul.menu li.expanded>ul li.overview a,
.front .navigation.audience ul.menu li.expanded>ul li.overview a.active-trail {
  @media (min-width: em(320)) {
    &:hover {
      font-weight: 600;
      cursor: auto;
      background-color: transparent;
      background-position: 96.5% -418px;
    }
  }

  @media (min-width: em(768)) {
    &:hover {
      background-position: 100% -434px;
    }
  }
}

.navigation.audience ul.menu li ul.menu-lvl-4 {
  display: none;
}

/* Primary & Audience Nav - Dropdown - 320 Breakpoint */
.navigation.primary ul.menu li.expanded ul,
.navigation.audience ul.menu li.expanded ul {
  @media (min-width: em(320)) {
    float: none;
    padding: 0 0 0 0;
    position: relative;
    display: none;
    background: #e9e9e9;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 0 0 0 0;
  }
}

.navigation.primary ul.menu li.expanded>ul.menu li,
.navigation.audience ul.menu li.expanded>ul.menu li {
  @media (min-width: em(320)) {
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 0.094em;
    margin: 0px 0px;

    a {
      font-size: em(12);
      height: em(20);
      line-height: em(20);
      padding: 14px 25px;
      border-top: 1px solid #fff;
      display: block;

      &:link,
      &:visited {
        font-weight: 600;
        background-position: 96.5% -418px;
      }

      &:hover,
      &:active,
      &:focus {
        color: #fff;
        background-color: $red;
      }
    }
  }
}

/* Primary & Audience Nav - Dropdown - 768 Breakpoint */
.navigation.audience ul.menu li.alumni ul.menu {
  right: 0;
}

.navigation.primary ul.menu li.expanded ul.menu,
.navigation.audience ul.menu li.expanded ul.menu {
  @media (min-width: em(768)) {
    padding: 20px 25px 0px 25px;
    position: absolute;
    @include box-shadow;
    background: #fff;
    margin: 0 0 0 0;
  }
}

.navigation.primary ul.menu li.expanded ul.menu {
  @media (min-width: em(768)) {
    float: left;
    left: 0;
  }
}

.navigation.primary ul.menu li.expanded.last ul.menu {
  @media (min-width: em(768)) {
    left: inherit;
    right: 0;
  }

  @media (min-width: em(1024)) {
    left: 0;
    right: inherit;
  }
}

.navigation.primary ul.menu li.expanded>ul.menu li,
.navigation.audience ul.menu li.expanded>ul.menu li {
  @media (min-width: em(768)) {
    display: list-item;
    margin: 8px 0px;
    text-align: left;

    &.last,
    &:last-child {
      margin-bottom: 25px;
    }

    a {
      height: auto;
      line-height: 0;
      padding: 0 25px 0 0;
      display: inline;
      border: none;

      &:before {
        display: none;
      }

      &:link,
      &:visited {
        color: $darkBlue;
        border: none;
        font-weight: 700;
        background-color: transparent;
        background-position: 100% -433px;
      }

      &:hover,
      &:active,
      &:focus {
        color: $red;
        background-color: none;
        border-top: none;
        border-left: none;
      }
    }
  }
}

.navigation.primary ul.menu li.expanded>ul.menu li.expanded>ul,
.navigation.audience ul.menu li.expanded>ul.menu li.expanded>ul {
  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(768)) {
    display: block;
  }
}

.navigation.primary ul.menu li.expanded>ul.menu li.expanded>ul li>ul,
.navigation.audience ul.menu li.expanded>ul.menu li.expanded>ul li>ul {
  @media (min-width: em(768)) {
    display: none;
  }
}

/* Primary & Audience - Dropdown menu lines */
.navigation.primary ul.menu li.expanded>ul.menu,
.navigation.audience ul.menu li.expanded>ul.menu {
  &:before {
    content: "";
    display: block;
    height: 4px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $darkBlue;
  }
}

.navigation.primary ul.menu li.expanded>ul.menu,
.navigation.audience ul.menu li.expanded>ul.menu {
  @media (min-width: em(320)) {
    &:before {
      height: 0px;
    }
  }

  @media (min-width: em(768)) {
    &:before {
      height: 4px;
    }
  }
}

.navigation.audience ul.menu li.prospective>ul,
.navigation.audience ul.menu li.current>ul,
.navigation.audience ul.menu li.alumni>ul {
  @media (min-width: em(320)) {
    &:before {
      height: 1px;
    }
  }

  @media (min-width: em(768)) {
    &:before {
      height: 4px;
    }
  }
}

.navigation.audience ul.menu li.prospective>ul.menu {
  &:before {
    background-color: $green;
  }
}

.navigation.audience ul.menu li.current>ul.menu {
  &:before {
    background-color: $cyan;
  }
}

.navigation.audience ul.menu li.alumni>ul.menu {
  &:before {
    background-color: $orange;
  }
}

.navigation.primary ul.menu li.expanded>ul li.expanded>ul.menu,
.navigation.audience ul.menu li.expanded>ul li.expanded>ul.menu {
  &:before {
    display: none;
  }
}

/* ======== Utility Nav - Dropdown Style ======== */
#utility .navigation.utility ul.menu>li ul.menu {
  display: none;
}

/* Utility Nav - 320 Breakpoint */
#utility, #main-menu {
  @media (min-width: em(320)) {
    @include order(2);
    width: 100%;
    height: auto;
    margin-left: 0px;
    position: relative;

    .navigation.utility {
      ul.menu {
        width: 100%;
        background: #e9e9e9;
        height: auto;

        &:before {
          display: none;
        }

        li {
          width: 50%;
          height: 40px;
          line-height: 40px;
          padding: 0;
          display: inline-block;
          float: left;
          border-left: 1px solid #fff;
          border-top: 1px solid #fff;
          text-align: center;
          background: #e9e9e9;
          box-sizing: border-box;

          &:first-child {
            border-left: none;
          }

          &:nth-child(3) {
            border-left: none;
          }
        }

        a {
          background: url(../images/ui/ui-icons-sprites.png) no-repeat;
          background-position: 30px 30px;

          &:link,
          &:visited {
            font-size: 0.750em;
          }

          &:hover,
          &.active-trail {
            color: $darkRed;
          }

          &.blog {
            background-position: -1px -585px;
            padding-left: 28px;
            display: inline-block;
          }

          &.calendar {
            width: auto;
            background-position: -1px -258px;
            padding-left: 28px;
            display: inline-block;
          }

          &.contact {
            background-position: -1px -655px;
            padding-left: 25px;
            display: inline-block;
          }

          &.support {
            background-position: -1px -724px;
            padding-left: 28px;
            display: inline-block;
          }
        }
      }
    }
  }
}

/* Utility Nav - 768 Breakpoint */
#utility {
  @media (min-width: em(768)) {
    @include order(1);
    width: 93.5%;
    height: 48px;
    top: 0;
    right: 0;
    display: block;
    position: absolute;

    .navigation.utility {
      ul.menu {
        width: 96.5%;
        display: block;
        height: 28px;
        background: #e9e9e9 url(../images/bg/bg-top-menu-sprites.png) repeat-x;
        background-position: 0 -55px;

        &:before {
          content: "";
          display: block;
          width: 30px;
          height: 28px;
          background: url(../images/bg/bg-top-menu-sprites.png) no-repeat;
          background-position: 0 -6px;
          position: absolute;
          margin-left: -28px;
          left: 0;
        }

        li {
          width: auto;
          display: inline;
          height: 25px;
          line-height: 25px;
          padding: 0 0.750em 0 1.375em;
          border-left: none;
          border-top: none;
          text-align: left;
          background: transparent url(../images/ui/ui-icons-sprites.png) no-repeat;
          background-position: 0 -1560px;

          &:first-child {
            background: none;
          }
        }

        a {

          &:link,
          &:visited {
            font-size: 0.750em;
          }

          &.blog {
            background-position: -1px -333px;
            padding-left: 24px;
            display: inline;

            &.active-trail {
              background-position: -1px -528px;
            }
          }

          &.calendar {
            background-position: -1px -2px;
            padding-left: 22px;
            display: inline;

            &.active-trail {
              background-position: -1px -200px;
            }
          }

          &.contact {
            background: none;
            padding-left: 0;
            display: inline;
          }

          &.support {
            background: none;
            padding-left: 0;
            display: inline;
          }
        }
      }
    }
  }
}

/* Utility Nav - 1024 Breakpoint */
#utility {
  @media (min-width: em(1024)) {
    @include order(1);
    width: 63.33%;
    height: 40px;
    top: 0;
    right: 0;
    position: absolute;

    .navigation.utility {
      ul.menu {
        width: 100%;

        li {
          margin: 0 -4px 0 0;
          padding: 0 0.438em 0 1.000em;
        }

        a {

          &:link,
          &:visited {
            font-size: 0.688em;
          }
        }
      }
    }
  }
}

/* Utility Nav - 1600 Breakpoint */
#utility {
  @media (min-width: em(1600)) {
    width: 49.5%;
  }
}

/* .block-search - 320 Breakpoint */
.block-search {
  @media (min-width: em(320)) {
    @include order(1);
    background: #001c25;
    background-position: -10px -196px;
    width: 100%;
    height: 50px;
    position: relative;

    &:before {
      display: none;
    }

    .block-search h2 {
      display: none;
    }

    input[type="submit"],
    form input.form-submit {
      right: 20px;
      top: 12px;
      background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
      background-position: -1px -1316px;
      height: 26px;
      width: 26px;
      position: absolute;
      padding: 0;
      overflow: hidden;
      text-indent: -9999px;
      border: 0;
      cursor: pointer;
      line-height: 0px;
    }

    input[type="submit"]:hover,
    form input.form-submit:hover {
      background-position: -1px -1392px;
    }

    form label,
    form placeholder {
      left: 25px;
      font-size: 0.875em;
      letter-spacing: 0.125em;
      height: 50px;
      line-height: 50px;
      position: absolute;
      top: 0px;
      color: #fff;
      font-family: "proxima-nova", Helvetica, Arial, sans-serif;
      font-weight: 800;
      text-transform: uppercase;
      display: inline-block;
    }

    form .form-type-textfield {
      height: 50px;
      position: relative;
      display: inline-block;
      margin-left: 5px;
      width: 80%;
    }

    form input.form-text {
      padding: 0px 0px 0px 25px;
      line-height: 50px;
      width: 95%;
      height: 50px;
      font-size: em(13);
      color: #fff;
      font-style: italic;
      background-color: #001c25;
      border: none;
    }
  }
}

/* .block-search - 768 Breakpoint */
.block-search {
  @media (min-width: em(768)) {
    background: #001c25 url(../images/bg/bg-top-menu-sprites.png) repeat-x;
    background-position: -10px -196px;
    width: 32.5%;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 50;

    &:before {
      content: "";
      display: block;
      width: 53px;
      height: 50px;
      background: url(../images/bg/bg-top-menu-sprites.png) no-repeat;
      background-position: 0 -120px;
      position: absolute;
      margin-left: -53px;
    }

    input[type="submit"],
    form input.form-submit {
      right: 10px;
      top: 10px;
      background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
      background-position: -1px -1051px;
      height: 19px;
      width: 19px;
    }

    input[type="submit"]:hover,
    form input.form-submit:hover {
      background-position: -1px -1182px;
    }

    form label {
      color: #fff;
      font-weight: 800;
      left: 10px;
      font-size: 0.750em;
      letter-spacing: 0.125em;
      height: 40px;
      line-height: 40px;
    }

    form .form-type-textfield {
      height: 40px;
    }

    form input.form-text {
      padding: 0px 0px 0px 10px;
      line-height: 40px;
      height: 40px;
    }
  }

  @media (min-width: em(1024)) {
    width: 37.5%;
  }
}

/**
 * custom styles for new nav bar (word-wrapped links must be pixel-perfect styled with fixed widths and padding)
 */
@media (min-width: 768px) {
  .navigation.primary {
    ul.menu>li a {
      padding: 8px 10px 9px 6px;
    }

    .menu-lvl-1 li.leaf-lvl-1>a {
      font-size: 12px;
      line-height: 1.4em;
      height: auto;
    }

    .menu-lvl-1 {

      // About
      li.leaf-lvl-1:nth-child(1)>a:link,
      li.leaf-lvl-1:nth-child(1)>a:visited {
        width: 41px;
        background-position: 32px -1570px !important;
        padding-top: 24px;
      }

      li.leaf-lvl-1:nth-child(2)>a:hover {
        background-position: 32px -1514px !important;
      }

      // Admissions
      li.leaf-lvl-1:nth-child(2)>a:link,
      li.leaf-lvl-1:nth-child(2)>a:visited {
        width: 69px;
        background-position: 61px -1570px !important;
        padding-top: 24px;
      }

      li.leaf-lvl-1:nth-child(3)>a:hover {
        background-position: 61px -1514px !important;
      }

      // Academics
      li.leaf-lvl-1:nth-child(3)>a:link,
      li.leaf-lvl-1:nth-child(3)>a:visited {
        width: 67px;
        background-position: 59px -1570px !important;
        padding-top: 24px;
      }

      li.leaf-lvl-1:nth-child(4)>a:hover {
        background-position: 59px -1514px !important;
      }

      // Exhibitions & Public Programs
      li.leaf-lvl-1:nth-child(4)>a:link,
      li.leaf-lvl-1:nth-child(4)>a:visited {
        width: 45px;
        background-position: 35px -1354px !important;
      }

      li.leaf-lvl-1:nth-child(5)>a:hover {
        background-position: 36px -1530px !important;
      }

      // Pre-College & Continuing Education
      li.leaf-lvl-1:nth-child(5)>a:link,
      li.leaf-lvl-1:nth-child(5)>a:visited {
        width: 122px;
        background-position: 114px -1571px !important;
      }

      li.leaf-lvl-1:nth-child(6)>a:hover {
        background-position: 114px -1514px !important;
      }

      // Life at Tyler
      li.leaf-lvl-1:nth-child(6)>a:link,
      li.leaf-lvl-1:nth-child(6)>a:visited {
        width: 72px;
        background-position: 63px -1570px !important;
        padding-top: 24px;
      }

      li.leaf-lvl-1:nth-child(7)>a:hover {
        background-position: 63px -1514px !important;
      }

      // Giving
      li.leaf-lvl-1:nth-child(7)>a:link,
      li.leaf-lvl-1:nth-child(7)>a:visited {
        width: 46px;
        background-position: 37px -1570px !important;
        padding-top: 24px;
      }

      li.leaf-lvl-1:nth-child(8)>a:hover {
        background-position: 37px -1514px !important;
      }
    }
  }
}


@media (min-width: 1024px) {
  .logo {
    margin: 0px 0 4px 0;
  }

  .navigation.audience {
    margin-top: 2px;
  }

  .navigation.primary {
    margin: 0 0 0 -1.55%;

    ul.menu>li a {
      padding: 8px 27px 9px 16px;
    }

    .menu-lvl-1 li.leaf-lvl-1>a {
      font-size: 13px;
      line-height: 1.4em;
      height: auto;
    }

    ul.menu>li a:visited,
    ul.menu>li a:link {
      background-position: 90% -1565px;
    }

    ul.menu>li a:hover {
      background-position: 90% -1509px !important;
    }

    .menu-lvl-1 {
      // About
      li.leaf-lvl-1:nth-child(1)>a:link,
      li.leaf-lvl-1:nth-child(1)>a:visited {
        background-position: 47px -1567px !important;
        padding-top: 28px;
        width: 40px;
      }

      li.leaf-lvl-1:nth-child(1)>a:hover {
        background-position: 47px -1509px !important;
      }

      // Admissions
      li.leaf-lvl-1:nth-child(2)>a:link,
      li.leaf-lvl-1:nth-child(2)>a:visited {
        width: 68px;
        background-position: 78px -1567px !important;
        padding-top: 28px;
      }

      li.leaf-lvl-1:nth-child(2)>a:hover {
        background-position: 78px -1509px !important;
      }

      // Academics
      li.leaf-lvl-1:nth-child(3)>a:link,
      li.leaf-lvl-1:nth-child(3)>a:visited {
        width: 69px;
        background-position: 75px -1567px !important;
        padding-top: 28px;
      }

      li.leaf-lvl-1:nth-child(3)>a:hover {
        background-position: 75px -1509px !important;
      }

      // Exhibitions & Public Programs
      li.leaf-lvl-1:nth-child(4)>a:link,
      li.leaf-lvl-1:nth-child(4)>a:visited {
        width: 45px;
        background-position: 50px -1585px !important;
      }

      li.leaf-lvl-1:nth-child(4)>a:hover {
        background-position: 50px -1528px !important;
      }

      // Pre-College & Continuing Education
      li.leaf-lvl-1:nth-child(5)>a:link,
      li.leaf-lvl-1:nth-child(5)>a:visited {
        width: 126px;
        background-position: 135px -1567px !important;
      }

      li.leaf-lvl-1:nth-child(5)>a:hover {
        background-position: 135px -1509px !important;
      }

      // Life at Tyler
      li.leaf-lvl-1:nth-child(6)>a:link,
      li.leaf-lvl-1:nth-child(6)>a:visited {
        width: 71px;
        background-position: 79px -1567px !important;
        padding-top: 28px;
      }

      li.leaf-lvl-1:nth-child(6)>a:hover {
        background-position: 79px -1509px !important;
      }

      // Giving
      li.leaf-lvl-1:nth-child(7)>a:link,
      li.leaf-lvl-1:nth-child(7)>a:visited {
        width: 46px;
        background-position: 50px -1567px !important;
        padding-top: 28px;
      }

      li.leaf-lvl-1:nth-child(7)>a:hover {
        background-position: 50px -1509px !important;
      }
    }
  }
}

@media (min-width: 64em) {
  .logo {
    margin: 0;
  }

  .navigation.audience {
    margin-top: 0;
  }

  .navigation.primary {
    margin: 0 0 0 -3%;

    ul.menu>li a {
      padding: 10px 27px 11px 16px;
    }
  }
}

/* Hiding original utility nav on mobile, since its replaced by link-swapped version */
//see main.js
@media screen and (max-width: 767px) {
  #utility .block-menu.utility {
  display: none;
  }
}

@media screen and (min-width: 768px) {
  #main-menu .block-menu.utility {
  display: none;
  }
}

//adding visible focus for primary navigation dropdowns
.dropdown {
  > a.menu__link {
    &:focus {
      outline: 1px solid #003333;
      outline-offset: -2px;
    }
  }
}