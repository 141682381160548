/** Sorting Bar **/
.sort-bar {
	margin: 20px 0 50px 0;
	padding: 0;
	height: em(45);
	border-top: 3px solid #fff;
	border-bottom: 1px solid #fff;

	@media (min-width: em(320)) {
    	margin: 15px 0 25px 0;
    	height: auto;
	}
	@media (min-width: em(768)) {
    	margin: 20px 0 50px 0;
	}
}
.sort-bar ul li {
	margin: 0 0 0 -4px;
	padding: 0 0 0 0;
	display: inline-block;
    font-size: em(11);
    letter-spacing: 0.125em;
    text-transform: uppercase;

    a {
    	line-height: 45px;
    	display: block;
    	position: relative;
        &:hover, &:focus, &:active {
        	background-color: rgba(255, 255, 255, 0.7);
        }
        &.active, &:focus, &.current {
        	background-color: rgba(255, 255, 255, 0.7);
        	color: $darkRed;
        }
    }
    a.active:before,
    a.current:before {
    	content: "";
    	display: block;
    	position: absolute;
    	padding: 0;
    	left: 40%;
    	bottom: -9px;
		width: 0;
		height: 0;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-top: 8px solid #fff;
		@media (min-width: em(320)) {
			display: none;
		}
		@media (min-width: em(768)) {
			display: block;
		}
    }
    a.all.active:before,
    a.all.current:before {
    	left: 35%;
    }
}
.sort-bar ul.filter,
.sort-bar ul.filterable {
	padding-left: 4px;
	display: inline-block;
	float: left;
}
.sort-bar ul {
	@media (min-width: em(320)) {
		width: 100%;
	}
	@media (min-width: em(768)) {
		width: auto;
	}
}
.sort-bar ul li {
	@media (min-width: em(320)) {
		width: 50%;
    	border-bottom: 1px solid #fff;
    	&:nth-child(odd) { 
			border-right: 1px solid #fff;
		}
	}
	@media (min-width: em(768)) {
		width: auto;
    	border-bottom: none;
    	&:nth-child(even) { 
			border-right: 1px solid #fff;
		}
	}
}
.sort-bar ul li a {
	@media (min-width: em(320)) {
		width: 100%;
		text-align: center;
	}
	@media (min-width: em(768)) {
		padding: 0 em(20) 0 em(20);
		width: auto;
		text-align: left;
	}
}
.sort-bar ul.view-switch li a {
	@media (min-width: em(320)) {
		padding: 0 0 0 0;
	}
	@media (min-width: em(768)) {
		padding: 0 20px 0 60px;
	}
}
.sort-bar ul.view-switch {
	overflow: hidden;
	float: right;
	width: auto;
	border-left: 1px solid #fff;
	@media (min-width: em(320)) {
    	width: 100%;
    	border-top: 2px solid #fff;
    	border-left: none;
	}
	@media (min-width: em(768)) {
    	width: auto;
    	border-top: none;
    	border-left: 1px solid #fff;
	}
	li {
		margin: 0 0 0 0;
		border-right: none;
		float: left;

		@media (min-width: em(320)) {
			width: 50%;
		}
		@media (min-width: em(768)) {
			width: auto;
		}
		a {
			background: url(../images/ui/ui-sort-sprites.png) no-repeat;
			background-position: -1px 60px;
			text-align: center;

			&:hover, &:focus, &:active, &.active {
	        	background-color: rgba(255, 255, 255, 0.7);
	        }
			&.grid {
				background-position: 14px -535px;
				float: none;
				&.active {
					background-position: 14px -455px;
				}
			}
			&.list {
				background-position: 14px -215px;
				&.active {
					background-position: 14px -140px;
				}
			}
			&.tile {
				background-position: 14px -617px;
				&.active {
					background-position: 14px -699px;
				}
			}
		}
	}
}
.sort-bar ul.view-switch li a span,
.sort-bar ul.view-switch li a span,
#block-program-masonry .masonry-title span {
	@media (min-width: em(320)) {
		display: none;
	}
	@media (min-width: em(768)) {
		display: inline-block;
	}
}
ul.view-switch li:first-child a {
	border-right: 1px solid #fff;
}
.sort-bar.dark-bg ul li a {
  color: #fff;
  &:hover, &:focus, &:focus {
    color: $darkBlue;
    background-color: rgba(255, 255, 255, 0.7);
  }
  &.current, &.active  { color: $darkRed; }
}
.sort-bar.dark-bg ul.view-switch li a.grid{
	background-position: 14px -296px;
	&:hover, &:focus, &.active {
		background-position: 14px -456px;
	}
}
.sort-bar.dark-bg ul.view-switch li a.list {
	background-position: 14px 10px;
	&:hover, &:focus, &.active {
		background-position: 14px -140px;
	}
}
.sort-bar.dark-bg ul.view-switch li a.tile{
	background-position: 14px -618px;
	&:hover, &:focus, &.active {
		background-position: 14px -699px;
	}
}
.sort-bar.dark-bg .filter-dropdown a.label-title,
.sort-bar.dark-bg .filter-dropdown a.label-title {
	color: #fff;
}
/** Grid & List Views **/
#gridlist-views {
	height: auto;
}
#gridlist-views ul > li,
#gridlist-views ul > li.item { 
	position: relative;
	z-index: 20;
}
ul#gridlist-views li.active .left,
ul#gridlist-views li.active .right,
ul#gridlist-views li.show-item .left,
ul#gridlist-views li.show-item .right {
	position: relative;
	z-index: 60;
}
/** list view **/
ul#gridlist-views.list { 
	width: 100%;
	margin: 20px 0px 150px 0px;
	.degree-label {
		color: #3a8e33;
	}
	@media (min-width: em(320)) {
		li .bg { display: none;}
	}
	@media (min-width: em(768)) {
		li .bg { display: block;}
	}
}
ul#gridlist-views.list li {
	cursor: pointer;
	position: relative; 
	height: auto;
	padding: 0;
	margin-bottom: 30px; 
	display: block;
	clear: right;
	float: left;

	&.col-one-third {
		width: 100%;
	}
	@media (min-width: em(320)) {
    	background: #fff;
    	margin-bottom: 2px; 
	}
	@media (min-width: em(768)) {
    	background: none;
    	margin-bottom: 30px;
	}
}
ul#gridlist-views.list li .left { 
	float: left; 
	width: 23.55%;
	.image {
		width: 100%;
		margin-bottom: 0;
	}
	.item-title {
		display: none;
	}
	.item-medium {
		display: none;
	}
	@media (min-width: em(320)) {
		width: 40%;
		img { height: 70px;}
	}
	@media (min-width: em(400)) {
		width: 23.55%;
		img { height: auto;}
	}
}
ul#gridlist-views.list li .right {
	display: block; 
	float: right;
	width: 74%;
	@media (min-width: em(320)) {
    	width: 55%;
    	padding: 7px 0 0 0;
	}
	@media (min-width: em(400)) {
    	width: 74%;
	}
	@media (min-width: em(768)) {
    	padding: 0 0;
	}
}
ul#gridlist-views.list li .right .item-title {
	@media (min-width: em(320)) {
		display: block;
    	width: auto;
	}
	@media (min-width: em(768)) {
    	width: 70%;
	}
	@media (min-width: em(1024)) {
    	width: 77%;
	}
}
ul#gridlist-views.list li .description {
	display: inline-block;
	width: 63%;
	float: left;
	@media (min-width: em(320)) {
    	width: 100%;
    	float:none;
    	display: none;
	}
	@media (min-width: em(600)) {
    	width: 63%;
    	float: left;
	}
	@media (min-width: em(768)) {
    	display: block;
	}

}
ul#gridlist-views.list li ul.links {
	display: inline-block;
	width: 32%;
	float: right;
	padding: 0 0 0 15px;
	border-left: 1px solid $darkGrey;
	@media (min-width: em(320)) {
    	width: 100%;
    	display: none;
	}
	@media (min-width: em(600)) {
    	width: 32%;
    	float: right;
	}
	@media (min-width: em(768)) {
    	display: inline-block;
	}
}
/** grid view **/
ul#gridlist-views.grid {
	margin: 0 0 100px 0;
	padding: 0;
	.degree-label {
		display: block;
		color: $darkGrey;
	}
}
ul#gridlist-views.grid .gutter {
	padding-left: 20px;
}
ul#gridlist-views.grid li {
	cursor: pointer;
	position: relative; 
	display: block; 
	float: left; 
	height: auto;
	box-sizing: border-box; 
	-moz-box-sizing: border-box; 
	-webkit-box-sizing: border-box;

	@media (min-width: em(320)) {
        margin: 0 0 40px 0;
    }
    @media (min-width: em(480)) {
        margin: 0 10px 40px 10px;
    }

	&.col-one-third {
		width: 31.100%;

		@media (min-width: em(320)) {
	    	width: 100%;
		}
		@media (min-width: em(480)) {
	    	width: 45%;
		}
		@media (min-width: em(600)) {
	    	width: 46.5%;
		}
		@media (min-width: em(1024)) {
	    	width: 31.100%;
		}
	}
}
ul#gridlist-views.grid li ul.links {
	margin: em(10) 0 0 0;
}
ul#gridlist-views.list li ul > li,
ul#gridlist-views.grid li ul > li { 
	padding: 0;
	margin: em(5) em(10) 0 0;
	height: auto;
	display: inline-block;
}
ul#gridlist-views.grid li .left {
	position: relative;
	float: none;
	.item-title {margin-bottom: 0;}
}
ul#gridlist-views.grid li .right {
	position: relative; 
	float: none;
	.item-title {
		display: none;
	}
	.item-medium {
		display: none;
	}
}
#gridlist-views.grid .bg{
    height: 150%;
    @media (min-width: em(320)) {
        height: 130%;
    }
    @media (min-width: em(768)) {
        height: 150%;
    }
}
#gridlist-views.list .bg{
    height: 100%;
}
ul#gridlist-views .col-one-quarter {
    width: 22.8%;
    @media (min-width: em(320)) {
    	width: 100%;
	}
	@media (min-width: em(768)) {
		width: 30.333%;
	}
	@media (min-width: em(1024)) {
		width: 22.8%;
	}
}
ul#gridlist-views.list li.col-one-quarter {
	width: 100%;
}
ul#gridlist-views li .schedule {
	font-size: em(14);
	color: #001C25;
	font-weight: 700;
	text-decoration: none;
	@media (min-width: em(320)) {
    	display: none;
	}
	@media (min-width: em(768)) {
		display: block;
	}
}
ul#gridlist-views li .schedule > div {
	display: inline;
}
.view-search-classes {
	position: relative;
	width: 100%;
	float: left;
	border-bottom: 3px solid #fff;
	margin: 0 0 35px 0;
}
.view-search-classes .view-filters {
	display: none;
}
.view-search-classes .sort-bar {
  display: none;
}
ul#gridlist-views.grid li .right,
ul#gridlist-views.grid li .right-col {
	display: none;
	.schedule {
		display: none;
	}
}
ul#gridlist-views.grid li:hover .right,
ul#gridlist-views.grid li.show-item .right{
	display: block;
	z-index: 70;
	position: absolute;
}
ul#gridlist-views.grid li.show-item .bg,
#block-blog li.show-item .bg{
	margin: -18px -18px;
	padding: 18px 18px;
	background: rgba(255, 255, 255, 1);
	-webkit-box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 1px 5px rgba(0,0,0,0.2);
	-moz-box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 1px 5px rgba(0,0,0,0.2);
	box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 1px 5px rgba(0,0,0,0.2);
}
ul#gridlist-views.list li .right-col {
	display: inline-block;
	float: right;
	width: 32%;
	border-left: 1px solid #A3ACAF;
	padding: 0 0 0 15px;
	@media (min-width: em(320)) {
    	display: none;
	}
	@media (min-width: em(768)) {
		display: block;
	}
}
ul#gridlist-views.list li .right-col a.button.more {
	margin: 27px 0 0 0;
}
.sort-bar .filter-dropdown {
	float: left;
	height: 45px;
	width: 20%;
	border-right: 1px solid #fff;
	@media (min-width: em(320)) {
        display: none;
    }
    @media (min-width: em(1024)) {
        display: block;
    }
}
.sort-bar .filter-dropdown.filter-time {
	width: 180px;
}
.sort-bar .filter-dropdown a.label-title {
	color: $darkBlue;
    font-size: em(15);
    line-height: 45px;
    padding: 0 0 0 50px;
    margin: 0 0 0 0;
    display: block;
    background: url(../images/ui/ui-icons-sprites.png) no-repeat 20px -788px;
    position: relative;
    cursor: pointer;
    @include box-sizing;
}
.sort-bar .filter-dropdown.active a.label-title {
	background: rgba(0, 28, 37, 0.9) url(../images/ui/ui-icons-sprites.png) no-repeat 20px -788px;
	color: #fff;
}
.sort-bar .filter-dropdown a span {
	width: 20px;
	height: 20px;
	position: absolute; 
	right: 11px;
	top: 13px;
	background: url("../images/ui/ui-arrows-sprites.png") no-repeat scroll -1px -984px transparent;
}
.sort-bar .filter-dropdown.active a span {
	background-position: -1px -917px;
}
.sort-bar .filter-dropdown ul {
	position: relative;
	width: 230px;
	z-index: 60;
	padding: em(25) em(50);
	background: rgba(0, 28, 37, 0.9);
	@include box-sizing;
	display: none;
}
.sort-bar .filter-dropdown.active ul {
	display: block;
}
.sort-bar .filter-dropdown ul li {
	border: none;
	display: block;
	margin: 7px 0 7px 0;
	line-height: 25px;
}
.sort-bar .filter-dropdown ul input[type="checkbox"] {
	display: inline-block;
	width: 15px;
	height: 15px;
	border: 1px solid #8d8882;
	margin: 0 5px 0 0;
	display: none;
}
.sort-bar .filter-dropdown li a.active,
.sort-bar .filter-dropdown ul input:checked + label {
	color: $darkRed;
	background: url("../images/ui/ui-sort-sprites.png") no-repeat 0 -862px;
	&:before {
		display: none;
    }
}
.sort-bar .filter-dropdown li a,
.sort-bar .filter-dropdown ul label {
	background: url("../images/ui/ui-sort-sprites.png") no-repeat 0 -791px;
	font-family: "proxima-nova", Helvetica, Arial, sans-serif;
	line-height: 20px;
	font-size: em(15);
	letter-spacing: 0.1875em;
	color: #fff;
	font-weight: 800;
	text-transform: uppercase;
	padding: 0 0 0 25px;
	cursor: pointer;
}
.sort-bar .filter-dropdown ul li a:hover,
.sort-bar .filter-dropdown ul li a:focus,
.sort-bar.dark-bg .filter-dropdown ul li a:hover,
.sort-bar.dark-bg .filter-dropdown ul li a:focus {
	color: $lightRed;
	background-color: transparent;
}
.block-views .fader {
	background: #fff;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	margin-top: 20px;
	width: 100%;
	opacity: 0.5;
	z-index: 500;
}
/* Filters - No Result */
.no-result {
	background: url("../images/ui/ui-search-icon.png") no-repeat top center;
	padding-top: 75px;
}
.no-result .wrapper{
	border-top: 2px solid white;
	border-bottom: 1px solid white;
	width: 350px;
	margin: 0 auto;
	text-align: center;
	padding: 20px 0 5px 0;
	background: rgba(255, 255, 255, 0.4);
	@media (min-width: em(320)) {
        width: 100%;
    }
    @media (min-width: em(768)) {
        width: 350px;
    }
	h5 {
		color: $darkBlue;
	}
	span {
		color: $red;
	}
}
.no-result.dark-bg {
	background: none;
	padding-top: 25px;
}
.no-result.dark-bg .wrapper{
	background: none;
	color: #fff;
	h5 {
		color: #fff;
	}
}
.no-result.no-icon {
	background: none;
	padding: 50px 0 50px 0;
}
.no-result.no-icon .wrapper{
	padding: 20px 0 20px 0;
}

#no-result-whats-happening {
	background: none;
}

// To accomodate for 2 line titles in grid
.item-title {
	.needsclick {
	  min-height: 40px;
		display: inline-block;
	}
}