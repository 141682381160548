/* Site Layout - START
*********************************/
body.not-front #page {
  overflow: inherit;
}

#page {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.container {
  @media (min-width: em(320)) {
    width: 100%;
    margin: 0 auto;
    max-width: 940px;
  }

  @media (min-width: em(768)) {
    width: 92%;
  }

  @media (min-width: em(1024)) {
    width: 93%;
  }
}

#main {
  position: relative;
  height: 100%;
  min-height: 100%;
  padding-bottom: 50px;
}

#main,
#content-bottom {
  outline: 0;
}

.front #main {
  padding-bottom: 0;
}

.main-content {
  margin: 0 auto;

  @media (min-width: em(320)) {
    padding: em(20) em(20) em(30) em(20);
    min-height: auto;
  }

  @media (min-width: em(768)) {
    padding: em(40) 0 em(30) 0;
    width: 100%;
  }

  .node-event {
    .region-content {
      ul {
        li {
          list-style: initial;
          margin-left: 15px;
        }
      }
    }
  }
}

.node-type-academic-program .main-content {
  @media (min-width: em(768)) {
    min-height: inherit;
  }
}

#main.border {
  border-bottom: 0px solid white;
}

.border {
  min-height: auto;

  @media (min-width: em(320)) {
    border: none;
  }

  @media (min-width: em(768)) {
    border: 20px solid #fff;
  }
}

.page-student-exhibitions.page-views .page-border {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 50% !important;
  border-left: 20px solid white;
  border-right: 20px solid white;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(768)) {
    display: block;
  }
}

.bg-green {
  background: url(../images/bg/bg-transparent-green.png) repeat;
}

.bg-grey {
  background: url(../images/bg/bg-transparent-grey-2.png) repeat;
}

.bg-cadet-blue {
  background: url(../images/bg/bg-transparent-cadetblue.png) repeat;
}

/* CSS3 Fadin - START
*********************************/
.block-program-hero,
.view-academic-programs #gridlist-views,
.view-search-classes #gridlist-views,
.view-whats-happening #gridlist-views,
.view-event-calendar .view-content,
#main .view-facilities,
#block-admissions-timeline {
  animation: fadein 1.4s;
  -moz-animation: fadein 1.4s;
  /* Firefox */
  -webkit-animation: fadein 1.4s;
  /* Safari and Chrome */
  -o-animation: fadein 1.4s;
  /* Opera */
  -ms-animation: fadein 1.4s;
  /* IE 10 */
}

.front-fade {
  animation: fadein 1s;
  -moz-animation: fadein 1s;
  /* Firefox */
  -webkit-animation: fadein 1s;
  /* Safari and Chrome */
  -o-animation: fadein 1s;
  /* Opera */
  -ms-animation: fadein 1s;
  /* IE 10 */
}

body.front #content-bottom {
  animation: fadein 3s;
  -moz-animation: fadein 3s;
  /* Firefox */
  -webkit-animation: fadein 3s;
  /* Safari and Chrome */
  -o-animation: fadein 3s;
  /* Opera */
  -ms-animation: fadein 3s;
  /* IE 10 */
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {

  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {

  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {

  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

​

/* Page BG - START
*********************************/
.node-type-gallery #page,
.node-type-academic-degree #page,
.node-type-basic #page,
.node-type-event #page,
.node-type-alum #page,
.node-type-faculty #page,
.page-printmail #page {
  @media (min-width: em(320)) {
    background-color: #fff;
  }

  @media (min-width: em(768)) {
    background-color: transparent;
  }
}

.node-type-gallery #main,
.node-type-academic-degree #main,
.node-type-basic #main:not(.page-blog),
.node-type-event #main,
.node-type-feature-block #main,
.node-type-alum #main,
.node-type-faculty #main,
.node-type-webform #main,
.page-printmail #main {
  @media (min-width: em(320)) {
    padding-bottom: 50px;
    background: #fff;
    height: auto !important;
    height: 100%;
    margin: 0 0 0 0;
  }

  @media (min-width: em(768)) {
    margin: 20px 20px 5px 20px;
    padding-bottom: 150px;
  }

  &.border {
    border: none;
  }
}

.node-type-faculty #main {
  background: #fff url("../images/bg/bg-page-title-overlay.png") no-repeat;
  background-size: 100% auto;
}


/* Page Title - Block */
.page-node-lecture-series.page-views .block-page-title,
.page-node-degree.page-views .block-page-title,
.page-node-alumni.page-views .block-page-title,
.page-student-life-the-what.page-views .block-page-title,
.page-taxonomy-term .block-page-title,
.node-type-basic .block-page-title,
.node-type-event .block-page-title,
.node-type-gallery .block-page-title,
.node-type-webform .block-page-title,
.node-type-alum .block-page-title,
.node-type-faculty .block-page-title,
.node-type-academic-degree .block-page-title,
.node-type-media-page .block-page-title,
.node-type-feature-block .block-page-title,
.section-blog .block-page-title,
.page-printmail .block-page-title {
  @media (min-width: em(320)) {
    background: none;
  }

  @media (min-width: em(768)) {
    margin: 0 0 0 0;
    background: url(../images/bg/bg-page-title-overlay.png) no-repeat;
    background-size: 100% 100%;
    min-height: 175px;
  }
}

.block-page-title {
  @media (min-width: em(320)) {
    height: auto;
    position: relative;
    overflow: hidden;
  }

  @media (min-width: em(768)) {
    min-height: 175px;
  }
}

.block-page-title h1,
body.page-student-life.page-views .block-page-title h1,
.page-node-degree.page-views .block-page-title h1,
.page-node-alumni.page-views .block-page-title h1,
.page-node-lecture-series.page-views .block-page-title h1 {
  @media (min-width: em(320)) {
    margin: 0 auto;
    width: auto;
    height: auto;
    display: block;
    vertical-align: none;
    margin: 0 20px;
    padding: 20px 0 0;
    word-wrap: break-word;
  }

  @media (min-width: em(768)) {
    margin: 0 0 0 0;
    padding: 20px 0 10px 0;
    width: 100%;
    height: 160px;
    vertical-align: middle;
    display: table-cell;
    padding: 0;
    border-bottom: none;
  }

  @media (min-width: em(1024)) {
    width: 65%;
  }
}

body.page-node-student-work.page-views,
body.page-student-exhibitions.page-views,
body.page-dark-bg.page-views {
  .block-page-title h1 {
    display: none;
  }

  .block-page-title {
    margin-top: 60px;
    min-height: 0;

    @media (min-width: em(320)) {
      margin-top: 40px;
      min-height: 0;
    }

    @media (min-width: em(768)) {
      margin-top: 60px;
      min-height: 0;
    }
  }

  h5 {
    padding-bottom: 8px;
  }

  h1.title {
    color: $grey;
  }
}

.page-exhibition.page-views h1 {
  color: $grey;
}

/* Views Page Title - Block */
.page-views .block-page-title {
  @media (min-width: em(320)) {
    display: block;
    overflow: inherit;
    border: none;
  }

  @media (min-width: em(768)) {
    margin: 60px 0 0 0;
    min-height: 65px;
  }
}

.page-dark-bg .block-page-title h1,
.page-node-student-work .block-page-title h1,
.page-student-exhibitions.page-views h1 {
  color: $grey;
}

.page-views .block-page-title h1,
.page-views .block-page-title h5 {
  @media (min-width: em(320)) {
    display: block;
    margin: 0 20px 0 20px;
    height: auto;
    word-wrap: break-word;
  }

  @media (min-width: em(768)) {
    margin: 0 0 0 0;
    width: 100%;
  }
}

.page-views .block-page-title h5 {
  @media (min-width: em(320)) {
    padding: 15px 0 0 0;
  }

  @media (min-width: em(768)) {
    padding: 10px 0 10px 0;
  }
}

.page-views .block-page-title .title-bg {
  min-height: inherit;

  @media (min-width: em(320)) {
    background: none;
    position: relative;
    min-height: inherit;
  }
}

.page-events-list.page-views .block-page-title {
  @media (min-width: em(320)) {
    display: block;
    overflow: inherit;
    margin-top: 0;
    height: auto;
    min-height: inherit;
  }

  @media (min-width: em(768)) {
    margin-top: 60px;
    min-height: 35px;
  }
}

.page-views .main-content {
  min-height: 475px;

  @media (min-width: em(320)) {
    min-height: auto;
  }

  @media (min-width: em(768)) {
    min-height: 475px;
  }
}

.page-views .taxonomy-term-description {
  margin: 5px 0 20px;
}

.page-event-calendar.page-views .main-content {
  @media (min-width: em(320)) {
    padding-top: 35px;
  }
}

/* Programs Hero - Block */
.block-program-hero {
  img {
    width: 100%;
    height: auto;
    display: block;
    -ms-interpolation-mode: bicubic;
  }

  @media (min-width: em(320)) {
    margin: 0;
    background: #fff;
    position: relative;
    overflow: hidden;
    height: auto;
    max-height: auto;
  }

  @media (min-width: em(1024)) {
    max-height: 465px;
  }
}

/* node-basic and Regions */
.node-basic ul li,
.node-type-academic-program .node-academic-program .region-content ul li {
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 0 0 0 15px;
  margin: 0 0 0.813em 0;
  background: url(../images/ui/ui-bulletin-arrow.png) no-repeat;
  background-position: 0 7px;
}

/* Regions */
.region-content {
  @media (min-width: em(320)) {
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }

  @media (min-width: em(1024)) {
    width: 72.55%;
  }
}

.node-type-academic-program .main-content .region-content,
.node-type-landing-page .main-content .region-content {
  float: right;
}

.node-type-landing-page.section-temple-contemporary .main-content .region-content {
  float: none;
  width: 100%;

  .field-body>p:first-child {
    font-size: .875em;
    font-weight: 400;
  }

  iframe {
    width: 100%;
    min-height: 530px;
  }
}

.node-type-academic-program .main-content .region-content .field-body>p:first-child,
.node-type-landing-page .main-content .region-content .field-body>p:first-child {
  @media (min-width: em(320)) {
    line-height: 1.35em;
    font-weight: 700;
    margin-bottom: 0.313em;
    color: $darkBlue;
    font-size: 1.25rem;

    a:link {
      color: $darkRed;
      font-size: inherit;

      &:hover {
        color: $darkBlue;
      }
    }
  }

  @media (min-width: em(768)) {
    padding-top: 0;
  }
}

.node-type-academic-program .element-hidden,
.node-type-landing-page .element-hidden {
  display: none;
}

.sidebars {
  @media (min-width: em(320)) {
    padding: 0 25px 0 25px;
  }

  @media (min-width: em(768)) {
    padding: 0 0 0 0;
  }

  @media (min-width: em(1024)) {
    padding: 30px 0 0 0;
  }
}

.sidebar-second .main-content {
  @media (min-width: em(320)) {
    width: auto;
    float: left;
  }

  @media (min-width: em(1024)) {
    width: 72.55%;
  }
}

.region-sidebar-first {
  @media (min-width: em(320)) {
    padding-right: 0;
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }

  @media (min-width: em(1024)) {
    padding-right: 20px;
    width: 25%;
  }
}

.region-sidebar-second {
  @media (min-width: em(320)) {
    float: right;
    margin-bottom: 20px;
    padding-left: 0;
    width: 100%;
    border-top: 2px solid $lightGrey;
    padding-top: 30px;
    margin-top: 20px;
  }

  @media (min-width: em(1024)) {
    padding-left: 20px;
    width: 25%;
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
}

.region-sidebar-first,
.region-sidebar-second {
  &.col-one-quarter {
    @media (min-width: em(320)) {
      width: 100%;
    }

    @media (min-width: em(768)) {
      width: 100%;
    }

    @media (min-width: em(1024)) {
      width: 25%;
    }
  }
}

/* Programs callout links */
.block-program-quick-links {
  li {
    margin: 7px 0 7px 0;
  }

  a {
    clear: both;
    display: inline-block;
    font-size: em(14);
    line-height: em(14);
    padding: 0.375em em(34) 0.313em em(15);
    border-radius: em(10);
    border: 1px solid #a9afb1;
    background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
    background-position: 98% -429px;
  }

  a:hover,
  a:focus {
    background-color: #fff;
  }
}

.block-quick-links {
  @media (min-width: em(320)) {
    width: 100%;
    display: block;
    min-height: 150px;
    background-color: $deepGrey;
    margin-bottom: 20px;
  }

  @media (min-width: em(768)) {
    width: 29.333%;
    float: left;
    margin-left: 20px;
  }

  @media (min-width: em(1024)) {
    width: 100%;
    float: none;
    margin-left: 0;
  }

  .content {
    padding: 20px 20px;
  }

  h2 {
    margin-bottom: 15px;
    color: #fff;
  }

  .content ul li {
    margin: 8px 0;

    a {
      background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
      background-position: 100% -431px;
      padding-right: 20px;

      &:link,
      &:visited {
        color: $darkBlue;
      }

      &:hover,
      &:active {
        color: #4d0808;
      }
    }
  }

  ul.expanded li {
    a {
      background: none;
      padding-right: 0;
    }
  }
}

/* Event Info Boxes */
.event-info-boxes a {
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: 100% -431px;
  padding-right: 20px;

  &:link,
  &:visited {
    color: $darkBlue;
  }

  &:hover,
  &:active {
    color: #4d0808;
  }
}



/* Programs - START
*********************************/
.section-programs #main {
  @media (min-width: em(320)) {
    padding-bottom: 0;
  }

  @media (min-width: em(768)) {
    padding-bottom: 30px;
  }
}

.node-type-academic-program h1,
.node-type-landing-page h1 {
  @media (min-width: em(320)) {
    margin-bottom: em(5);
    margin-top: 0;
  }

  @media (min-width: em(1024)) {
    margin-top: -57px;
  }
}

.view-lecture-series h5 {
  display: none;
}

/* Programs Degree Node - START
*********************************/
.degree-info-boxes li,
.event-info-boxes li {
  h3 {
    margin-bottom: em(10);
    color: #fff;
  }

  .content {
    padding: 20px 20px;
  }

  @media (min-width: em(320)) {
    width: 100%;
    float: none;
    background-color: $deepGrey;
    margin-bottom: 20px;
  }

  @media (min-width: em(768)) {
    width: 29.333%;
    float: left;
    margin-left: 20px;
    min-height: 120px;
  }

  @media (min-width: em(1024)) {
    width: 100%;
    float: none;
    margin-left: 0;
  }
}

.page-node-degree .degree-info-boxes li {
  @media (min-width: em(768)) {
    min-height: 160px;
  }

  @media (min-width: em(1024)) {
    min-height: auto;
  }
}

.row-item .node-academic-degree,
.row-item .view-lecture-series,
.page-node-lecture-series .row-item .node-event {
  padding: em(40) 0 em(20) 0;

  @media (min-width: em(320)) {
    padding: em(20) 0 em(20) 0;
  }

  @media (min-width: em(768)) {
    padding: em(40) 0 em(20) 0;
  }
}

.page-node-lecture-series .region-sidebar-second {
  @media (min-width: em(320)) {
    border-top: 2px solid #fff;
  }

  @media (min-width: em(1024)) {
    border-top: none;
  }
}

.views-row-first .node-academic-degree {
  @media (min-width: em(320)) {
    padding: em(20) 0 em(20) 0;
  }

  @media (min-width: em(768)) {
    padding: em(40) 0 em(20) 0;
  }
}

.page-node-lecture-series h1,
.page-node-alumni h1,
.page-node-degree h1 {
  margin-bottom: 45px;

  @media (min-width: em(320)) {
    margin-bottom: 0;
  }

  @media (min-width: em(768)) {
    margin-bottom: 45px;
  }
}

.view-degrees .row-item,
.view-lecture-series .row-item {
  border-bottom: 3px solid #fff;
}

.view-degrees .views-row-last.row-item,
.view-lecture-series .views-row-last.row-item {
  border-bottom: none;
}

/* Faculty & Alumni- START
*********************************/
.view-faculty.contextual-links-region {
  padding-top: 25px;
}

.view-faculty .row,
.view-alumni .row-item {
  clear: both;
  margin-bottom: em(65);
}

.view-faculty .views-row-last,
.view-alumni .views-row-last {
  margin-bottom: 0;
}

.view-alumni .view-content {
  @media (min-width: em(320)) {
    float: left;
    margin: 0 0;
  }

  @media (min-width: em(1024)) {
    margin: 50px 0;
  }
}

/* Profile Box - START
*********************************/
.page-taxonomy .profile-box {
  margin-bottom: 30px;
}

.profile-box h2 {

  a:link,
  a:visited {
    color: #3a8e33;
  }

  a:hover,
  a:focus {
    color: $darkBlue;
  }
}

.profile-box {
  background-color: #e9e9e9;
}

.profile-box .image {
  margin-bottom: 0;
  float: left;

  img {
    width: 100%;
  }
}

.profile-box .description {
  background-color: #e9e9e9;
  padding: em(25);
  float: left;
  @include box-sizing;
}

.profile-box ul.contact-info {
  padding-top: em(5);
  word-wrap: break-word;
}

.profile-box ul.contact-info li {
  word-wrap: break-word;

  strong {
    font-size: em(14);
    padding-right: em(5);
  }

  a:link,
  a:visited {
    font-size: em(15);
    text-decoration: underline;
  }
}

.profile-box.grid-one .col-one-quarter {
  @media (min-width: em(320)) {
    width: 100%;
  }
}

.profile-box.grid-one .col-three-quarters {
  @media (min-width: em(320)) {
    width: 100%;
  }
}

.profile-box.grid-two .col-one-quarter {
  @media (min-width: em(320)) {
    width: 100%;
    padding: 0 0 0 0;
  }

  @media (min-width: em(768)) {
    width: 29%;
    padding: 25px 0 25px 25px;
  }
}

.profile-box.grid-two .col-three-quarters {
  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 67%;
  }
}

.course-popup .profile-box.grid-two .col-one-quarter {
  @media (min-width: em(320)) {
    width: 100%;
    padding: 0 0 0 0;
  }

  @media (min-width: em(768)) {
    width: 29%;
  }
}

/* Featured Work - START
*********************************/
.section-programs.page-views .block-label,
.page-featured-work h5 {
  margin-bottom: em(20);
}

.page-featured-work h1 {
  @media (min-width: em(320)) {
    padding: 5px 0 0 0;
  }

  @media (min-width: em(768)) {
    padding: 0;
  }
}

/* Temple Contemporary - START
*********************************/
.node-type-landing-page.section-temple-contemporary h1,
.node-type-landing-page.section-temple-contemporary .grid-view a.link,
.node-type-landing-page.section-connect .grid-view a.link {
  display: none;
}

.section-temple-contemporary .grid-view {
  margin-top: 0;
}

.tc-section-desc {
  margin: 30px 0 40px 0;
}

.tc-section-desc h2 {
  text-indent: -9999px;
  background: url("../images/logo-tu-contemporary.png") no-repeat top center;
  background-size: 100% auto;
  width: 31.77%;
  height: 89px;
  margin: 15px 0 20px 0;
  float: left;

  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 31.77%;
  }
}

.tc-section-desc .content {
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  padding: em(8) 0 em(8) 0;
  line-height: em(21);
  color: $darkBlue;
  font-size: em(20);
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-weight: 700;
  /*text-shadow: 0px 0px 2px #8c8c8b;*/
  float: right;
  width: 66.666%;

  p {
    padding: 0 0;
  }

  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 65.666%;
  }
}

/* Temple Contemporary - What's happening */
.view-whats-happening .view-header {
  width: 25%;
  float: left;

  @media (min-width: em(768)) {
    display: none;
  }

  @media (min-width: em(1024)) {
    display: block;
  }
}

.view-whats-happening #gridlist-views,
.view-whats-happening #gridlist-views.list,
.view-whats-happening .view-empty {
  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(1024)) {
    width: 75%;
    float: left;
    clear: none;
  }
}

.view-whats-happening #gridlist-views.list,
.view-whats-happening #gridlist-views.grid {
  margin-bottom: 0;
}

.view-whats-happening #gridlist-views.grid .image {
  @media (min-width: em(320)) {
    display: block;
    width: auto;
    height: auto;
  }

  @media (min-width: em(1024)) {
    width: 219px;
    height: 137px;
  }
}

.view-whats-happening .item-list .pager {
  @media (min-width: em(320)) {
    width: 100%;
    float: right;
  }

  @media (min-width: em(1024)) {
    width: 75%;
  }
}

.view-whats-happening #gridlist-views.grid li {
  margin: 0 7px 50px;
  padding: 0;
}

.view-whats-happening ul#gridlist-views.grid li .right-col {
  display: block;
}

.view-whats-happening .view-filters {
  display: none;
}

.block.tc-section-sidebar {
  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(1024)) {
    display: block;
  }
}

.tc-section-sidebar h2 {
  background: url("../images/logo-ts-contemporary.png") no-repeat top center;
  height: 91px;
  margin: 0 0 0;
  text-indent: -9999px;
  width: 220px;
}

.tc-section-sidebar .subsection {
  background: url("../images/bg/bg-ts-section.png") repeat-x 0 100%;
  margin: 0 15px 0 0;
  padding: 10px 0 20px 0;
}

.tc-section-sidebar h4 {
  color: #000;
  font-size: em(12);
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 20px;
}

.tc-section-sidebar .subsection strong {
  font-size: em(12);
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 18px;
  text-transform: uppercase;
  color: #151515;
}

.tc-section-sidebar .subsection em {
  font-size: em(15);
  line-height: 20px;
}

.tc-section-sidebar .subsection address {
  font-size: em(13);
  line-height: 18px;
  color: #001c25;
  margin: -0.625em 0;
  font-style: normal;
}

.tc-section-sidebar a.button {
  font-size: 0.875em;
  margin: 15px 0 0 0;
}

/* Continuing Education - START
*********************************/
.section-continuing-education-program a.link {
  padding-left: em(6);

  &:hover,
  &:focus {
    color: $red;
  }
}

/* Landing Page - START
*********************************/
.section-admission-process .grid-view .col-one-third {
  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 33.333%;
  }

  @media (min-width: em(1024)) {
    width: 25%;
  }
}

.section-admission-process #block-program-masonry.border {
  border-top: 0px solid #fff;
}

.section-admission-process #block-program-masonry .masonry-title:before,
.section-admission-process #block-program-masonry .masonry-title .block-label:after {
  display: none;
}

.section-admission-process #block-program-masonry .masonry-title .block-label {
  @media (min-width: em(320)) {
    width: 90%;
    background-color: transparent;
    font-size: 0.750em;
    font-weight: 800;
    text-shadow: 1px 0px 1px #fff;
    border-bottom: 3px solid #fff;
  }

  @media (min-width: em(768)) {
    width: 100%;
  }
}

/* Event Category Page - START
*********************************/
.section-event-category #main.border,
.section-event-type #main.border {
  h1 {
    margin-bottom: 30px;
  }
}

.node-event.view-mode-full .field-featured-image {
  margin-bottom: 20px;
}

.node-event.view-mode-full .field-featured-image img {
  @media (min-width: em(320)) {
    width: 100%;
    height: auto;
  }

  @media (min-width: em(768)) {
    width: auto;
  }
}

.node-type-event .field-event-date,
.node-type-event .field-room-number {
  font-size: em(14);
}

/* Blog - START
*********************************/
.sidebar-second.section-blog .main-content,
.sidebar-second.section-tags .main-content,
.sidebar-second.section-blog-categories .main-content {
  @media (min-width: em(320)) {
    width: auto;
    float: left;
  }

  @media (min-width: em(1024)) {
    width: 65.99%;
  }
}

.section-blog .node-teaser {
  margin: 0 0 em(60) 0;
  padding: 0 0 em(50) 0;
  border-bottom: 2px solid #fff;
}

.section-blog h2 {
  margin: em(8) 0;
}

.section-blog h5 {
  display: inline-block;
  color: $darkRed;

  a {
    font-weight: 800;
    color: $darkRed;
    display: block;
    text-align: right;

    &:hover,
    &:focus {
      color: $red;
    }
  }
}

.node-type-blog h5 {
  display: inherit;
}

.node-type-blog .node-blog h2 {
  color: $darkBlue;
  width: 85%;
  line-height: 35px;
}

.section-blog a.back-to-blog {
  padding: 0 0 0 30px;
  width: auto;
  height: 23px;
  line-height: 22px;
  float: right;
  background: url("../images/ui/ui-arrows-sprites.png") no-repeat;
  background-position: 0 -1114px;

  &:hover {
    background-position: 0 -1245px;
  }

  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(1024)) {
    display: block;
  }
}

.node-type-blog .blog-tag,
.section-blog .author,
.section-blog .author h5 {
  display: inline-block;
}

.section-blog span.username {
  display: inline-block;
  font-weight: 600;
}

.section-blog .teaser-body {
  padding: em(20) 0 em(20) 0;
}

.section-blog a.link.more {
  padding-left: 10px;
}

.section-blog .blog-tag span {
  overflow: hidden;
  text-indent: -9999px;
  display: inline-block;
  background: url("../images/ui/ui-icons-sprites.png") no-repeat;
  background-position: 0px -1325px;
  width: 21px;
  height: 20px;
}

.section-blog .blog-tag .field-tags {
  display: inline-block;
}

.section-blog .blog-tag .field-tags li a {
  text-transform: capitalize;
  font-size: em(11);
}

.node-type-blog .image {
  margin: em(30) 0 0 0;
}

/* Facilities - START
*********************************/
.page-facilities #page {
  background: url(../images/bg/bg-transparent-grey-2.png) repeat;
}

.page-facilities {
  h5 {
    color: $darkBlue;
  }
}

.page-facilities .block-page-title {
  @media (min-width: em(320)) {
    h5 {
      margin: 0 0 0 0;
    }
  }

  @media (min-width: em(768)) {
    h5 {
      margin: 60px 0 0 0;
    }
  }
}

.page-facilities h1.page-title {
  border-bottom: 3px solid #fff;
  padding: 0 0 20px 0;
  margin: 0 0 0 0;
}

.page-facilities #main .container {
  width: 100%;
  max-width: 100%;
}

.page-facilities ul#gridlist-views.grid,
.page-facilities #facility-wrap .wrapper,
.page-facilities .facilities-intro,
.page-facilities .block-page-title {
  width: 940px;
  max-width: 940px;
  margin: 0 auto;
  float: none;

  @media (min-width: em(320)) {
    width: 90%;
  }

  @media (min-width: em(768)) {
    width: 93%;
  }

  @media (min-width: em(1024)) {
    width: 940px;
  }
}

.facilities-intro {
  padding: 25px 0 15px 0;
  border-bottom: 1px solid #dfdfdf;
}

.page-facilities .main-content {
  padding: 0 0;
}

.page-facilities #facility-wrap .wrapper {
  h4 {
    color: $red;
    margin: 0 0;
    letter-spacing: 1px;
  }
}

#ajax-container .gallerySlider .flex-direction-nav {
  right: 50%;
  margin-right: -470px;
  bottom: -17.5%;
  width: 86px;

  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(1024)) {
    display: block;
  }
}

#ajax-container {
  position: relative;
  background: url(../images/bg/bg-transparent-green.png) repeat;
  width: 100%;
  height: 100%;

  a.close {
    position: relative;
    background: url(../images/ui/ui-icons-sprites.png) no-repeat;
    background-position: 97% -990px;
    display: block;
    text-align: right;
    color: #fff;
    max-width: 940px;
    width: 93%;
    margin: 0 auto;
    padding: 15px 0;

    &:hover {
      color: $red;
    }

    @media (min-width: em(320)) {
      background-position: 90% -990px;
    }

    @media (min-width: em(768)) {
      background-position: 95% -990px;
    }

    @media (min-width: em(1024)) {
      background-position: 97% -990px;
    }
  }
}

#ajax-container .node-slide {
  display: none;
}

.field-panorama {
  p {
    padding: 0;
    line-height: 0;
  }

  iframe {
    @media (min-width: em(320)) {
      height: 255px;
    }

    @media (min-width: em(768)) {
      height: 470px;
    }

    @media (min-width: em(1024)) {
      height: 600px;
    }
  }
}

.page-facilities #facility-wrap .wrapper .left {
  p {
    width: 95%;
    color: #fff;
  }

  @media (min-width: em(320)) {
    padding: 20px 0 0 0;
  }

  @media (min-width: em(768)) {
    padding: 20px 0 20px 0;
  }
}

#facility-wrap .share-gallery {
  padding: 10px 0 25px 0;
  border-top: 1px solid $darkBlue;

  h6 {
    color: #918c88;
  }
}

.view-facilities {
  margin: 40px 0 0 0;

  #gridlist-views.grid .bg {
    height: 130%;
  }
}

#facility-wrap .share-label {
  color: #fff;
}

ul.gallery-tabs li {
  display: inline-block;
}

ul.gallery-tabs li a {
  background: url(../images/ui/ui-icons-sprites.png) no-repeat;
  background-position: 0 0;
  padding: 0 0 0 20px;
  margin: 0 25px 0 0;
  line-height: 55px;
  height: 55px;
  display: inline-block;

  &:link,
  &:visited {
    color: #fff;
  }

  &:hover,
  &:focus,
  &.active {
    color: $red;
  }

  &.icon-360-tour {
    background-position: 0 -1112px;

    &.active {
      background-position: 0 -1049px;
    }
  }

  &.icon-img {
    background-position: 0 -1237px;

    &.active {
      background-position: 0 -1174px;
    }
  }
}

/* Media Page - START
*********************************/
.node-type-media-page #page,
.page-temple-contemporary #page {
  background: url(../images/bg/bg-transparent-grey-2.png) repeat;
}

.node-type-media-page .grid-view h2 a {
  background: none;
  padding-left: 0;
  padding-bottom: 10px;
  font-size: em(14);
}

.node-type-media-page a.button {
  border: 1px solid #969898;
}

/* Taxonomy Page - START
*********************************/
.page-taxonomy-term .node-blog.node-teaser {
  border-bottom: 2px solid white;
  margin: 0 0 3.75em;
  padding: 0 0 3.125em;
}

.section-blog .node-teaser.last,
.page-taxonomy-term .node-blog.node-teaser.last {
  border-bottom: 0;
}

.section-blog .node-teaser .block-label,
.page-taxonomy-term .node-blog.node-teaser .block-label {
  display: inline-block;
}

.section-blog .node-blog.node-teaser .block-label,
.page-taxonomy-term .node-blog.node-teaser .block-label {
  color: $darkRed;
  margin-bottom: 12px;
}

.section-blog .node-blog.node-teaser p.teaser-body,
.page-taxonomy-term .node-blog p.teaser-body {
  line-height: 20px;
  padding: 6px 0 8px;
}

.section-blog .node-blog div.blog-tag,
.page-taxonomy-term .node-blog div.blog-tag {
  padding: 5px 0 0;
}

.section-blog .node-blog div.blog-tag a,
.page-taxonomy-term .node-blog div.blog-tag a {
  font-size: em(11);
  text-transform: capitalize;
}

.page-taxonomy-term .node-blog .blog-tag span {
  background: url("../images/ui/ui-icons-sprites.png") no-repeat scroll 0 -1325px transparent;
  display: inline-block;
  height: 20px;
  overflow: hidden;
  text-indent: -9999px;
  width: 21px;
}

.page-taxonomy-term .node-blog.node-teaser .blog-tag .field-tags {
  display: inline-block;
}

.section-blog .block-search form placeholder {
  display: none;
}

.section-blog div.categories,
.page-taxonomy-term div.categories {
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
  margin: 24px 0;
}

.section-blog div.categories,
.page-taxonomy-term div.categories {
  padding: 0 10px 23px 40px;
}

.section-blog div.categories h2.block-title,
.page-taxonomy-term div.categories h2.block-title {
  font-size: 0.875em;
  line-height: 1.4em;
  color: #001c25;
  margin-bottom: 20px;
}

.sidebar div.block-tyler-custom div.content a.active {
  color: $darkRed;
}

.sidebar div.categories div.content ul li {
  margin: 0 0 14px 0;
  letter-spacing: 0.03em;
}

.sidebar div.categories div.content ul li ul li {
  margin: 0 0 8px 0;
}

.sidebar div.blog-tags div.content ul li a,
.sidebar div.categories div.content ul li a {
  text-transform: uppercase;
  color: #464646;
  font-size: 0.625em;
}

.sidebar div.blog-tags div.content {
  margin: 0 0 20px 0;
}

.sidebar div.categories div.content ul li ul {
  margin: 0.75em 0 24px 0;
}

.sidebar div.categories div.content ul li ul li a {
  text-transform: capitalize;
  color: #464646;
  font-size: 0.7em;
}

#page div.categories div.content a:hover,
#page div.blog-tags div.content a:hover {
  color: $darkRed;
  text-decoration: none;
  -webkit-transition: background-color 0.4s ease;
  -moz-transition: background-color 0.4s ease;
  -o-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
}

.section-blog .view-mode-full div.field-body ul li:before,
.section-blog div.categories div.content ul li div.item-list ul li:before,
.page-taxonomy-term div.block-tyler-custom div.content div.item-list ul li ul li:before {
  color: #c22a21;
  font-weight: 900;
  font-size: em(10);
  content: ">";
  margin: 3px 5px 0;
  width: 5px;
}

.section-blog .view-mode-full div.field-body ul li:before {
  font-size: 0.95em;
}

.sidebar div.blog-tags div.content ul li {
  line-height: 0.875em;
  margin: 0 5px 0 0;
  letter-spacing: 0.03em;
  display: inline-block;
}

.sidebar div.blog-tags div.content a {
  display: inline-block;
  border-radius: 0.625em;
  border: 1px solid #a9b2b3;
  padding: 1px 4px;
  margin-bottom: 10px;
}

.page-taxonomy-term .node-blog div.image,
.section-blog .node-blog div.image {
  margin-bottom: 30px;
}

.section-blog .view-mode-full ul {
  margin: 0 0 0.75em;
}

.section-blog .view-mode-full ul li {
  font-size: 0.95em;
  line-height: 1.8em;
}

.section-blog .view-mode-full blockquote {
  background: none;
  border-left: 0;
  display: block;
  font-style: normal;
  margin: 0 1.125em 1.125em 0;
  overflow: hidden;
  padding: 1.125em 1.125em 0 0;
}

.section-blog .view-mode-full blockquote p {
  color: #3a8e33;
  font-style: 25px;
  line-height: 28px;
}

.section-blog .view-mode-full blockquote p:before {
  content: "\201c";
  font-style: 25px;
  font-weight: 900;
}

.section-blog .view-mode-full blockquote p:after {
  content: "\201d";
  font-style: 25px;
  font-weight: 700;
}

.page-taxonomy-term ul.pager li.pager-next,
.section-blog ul.pager li.pager-next {
  float: right;
  color: #817e7b;
}

.page-taxonomy-term #page ul.pager li,
.section-blog #page ul.pager li {
  line-height: 18px;
}

.page-taxonomy-term #page ul.pager li a,
.section-blog #page ul.pager li a {
  color: #817e7b;
}

.sidebar div.more-tyler-blogs div.content ul li a:hover,
.sidebar #page ul.pager li a:hover,
.sidebar div.blog-archive div.content ul.views-summary li a:hover {
  color: #da3a2b;
  text-decoration: none;
  -webkit-transition: background-color 0.4s ease;
  -moz-transition: background-color 0.4s ease;
  -o-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
}

.drop-down-list .item-list {
  display: none;
}

.drop-down-list>a {
  background: url("../images/ui/ui-arrows-sprites.png") no-repeat scroll 100% -984px transparent;
  padding: 5px 35px 5px 0;
}

.drop-down-list>a.show {
  background-position: 100% -917px;
}

.page-taxonomy-term ul.pager li.pager-previous,
.section-blog ul.pager li.pager-previous {
  float: left;
}

.page-taxonomy-term ul.pager li.pager-last,
.section-blog ul.pager li.pager-last,
.page-taxonomy-term ul.pager li.pager-first,
.section-blog ul.pager li.pager-first,
.section-blog ul.pager li.pager-item,
.page-taxonomy-term ul.pager li.pager-item {
  display: none;
}

.section-blog ul.pager li.pager-current:before,
.page-taxonomy-term ul.pager li.pager-current:before {

  color: #817e7b;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-size: em(12);
  font-weight: 700;
  letter-spacing: 0.125em;
  line-height: 1.14286em;
  text-transform: uppercase;
}

.page-taxonomy-term a.feed-icon img,
.section-blog a.feed-icon img {
  width: auto;
  max-width: none;
  display: none;
}

.section-blog div#comments h3.title {
  padding-top: 51px;
  border-top: 1px solid #fff;
  font-size: em(16);
  line-height: 30px;
}

.section-blog div#comments div.comment {
  padding: 16px 0 0;
}

.section-blog div#comments div.comment.last {
  padding-bottom: 48px;
}

.section-blog div#comments div.comment div.content {
  width: 100%;
  overflow: hidden;
}

.section-blog div.comment h3.comment-title {
  margin: 0;
}

.section-blog div.comment div.content p {
  padding: 0;
}

.section-blog div.comment ul {
  margin: 0;
  display: none;
}

.section-blog div.form-item-name label,
.section-blog div#edit-comment-body label {
  display: none;
  font-size: em(13);
  line-height: 22px;
  color: red;
}

.section-blog fieldset#edit-comment-body-und-0-format {
  display: none;
}

.section-blog #comment-form .form-item {
  margin: 22px 0;
}

.section-blog div.submitted div.author {
  display: inline-block;
}

.section-blog div.submitted div.author h2,
.section-blog div.submitted h4 {
  font-size: em(12);
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin: 0;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
}

.section-blog div.submitted div.author h2 {
  color: #31772b;
}

.section-blog div.submitted h4 {
  display: inline-block;
  padding-left: 3px;
}

.section-blog div.submitted h4:before {
  color: #FFFFFF;
  content: "/";
  margin: 3px 10px 0;
  font-size: em(12);
  width: 10px;
}

.section-blog form.comment-form .form-submit {
  background: none;
  color: #001c25;
  text-transform: capitalize;
  font-size: em(14);
  line-height: 20px;
  letter-spacing: normal;
  padding-right: 20px;
  background: url("../images/ui/ui-arrows-sprites.png") no-repeat 100% -421px;

}

.section-blog form.comment-form div.edit-name {
  width: 241px;
}

.section-blog form.comment-form textarea {
  width: 540px;
  height: 140px;
  overflow: hidden;
  resize: none;
  padding: 10px;
}

.section-blog form.comment-form div.form-textarea-wrapper .grippie,
.section-blog div.form-item-subject {
  display: none;
}

.section-blog form.comment-form label {
  color: #001C25;
}

.sidebar div.more-tyler-blogs {
  background: #fff;
  padding: 23px 18px 27px 20px;
  margin: 24px 0;
}

.sidebar div.more-tyler-blogs h2 {
  background: url(../images/bg/bg-info.png) no-repeat;
  padding-left: 34px;
  color: #001c25;
  font-size: em(20);
  line-height: 24px;
  margin: 0 0 20px 0;
  font-weight: normal;
}

.sidebar div.more-tyler-blogs div.content ul li {
  line-height: 33px;
}

.sidebar div.more-tyler-blogs div.content ul li a {
  background: url("../images/ui/ui-arrows-sprites.png") no-repeat scroll 100% -432px;
  padding-right: 24px;
  color: #000000;
  font-weight: 500;
}

.page-blog-archive .block-page-title .title-bg {
  background: url("../images/bg/bg-page-title-overlay.png") no-repeat scroll 0 0 / 100% 100% transparent;
  min-height: 175px;
  position: absolute;
}

.page-blog-archive .block-page-title {
  min-height: 175px;
  margin-top: 0;
}

.sidebar div.blog-archive {
  padding: 20px 15px;
  border-top: 2px solid #fff;
  border-bottom: 1px solid #fff;
  margin: 24px 0;
}

.sidebar div.blog-archive h2 {
  color: #001c25;
  cursor: pointer;
  font-size: em(14);
  line-height: 22px;
  background: url("../images/ui/ui-arrows-sprites.png") no-repeat scroll 80% -984px;
}

.sidebar div.blog-archive h2:before {
  content: "";
  width: 33px;
  height: 23px;
  float: left;
  background: url("../images/bg/bg-archive.png") no-repeat 0 0;
}

.sidebar div.blog-archive div.content {
  margin: 20px 0 0 0;
  display: none;
  width: 100%;
  overflow: hidden;
}

.section-blog-archive .sidebar div.blog-archive div.content {
  display: block;
}

.section-blog-archive .sidebar div.blog-archive div.content a.active {
  color: #C22A21;
}

.sidebar div.blog-archive h2.show {
  background-position: 80% -917px;
}

.sidebar div.blog-archive div.content ul.views-summary li a {
  color: #000000;
  font-weight: 500;
}

.sidebar div.search-blog {
  width: 100%;
  overflow: hidden;
  background-color: #a3acaf;
  border-bottom: 0;
  padding: 0;
  margin: 0;
}

.sidebar div.search-blog div.content {
  overflow: hidden;
  padding: 15px;
  width: 100%;
  float: left;
}

.sidebar form#tyler-custom-search-blog-form label {
  margin: 0;
}

.sidebar form#tyler-custom-search-blog-form div.form-item,
.sidebar form#tyler-custom-search-blog-form div.form-actions {
  float: left;
  margin: 3px 0;
}

.sidebar form#tyler-custom-search-blog-form input {
  width: 141px;
  background-color: #001c25;
  border: 1px solid #001c25;
  color: #fff;
  float: left;
  border-radius: 0;
}

.sidebar form#tyler-custom-search-blog-form input::-webkit-input-placeholder {
  color: #fff;
}

.sidebar form#tyler-custom-search-blog-form input::-moz-placeholder {
  color: #fff;
}

/* firefox 19+ */
.sidebar form#tyler-custom-search-blog-form input:-ms-input-placeholder {
  color: #fff;
}

/* ie */
.sidebar form#tyler-custom-search-blog-form input:-moz-placeholder {
  color: #fff;
}

.sidebar form#tyler-custom-search-blog-form input.form-submit {
  background: #001c25 url("../images/ui/ui-arrows-sprites.png") no-repeat 100% -1044px;
  width: 30px;
  border-radius: 0;
  color: #E1E1E1;
  cursor: pointer;
  font-size: 0.75em;
  height: 36px;
  text-indent: -999px;
  overflow: hidden;
}

.sidebar form#tyler-custom-search-blog-form input.form-submit.hover {
  background-position: 100% -1175px;
}

/* Sidebar Subsection - START
*********************************/
.tc-section-sidebar .subsection.last {
  background: none;
}

.tc-section-sidebar .subsection .ui-datepicker {
  margin-top: 5px;
}

/* Continue Education Programs */
.node-type-continuing-education-program ul#gridlist-views.list li .right-col a.button.more {
  margin: 0 0 0 0;
}

.node-type-continuing-education-program .main-content h2.block-title {
  color: #000;
  font-size: em(30);
  border-bottom: 3px solid #fff;
  margin: 25px 0 30px 0;
  padding: 0 0 8px 0;
}

.node-type-continuing-education-program ul#gridlist-views.list li .right-col {
  width: 18%;

  @media (min-width: em(768)) {
    width: 24%;
  }

  @media (min-width: em(1024)) {
    width: 18%;
  }
}

.node-type-continuing-education-program ul#gridlist-views.list li .description {
  width: 75%;

  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(600)) {
    width: 63%;
  }

  @media (min-width: em(1024)) {
    width: 75%;
  }
}

.node-type-continuing-education-program .region-sidebar-second {
  border-left: 1px solid #b9beb7;

  @media (min-width: em(320)) {
    border-left: none;
    border-top: 1px solid #b9beb7;
  }

  @media (min-width: em(1024)) {
    border-left: 1px solid #b9beb7;
    border-top: none;
  }
}

.node-type-continuing-education-program .region-content {
  width: 71%;

  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(1024)) {
    width: 71%;
  }
}

/* Add padding so that mobile menu pullout arrow has space */
@media (min-width: 20em) {

  .node-type-faculty #main,
  /*.node-type-gallery #main,*/
  .node-type-academic-degree #main,
  .node-type-basic #main,
  .node-type-article #main,
  .node-type-academic-program #main,
  .node-type-feature-block #main,
  .page-taxonomy #main,
  .node-type-event #main,
  .node-type-blog #main,
  .node-type-media-page #main,
  .section-blog #main,
  .page-event-calendar #main {
    padding-top: 25px;
  }
}

/* Add remove mobile menu pullout arrow extra space */
@media (min-width: 410px) {

  .node-type-faculty #main,
  /*  .node-type-gallery #main,*/
  .node-type-academic-degree #main,
  .node-type-basic #main,
  .node-type-article #main,
  .node-type-academic-program #main,
  .node-type-feature-block #main,
  .page-taxonomy #main,
  .node-type-event #main,
  .node-type-blog #main,
  .node-type-media-page #main,
  .section-blog #main,
  .page-event-calendar #main {
    padding-top: 0;
  }
}

// make sure header logo styling doesn't break for certain pages
.node-type-academic-program, .node-type-landing-page {
  .logo h1 {
    margin-bottom: 0;
  }
}
