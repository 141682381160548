
.page-search #search-form {
  position: relative;
  top: 0;
  padding: 10px 10px;
  .form-submit {
    top: 40px !important;
  }
  #edit-keys {
    border: 1px solid black;
  }
  .search-advanced {
    margin-top: 1em;
    margin-bottom: 0;
  }
  //advanced items
  .criterion {
    width: 100%;
    @media (min-width: 768px) {
      width: 45%;
    }
    .form-type-checkboxes {
      .form-type-checkbox {
        input {
          display: inline-block;
          width: auto;
        }
      }
    }
    input {
      border: 1px solid black;
    }
  }
}

.node-type-academic-program h1,
.node-type-landing-page h1 {
    margin-top: -25px;
}
.node-type-academic-program h1,
.node-type-landing-page h1 {
  @media (min-width: em(1024)) {
    margin-top: -25px;
  }
}

.node-type-landing-page.section-temple-contemporary h1 {
  display: block;
}

.gallerySlider.thumbsactive {
  //need to set explicit sizes to ensure the image is properly set
  //and the gallery item doesn't cut anything off.
  height: 214px; 
  @media (min-width: 500px) {
    height: 330px;
  }
  @media (min-width: 768px) {
    height: 100%;
  }
  .slides {
    height: inherit;
    .image {
      img {
        height: 214px; 
        @media (min-width: 500px) {
          height: 330px;
        }
        @media (min-width: 768px) {
          height: auto;
        }
      }
    }
  }
  .flex-direction-nav {
    display: block;
  }
}

.node-gallery {
  #single-wrap {
    .right.col-one-quarter {
      @media (min-width: 768px) {
        position: relative;
        display: block;
        top: 115px;
      }
      @media (min-width: 1024px) {
        top: 0;
      }
    }
  }
}

#item-wrap li.item.active, #item-wrap li.item.show-item {
  z-index: 999;
}