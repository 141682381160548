/* ===RESETS=== */
* {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  font-size: 100%;
}

html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(204, 0, 0, 0.5);
  /* Tap highlight for mobile devices */
  -moz-tap-highlight-color: rgba(204, 0, 0, 0.5);
  -o-tap-highlight-color: rgba(204, 0, 0, 0.5);
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}

body {
  height: 100%;
  font-size: em(16);
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  color: #151515;
  background: #DDD url(../images/bg/bg-pattern.png) repeat;
}

body,
img {
  transition: all .2s linear;
  -o-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -webkit-transition: all .2s linear;
}

body.page-event-calendar #page,
body.page-events-list #page,
body.page-exhibition.page-views #page,
body.page-node-student-work.page-views #page,
body.page-student-exhibitions.page-views #page,
body.page-featured-work #page {
  background: url(../images/bg/bg-transparent-green.png) repeat;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

a:link,
a:visited {
  text-decoration: none;
  font-size: 0.875em;
  font-weight: 700;
  color: $darkBlue;
  @include transitions-color;
}

a:hover,
a:active,
a:focus {
  color: $darkRed;
  text-decoration: none;
  @include transitions-background-color;
}

label {
  margin-bottom: 0.83333em;
  display: block;
}

/* ===HTML5 RESETS=== */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

/* ===GENERAL ELEMENT STYLES=== */
h1,
h4,
h5,
.item-title,
.block-label,
.subscribe-label,
.share-page-label,
.degree-label {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
}

h2,
h3,
h6,
.event-title {
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
}

h1 {
  @media (min-width: em(320)) {
    font-weight: 100;
    text-transform: uppercase;
    color: $darkRed;
    font-size: em(36);
    line-height: em(18);
  }

  @media (min-width: em(768)) {
    font-size: em(50);
    line-height: em(16);
  }

  @media (min-width: em(1024)) {
    font-size: em(65);
  }
}

h2 {
  line-height: em(18);
  font-size: 1.500em;
  font-weight: 700;
  margin-bottom: 0.313em;
  color: #3a8e33;

  @media (min-width: em(320)) {
    font-size: em(21);
  }

  @media (min-width: em(768)) {
    font-size: 1.500em;
  }
}

h3,
.event-title {
  line-height: 1.250em;
  font-size: 1.250em;
  font-weight: 700;
  margin-bottom: 0.313em;
  color: $darkBlue;

  @media (min-width: em(320)) {
    font-size: em(17);
  }

  @media (min-width: em(768)) {
    font-size: 1.250em;
  }
}

h4,
.item-title,
.subscribe-label {
  line-height: em(21);
  font-size: em(15);
  color: $darkBlue;
  font-weight: 700;
  margin-bottom: 0.625em;
  text-transform: uppercase;

  a {
    letter-spacing: em(2);

    &:link,
    &:visited {
      color: $darkRed;
    }

    &:hover,
    &:active {
      color: $darkBlue;
    }
  }

  @media (min-width: em(320)) {
    font-size: em(13);
  }

  @media (min-width: em(768)) {
    font-size: em(15);
  }
}

h5,
.block-label,
.share-page-label,
.degree-label {
  line-height: 1.14286em;
  font-size: 0.750em;
  letter-spacing: 0.125em;
  color: $darkBlue;
  font-weight: 800;
  text-transform: uppercase;
}

.page-views h5,
.page-views .block-label {
  color: #fff;
}

h6 {
  line-height: em(20);
  font-size: 0.688em;
  font-weight: 700;
  font-style: italic;
  color: $darkRed;
}

.node-type-academic-program a.button,
.program-main a.button {
  border: 1px solid #a9afb1;
}

.description a.link {
  font-size: em(18);
}

a.link {
  clear: both;
  display: inline;
  font-size: em(14);
  padding: 0 em(28) 0 0;
}

a.link.more {
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: 100% -431px;
}

a.button {
  clear: both;
  display: inline-block;
  font-size: em(14);
  line-height: em(14);
  padding: 0.375em em(34) 0.313em em(15);
  border-radius: em(10);
  border: 1px solid $darkBlue;
}

a.button:hover,
a.button:focus,
a.button.more:hover,
a.button.more:focus,
a.button.download:hover,
a.button.download:focus,
a.button.external-link:hover,
a.button.external-link:focus {
  background-color: #fff;
}

a.button.more {
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: 98% -429px;
}

a.button.download {
  background: url(../images/ui/ui-icons-sprites.png) no-repeat;
  background-position: 96% -1624px;
  margin-top: 15px;
}

a.button.calendar {
  background: url(../images/ui/ui-icons-sprites.png) no-repeat;
  background-position: 108% -197px;
  width: auto;
}

a.button.external-link {
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: 93% 5px;
  padding: 0.375em em(34) 0.313em em(15);
}

a.external-link {
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: 100% 3px;
  padding: 0 em(20) 0 0;
}

.calendar {
  width: auto;
}

p {
  font-size: em(14);
  line-height: 1.688em;
  padding: em(10) 0 em(10) 0;


}

.main-content {
  p, li {
    font-size: 1rem;

    a:link,
    a:visited {
      color: $darkRed;
      font-size: inherit;

      &:hover {
        color: $darkBlue;
      }
    }
  }

  .field-body img {
    max-width: 100%;
  }
}

.front p,
p.description {
  font-size: em(12);
}

#skip-link,
a.audience-skip {
  text-align: center;
  padding: 10px 25px;
  font-size: em(20);
  background-color: $darkRed;
  display: block;
}

a#skip-link,
a.audience-skip {
  color: #fff;
}

ul,
ul li,
ol,
ol li,
dd {
  margin: 0;
}

ol,
ul,
li {
  list-style: none;
}

ul.inline li {
  display: inline-block;
  margin: 0;
  padding: 0;
}

figure {
  max-width: 100% !important;
  height: 100% !important;
  display: inline-block;
  position: relative;
}

figcaption p {
  display: block;
  font-size: 0.750em;
  color: #151515;
}

figcaption {
  width: 180px;
  height: auto;
  padding: 20px;
  position: absolute;
  bottom: 0;
}

small {
  font-size: 85%;
}

blockquote {
  margin: 0 1.125em 1.125em;
  padding: 1.125em 1.125em 0;
  border-left: .25em solid #666;
  display: block;
  background: #eee;
  font-style: italic;
  overflow: hidden;
}

blockquote p {
  font-size: 1em;
}

hr {
  margin: 1.875em 0;
  padding: 0;
  height: 1px;
  display: block;
  border: 0;
  border-top: 2px solid $lightGrey;
  clear: both;
}

#skip-link a:hover,
#skip-link a:active,
#skip-link a:focus {
  color: $darkBlue;
}

/* Fluid Grids - START
*********************************/
.grid-wrap {}

.grid-wrap:before,
.grid-wrap:after {
  content: "";
  display: table
}

.grid-wrap:after {
  clear: both
}

.grid {
  float: left;
  padding-left: 1.250em;
  /* 20px gutter between columns */
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @media (min-width: em(320)) {
    padding-left: 0;
  }

  @media (min-width: em(768)) {
    padding-left: 1.250em;
  }
}

.col-full {
  @media (min-width: em(320)) {
    min-height: 100%;
    width: 100%;
    height: 100%;
  }
}

.col-one-half {
  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(580)) {
    width: 50%;
  }

  @media (min-width: em(768)) {
    width: 50%;
  }

  @media (min-width: em(1024)) {
    width: 50%;
  }
}

.col-one-third {
  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 50%;
  }

  @media (min-width: em(1024)) {
    width: 33.333%;
  }
}

.col-one-quarter {
  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 33.333%;
  }

  @media (min-width: em(1024)) {
    width: 25%;
  }
}

.col-three-quarters {
  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 100%;
  }

  @media (min-width: em(1024)) {
    width: 74.444%;
  }
}

.col-two-thirds {
  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 50%;
  }

  @media (min-width: em(1024)) {
    width: 66.666%;
  }
}

/* Loader */
.front-loader {
  top: 400px;
}

.loading_ajax {
  top: 50%;
}

.front-loader,
.loading_ajax,
  {
  background: $red url("../images/ui/loading.gif") no-repeat;
  z-index: 999;
  text-indent: -9999px;
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  background-size: 50% 50%;
  background-position: 50% 50%;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  border: 2px solid #fff;
}

.loading {
  width: 100%;
  height: 100%;
  background: #000 url("../images/ui/loading-dark.gif") no-repeat center center;
}

/* .image thumb */
.page-taxonomy-term img {
  width: auto;
  height: auto;
  display: block;
}

.image img,
.img-holder img {
  width: 100%;
  height: auto;
  display: block;
  -ms-interpolation-mode: bicubic;
}

.image {
  position: relative;
  margin-bottom: 1.125em;

  span.roll {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 0 50px 50px;
    border-color: transparent transparent #da392b transparent;

    a {
      background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
      background-position: 115% -1027px;
      overflow: hidden;
      text-indent: -9999px;
      width: 50px;
      height: 50px;
      position: absolute;
      margin: 0 0 0 -50px;
    }

    .plus {
      background: url(../images/ui/ui-icons-sprites-2.png) no-repeat;
      background-position: 96% -1080px;
      overflow: hidden;
      text-indent: -9999px;
      width: 50px;
      height: 50px;
      position: absolute;
      margin: 0 0 0 -50px;
    }
  }
}

/* rollover bg */
li .bg {
  @media (min-width: em(320)) {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
  }

  @media (min-width: em(768)) {
    -webkit-transition: all 0.15s ease-out 0.1s;
    -moz-transition: all 0.15s ease-out 0.1s;
    -o-transition: all 0.15s ease-out 0.1s;
    transition: all 0.15s ease-out 0.1s;
  }
}

li:hover>.bg {
  @media (min-width: em(320)) {
    margin: -18px -18px;
    padding: 18px 18px;
    background: rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 1px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 1px 5px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: em(768)) {
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transition-duration: 0.1s;
    -moz-transition-duration: 0.1s;
    -o-transition-duration: 0.1s;
    transition-duration: 0.1s;
  }
}

/* Blog Tags */
.tags,
ul.field-tags,
ul.field-blog-categories {
  padding: 0 0 em(10) 0;
  line-height: em(10);
  display: inline-block;
}

ul.slides li .tags ul li,
.tags ul li,
ul.field-tags li,
ul.field-blog-categories li {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  color: $darkRed;
  display: inline;
}

.tags ul li,
ul.field-tags li,
ul.field-blog-categories li {
  a {
    text-transform: uppercase;
    font-size: em(10);

    &:link,
    &:visited {
      color: $darkRed;
    }

    &:hover,
    &:active {
      color: $darkBlue;
    }
  }
}

/* Label */
span.label {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent transparent $red transparent;
  _border-color: #000000 #000000 $red #000000;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
  @include transitions;

  &:hover,
  &:focus {
    border-color: transparent transparent $darkRed transparent;
    _border-color: #000000 #000000 $darkRed #000000;
  }
}

span.label a {
  text-indent: -9999px;
  display: block;
  width: 50px;
  height: 50px;
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: 100% 50px;
  position: absolute;
  left: -50px;
}

span.label a.calendar {
  background: url(../images/ui/ui-icons-sprites.png) no-repeat;
  background-position: 150% -105px;
}

span.label a.blog {
  background: url(../images/ui/ui-icons-sprites.png) no-repeat;
  background-position: 150% -435px;
}

ul.event-info li {
  display: inline-block;
  line-height: 1.25em;
  font-size: em(11);
  font-weight: 700;
  font-style: italic;
  color: $darkRed;
}

ul.event-info li:after {
  content: "/";
  color: #dadada;
  width: 10px;
  margin: 0 5px 0 8px;
}

ul.event-info li:last-child:after {
  display: none;
}

/* info boxes */
.grid-view,
.info-boxes-wrapper {
  @media (min-width: em(320)) {
    margin: 30px 0 20px 0;
  }

  @media (min-width: em(768)) {
    margin: 30px 0 0px -20px;
  }
}

.info-block {
  @media (min-width: em(320)) {
    background-color: #fff;
    padding: 20px 20px 30px 20px;
    margin: 0 0 20px 0;
  }

  @media (min-width: em(1024)) {
    margin: 0 0 0 0;
  }
}

.info-block:hover {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .06), 0 1px 7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, .06), 0 1px 7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .06), 0 1px 7px rgba(0, 0, 0, 0.2);
  @include transitions;
}

.info-block h3,
.info-block h5 {
  display: inline-block;
  color: $darkBlue;
}

.info-block h3 {
  margin-bottom: em(18);
}

.info-block .image {
  margin: 0 em(-20) em(30) em(-20);
}

.info-block p {
  padding: em(5) 0 em(5) 0;
}

.info-block p a {

  &:link,
  &:visited {
    color: $red;
  }

  &:hover,
  &:focus {
    color: $darkBlue;
  }
}

.info-block p a.button {

  &:link,
  &:visited {
    color: $darkBlue;
    font-size: em(15);
  }

  &:hover,
  &:focus {
    color: $red;
  }

  &.more {
    background-position: 98% -430px;
  }
}

.ico {
  background: url("../images/ui/ui-icons-sprites-2.png") no-repeat;
  background-position: -1px -313px;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin: 0 10px -5px 0;
}

.ico.calendar {
  background-position: -1px -1px;
}

.ico.lecture {
  background-position: -1px -79px;
}

.ico.news {
  background-position: -1px -157px;
}

.ico.twitter {
  background-position: -1px -235px;
}

.ico.location {
  background-position: -1px -391px;
}

.ico.connect {
  background-position: -1px -471px;
}

.ico.rss {
  background-position: -1px -157px;
}

/* Block Twitter */
.block-twitter h6 {
  color: $darkBlue;
}

.block-twitter a,
.block-location a,
.block-connect a {

  &:link,
  &:visited {
    font-size: em(14);
    color: $red;
  }

  &:hover,
  &:focus {
    color: $darkBlue;
  }
}

.info-block a.button,
.block-twitter a.button,
.block-location a.button,
.block-connect a.button {
  color: $darkBlue;
  margin-top: em(15);
  font-size: 0.875em;

  &:hover,
  &:focus {
    color: $red;
    background-color: $lightGrey;
  }
}

/* Show & Hide links */
.hidden-text {
  display: none;
  padding: 0;
  margin: 0;
}

#show-hide {
  width: 100%;
  text-align: center;
  background: url("../images/bg/bg-line-grey.png") no-repeat center;

  a:link,
  a:visited {
    font-family: "proxima-nova", Helvetica, Arial, sans-serif;
    letter-spacing: em(3);
    text-shadow: 1px 1px 1px #fff;
    line-height: 1.14286em;
    font-size: em(11);
    font-weight: 800;
    text-transform: uppercase;
    color: $darkRed;
    padding: 0 em(30) 0 em(5);
    background: url("../images/ui/ui-arrows-sprites.png") no-repeat;
    background-position: 100% -174px;
    font-weight: 800;
  }

  a:hover,
  a:focus {
    color: red;
  }

  a.more {
    background-position: 100% -173px;
  }

  a.less {
    background-position: 100% -232px;
  }
}

#page.bg-cadet-blue #show-hide {
  background: url("../images/bg/bg-line-white.png") no-repeat center;
}

.section-admission-process #show-hide {
  display: none;
}

.section-admission-process .hidden-text {
  display: block;
}

/* grid view */
.grid-view {
  padding: 0;
}

.grid-view .grid {
  @media (min-width: em(320)) {
    position: relative;
    padding: em(20) 0;
  }

  @media (min-width: em(768)) {
    padding: em(20) 0 em(20) em(20);
  }
}

.grid-view li .item-content {
  position: relative;
  z-index: 5;
  cursor: pointer;
}

.grid-view h2:before {
  content: '';
  display: block;
  border-top: 3px solid #fff;
  margin-bottom: em(15);
}

.grid-view li.item:hover h2:before {
  border-top: 3px solid #e0e7e1;
}

.grid-view h2 a {
  background: url(../images/ui/ui-icons-sprites-2.png) no-repeat;
  background-position: -1px -312px;
  padding: 0 0 5px 30px;
  height: auto;
  display: block;
}

.grid-view li .item-content a.more span {
  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(768)) {
    display: inline-block;
  }
}

/* rollover bg */
.grid-bg {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;

  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(768)) {
    display: block;
  }
}

li:hover>.grid-bg {
  z-index: 1;
  margin: 0 0 0 0;
  padding: 18px 18px 18px 0;
  background-color: rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 1px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 1px 5px rgba(0, 0, 0, 0.2);
}

/* Dates & Times */
.date-time li {
  text-transform: uppercase;
  font-size: em(13);
  letter-spacing: em(2);
  font-weight: 700;
  margin: 7px 0 7px 0;

  .time {
    color: #6a6a6a;
    font-style: italic;
    text-transform: lowercase;
    letter-spacing: em(1);
    padding-left: em(7);
  }
}

/* Search Results */
.page-search #main {
  background: url(../images/bg/bg-transparent-grey-2.png) repeat;

  .container {
    position: relative;
  }
}

.page-search #main.border {
  border-top: none;
}

.page-search h1 {
  padding-bottom: 20px;
  border-bottom: 3px solid #fff;
}

.search-results p {
  margin: 0 0 0 0;
  padding: em(5) 0 em(25) 0;
}

.page-search .search-snippet-text {
  @media (min-width: em(320)) {
    padding: 30px 0 0 20px;
  }

  @media (min-width: em(768)) {
    padding: 0 0 0 0;
  }

  h3 {
    padding-left: 8px;
    display: inline-block;
  }
}

.page-search a.button {
  border-color: $darkGrey;
}

.search-result {
  padding: em(25) 0;
  border-top: 1px solid #f2f1f1;
}

.search-results .title {
  font-size: 0.9375em;
}

.page-search #search-form {
  @media (min-width: em(320)) {
    background-color: rgba(255, 255, 255, 0.6);
    right: 0;
    margin-bottom: em(25);
    position: relative;
    top: 0;
  }

  @media (min-width: em(1024)) {
    position: absolute;
    top: -135px;
  }
}

.page-search #search-form input {
  width: 83%;
  height: 45px;
  background: transparent;
  border: none;
  box-shadow: none;
}

.page-search #search-form input.form-text {
  text-transform: capitalize;
  font-size: em(18);
  font-weight: 700;
  color: $darkBlue;
}

.page-search #search-form label {
  //display: none;
}

.page-search #search-form {
  padding: 10px 10px;
  .form-submit {
    top: 40px !important;
  }
  #edit-keys {
    border: 1px solid black;
  }
  .search-advanced {
    margin-top: 1em;
    margin-bottom: 0;
  }
  .criterion {
    width: 100%;
    @media (min-width: 768px) {
      width: 45%;
    }
    .form-type-checkboxes {
      .form-type-checkbox {
        input {
          display: inline-block;
          width: auto;
        }
      }
    }
    input {
      border: 1px solid black;
    }
  }
}

.page-search #search-form .form-submit {
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: -1px -1314px;
  height: 33px;
  width: 26px;
  position: absolute;
  top: 8px;
  right: 0;
  overflow: hidden;
  text-indent: -9999px;
  cursor: pointer;
}

.page-search #search-form .form-submit:hover {
  background-position: -1px -1390px;
}

/* Page Share - Grey Icons - START */
#main .share {
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/bg/bg-share.png) no-repeat 0 0;
  height: 110px;
  width: 110px;
  padding: 15px;
  text-align: right;

  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(768)) {
    display: block;
  }
}

#main.border .course-popup .share {
  margin: 0 0 0 0;
}

#main.border .share {
  margin: -20px -20px 0 0;
}

#main.border .share .share-page-label {
  display: block;
  color: $darkBlue;
  letter-spacing: em(3);
  margin: em(10) 0 0 0;
}

#main .share ul.follow {
  margin: 8px 0 0 0;
  height: 16px;
}

#main .share ul.follow li {
  display: inline-block;
}

#main .share ul.follow li a {
  display: block;
  padding-right: 0;
  margin-left: 9px;
  height: 16px;
  overflow: hidden;
  text-indent: 9999px;
  cursor: pointer;
  background: url("../images/ui/ui-social-sprites.png") no-repeat;
}

#main .share ul.follow li a.facebook {
  width: 8px;
  background-position: -1px -260px;
}

#main .share ul.follow li a.facebook:hover {
  background-position: -1px -66px;
}

#main .share ul.follow li a.twitter {
  width: 17px;
  background-position: -1px -576px;
}

#main .share ul.follow li a.twitter:hover {
  background-position: -1px -388px;
}

/* Share - Red Icons - START */
ul.tyler-share,
footer#footer ul.tyler-share,
  {
  margin: 8px 0 0 0;
  height: 16px;

  li {
    display: inline-block;

    a {
      display: block;
      padding-right: 0;
      margin-right: 6px;
      height: 16px;
      overflow: hidden;
      text-indent: -9999px;
      cursor: pointer;
      background: url("../images/ui/ui-social-sprites.png") no-repeat;

      &.facebook {
        width: 8px;
        background-position: -1px -1px;

        &:hover {
          background-position: -1px -66px;
        }
      }

      &.twitter {
        width: 17px;
        background-position: -1px -324px;

        &:hover {
          background-position: -1px -388px;
        }
      }

      &.flickr {
        width: 21px;
        background-position: -1px -639px;

        &:hover {
          background-position: -1px -700px;
        }
      }

      &.rss {
        width: 14px;
        background-position: -1px -883px;

        &:hover {
          background-position: -1px -946px;
        }
      }

      &.email {
        width: 16px;
        background-position: -1px -1264px;

        &:hover {
          background-position: -1px -1326px;
        }
      }
    }
  }
}

/* Page Share - White Icons - START */
.share-gallery {
  padding: 10px 15px;
}

.share-gallery .share-label,
#block-blog ul.slides li .share-content .share-label {
  font-style: normal;
  color: $darkBlue;
  font-size: em(12);
  padding-right: 0.625em;
  line-height: 1.25em;
  font-weight: 700;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
}

.share-gallery ul.tyler-share {
  margin: 8px 0 0 0;
  height: 16px;
}

.share-gallery ul.tyler-share .share-label,
.share-gallery ul.tyler-share li {
  display: inline-block;
}

.share-gallery ul.tyler-share li a.facebook {
  width: 8px;
  background-position: -1px -65px;
}

.share-gallery ul.tyler-share li a.facebook:hover {
  background-position: -1px -1px;
}

.share-gallery ul.tyler-share li a.twitter {
  width: 17px;
  background-position: -1px -387px;
}

.share-gallery ul.tyler-share li a.twitter:hover {
  background-position: -1px -324px;
}

.share-gallery ul.tyler-share li a.email {
  width: 17px;
  background-position: -1px -1191px;
}

.share-gallery ul.tyler-share li a.email:hover {
  background-position: -1px -1264px;
}

/* pager */
.item-list .pager {
  padding: em(8) 0;
  margin: em(35) 0 em(35) 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.item-list .pager li {
  margin: 0 0 0 0;
  padding: 0 em(10);
  line-height: 1.14286em;
  letter-spacing: 0.125em;
  font-weight: 800;
  text-transform: uppercase;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;

  a:link {
    font-size: em(12);
    color: #494848;
    text-shadow: 1px 1px 1px #fff;
  }
}

.item-list .pager li.pager-next,
.item-list .pager li.pager-last,
.item-list .pager li.pager-previous,
.item-list .pager li.pager-first {
  a:link {
    color: $darkRed;
  }
}

.item-list .pager li,
.item-list .pager li.pager-next,
.item-list .pager li.pager-last,
.item-list .pager li.pager-previous,
.item-list .pager li.pager-first {

  a:hover,
  a:focus {
    color: $darkBlue;
  }
}
