.view-student-work .item-list,
.view-exhibitions .item-list { 
    position: absolute; 
    bottom: 0;
    color: #fff;
    width: 100%;
    .pager li a { color: #fff; text-shadow: 0px #fff;}
}
/*Isotope Filtering*/
.isotope-item { z-index: 2 }
.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}
/* Isotope CSS3 transitions */
.isotope,
.isotope .isotope-item { -webkit-transition-duration: 0.8s }
.isotope { -webkit-transition-property: height, width }
.isotope .isotope-item { -webkit-transition-property: -webkit-transform, opacity }
/* disabling Isotope CSS3 transitions */
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition { -webkit-transition-duration: 0s }
/* List item */
.close_ajax {
    z-index: 999;
    display: block;
    position: absolute;
    height: auto;
    width: 75px;
    -webkit-transition-duration: 0.20s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-duration: 0.20s;
    -moz-transition-timing-function: ease-out;
}
a.close_ajax {
    background: url(../images/ui/ui-icons-sprites.png) no-repeat;
    background-position: 5px -1004px;
    font-family: "proxima-nova", Helvetica, Arial, sans-serif;
    text-align: right;
    color: #fff;
    font-size: em(14);
    letter-spacing: 0.125em;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
}
a.close_ajax:hover {
    color: $red;
}
#item-wrap {
    display: none;
    min-height: 450px;
    height: auto;
    margin: 0 0 50px 0;
}
#item-wrap li.item {
    display: block;
    float: left;
    width: auto;
    height: auto;
    z-index: 5;
}
#item-wrap li.item.activeItem{
    background: #b2afad;
    margin-bottom: 20px;
    z-index: 100;
}
#item-wrap li.item h4 {
    margin-bottom: 0;
}
#item-wrap li.item h4 a{
    @media (min-width: em(320)) {
        font-size: em(13);
    }
    @media (min-width: em(768)) {
        font-size: em(15);
    }
}
#item-wrap li.item .innerItem{
    position: relative;
}
#item-wrap li.item.active,
#item-wrap li.item.show-item {
    position: relative;
    z-index: 15;
}
#item-wrap li.item.active .innerItem:hover > .white-bg-pop,
#item-wrap li.item.show-item .white-bg-pop {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    margin: -18px -18px;
    padding: 18px 18px;
    background: rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 1px 5px rgba(0,0,0,0.2);
        -moz-box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 1px 5px rgba(0,0,0,0.2);
        box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 1px 5px rgba(0,0,0,0.2);
}
#item-wrap li.item .innerItem .item-info {
    display: none;
    position: relative;
    z-index: 10;
    margin: 20px 0 0 0;
}
#item-wrap li.item .innerItem .image{
    position: relative;
    margin: 0 0 0 0;
    z-index: 10;
    background: #b2afad;
}
#item-wrap li.item a.link {
    padding-left: 0.625em;
    margin-left: 0.625em;
    border-left: 1px solid $grey;
}
#single-wrap {
    background: #b2afad;
}
a.gallery-thumbnail-toggle.active:before {
    content: "";
    display: block;
    position: absolute;
    left: 45%;
    top: -8px;
    width: 0;
    height: 0;
    z-index: 500;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 8px solid #dadada;
}
a.gallery-thumbnail-toggle{
    position: relative;
    font-family: "proxima-nova", Helvetica,Arial,sans-serif;
    font-size: em(11);
    letter-spacing: 2px;
    color: #fff;
    text-transform: uppercase;
    padding: 15px 0 13px 0;
    text-align: center;
    border-bottom: 2px solid #fff;
    margin-left: -6px;
    background: url("../images/ui/ui-icons-sprites.png") no-repeat;
    background-position: 28% -1243px;
    &:hover, &.active {
        color: $red;
        background-color: #dadada;
        background-position: 28% -1180px;
    }
}
#item-wrap li.item .col-three-quarters,
#single-wrap .col-three-quarters{
    @media (min-width: em(320)) {
        width: 100%;
    }
    @media (min-width: em(768)) {
        width: 74.444%;
    }
}
#item-wrap li.item .col-one-quarter,
#single-wrap .col-one-quarter{
    @media (min-width: em(320)) {
        width: 100%;
    }
    @media (min-width: em(768)) {
        width: 23.33%;
    }
    @media (min-width: em(1024)) {
        width: 25%;
    }
}
#item-wrap li.item .content-left,
#single-wrap .content-left{
    min-height: 80px;
    padding: 10px 20px;
    border-right: 2px solid #fff;
    @media (min-width: em(320)) {
        border-right: none;
    }
    @media (min-width: em(768)) {
        border-right: 2px solid #fff;
    }
}
#single-wrap h2,
#item-wrap h2 {
    color: #fff;
    margin-top: em(10);
    margin-bottom: 0;
}
#single-wrap h3,
#item-wrap h3 {
    display: inline-block;
    text-transform: uppercase;
    font-size: em(15);
    margin: 0;
    font-family: "proxima-nova", Helvetica,Arial,sans-serif;
    letter-spacing: em(2);
}
#single-wrap p.subtitle,
#item-wrap .content-left p.subtitle {
  padding: 0;
  line-height: 1em;
  color: #fff;
  font-style: italic;
  font-size: em(16);
}

#single-wrap p.description,
#item-wrap .content-left p.description {
    width: 95%;
}
ul.field-artwork-medium {
    margin-right: em(10);
    display: inline-block;

    li { display: inline;}
    li a {
        display: inline-block;
        background: $darkBlue;
        border-radius: em(16);
        padding: em(5) em(15);
        color: #fff;
        font-size: em(12);

        &:hover{
            background: $red;
        }
    }
}
#single-wrap .gallery-title,
#item-wrap .gallery-title {
  color: #001c25;
  font-family: "proxima-nova", Helvetica,Arial,sans-serif;
  font-size: em(14);
  margin-top: -.5em;
  text-transform: uppercase;
  display: inline;
}
.artwork-medium {
  background: #011c24;
  color: white;
  font-weight: 700;
  font-size: em(12);
  padding: .3em .75em .15em .75em;
  margin-right: 1em;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  border-radius:10px;
}
/* List View */
#item-wrap.list{
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
#item-wrap.list li.item {
    display: block;
    float: none;
    width: 100%;
    height: auto;
    z-index: 5;
    margin: 20px 0 0 0;
    padding:0;
    background: #dcdede;
}
#item-wrap.list li.item.activeItem{
    z-index: 300;
}
#item-wrap.list li.item h4 {
    margin-bottom: 0;
}
#item-wrap.list li.item .innerItem{
    position: relative;
}
#item-wrap.list span.roll {
  display: none;
}
#item-wrap.list li.item.active {
    position: relative;
    z-index: 15;
}
#item-wrap.list li.item.active .innerItem:hover > .white-bg-pop {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    margin: -18px -18px;
    padding: 18px 18px;
    background: rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 1px 5px rgba(0,0,0,0.2);
        -moz-box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 1px 5px rgba(0,0,0,0.2);
        box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 1px 5px rgba(0,0,0,0.2);
}
#item-wrap.list .thumbwrap {
    float: left;
    width: 15%;
    @media (min-width: em(320)) {
        width: 35%;
    }
    @media (min-width: em(1024)) {
        width: 15%;
    }
}
#item-wrap.list li.item .innerItem .item-info {
    display: block;
    position: relative;
    float: left;
    z-index: 10;
    width: 80%;
    margin: 10px 0 0 1em;
    @media (min-width: em(320)) {
        width: 53%;
    }
    @media (min-width: em(1024)) {
        width: 80%;
    }
}
#item-wrap.list .item-info .description {
    width: 100%;
    @media (min-width: em(320)) {
        display: none;
    }
    @media (min-width: em(768)) {
        display: block;
    }
}
#item-wrap.list li.item .innerItem .image{
    position: relative;
    margin: 0 0 0 0;
    z-index: 10;
    background: #b2afad;
}
#item-wrap.list li.item a.link {
    padding-left: 0.625em;
    margin-left: 0.625em;
    border-left: 1px solid #817e7b;
}
#item-wrap.list li.item .content-left{
    padding: 20px 20px;
    border-right: 2px solid #fff;
    background: #b2afad;
    @media (min-width: em(320)) {
        border-right: none;
    }
    @media (min-width: em(1024)) {
        border-right: 2px solid #fff;
    }
}
/* Student Gallery Slider */
.gallerySlider {
    position: relative;
    height: 100%;
}
.gallerySlider .no-js .slides > li:first-child {display: block;}
.gallerySlider .slides {
    display: block;
    height: 600px;
    overflow: hidden;

    @media (min-width: em(320)) {
        height: auto;
    }
    @media (min-width: em(768)) {
        height: 425px;
        background: #000 url("../images/ui/loading-dark.gif") no-repeat;
        background-position: center center;
    }
    @media (min-width: em(1024)) {
        height: 600px;
    }
}
.view-student-work .gallerySlider,
.view-exhibitions .gallerySlider,
#single-wrap .gallerySlider {
    border-bottom: 1px solid $darkBlue;
}
#item-wrap .gallerySlider .image .flex-title,
#single-wrap .gallerySlider .image .flex-title,
#item-wrap .gallerySlider .image .flex-description,
#single-wrap .gallerySlider .image .flex-description{
    text-indent: -9999px;
    display: none;
}
.gallerySlider .image {
    margin: 0;
    background: #000;
    img {
        display: block;
        width: auto;
        height: auto;
        margin: 0 auto;
    }
}
#item-wrap .share-gallery,
#single-wrap .share-gallery{
    @media (min-width: em(320)) {
        margin-bottom: 10px;
        border-top: 2px solid #fff;
    }
    @media (min-width: em(768)) {
        margin-bottom: 0;
        border-top: 0;
    }
}
.gallerySlider .flex-direction-nav {
    position: absolute;
    bottom: em(-50);
    right: 27%;
    top: inherit;
    @media (min-width: em(320)) {
        right: 3%;
        bottom: 0;
        //display: none;
    }
    @media (min-width: em(600)) {
        bottom: em(-50);
    }
    @media (min-width: em(1024)) {
        right: 27%;
        display: block;
    }
}
.gallerySlider.thumbsactive .flex-direction-nav {
    position: absolute;
    bottom: em(-50);
    right: 27%;
    top: inherit;
    @media (min-width: em(320)) {
        right: 3%;
        display: block;
        bottom: 0;
    }
    @media (min-width: em(600)) {
        bottom: em(-50);
    }
    @media (min-width: em(768)) {
        right: 27%;
    }
}
.gallerySlider .flex-caption {
    bottom: 0;
    left: 0;
    width: auto;
    max-width: 100%;
    height: auto;
    min-height: auto;
    background: rgba(0, 0, 0, 0.8);
    padding: 5px 25px;
    p {
        color: #fff;
    }
}
.flex-control-nav.flex-control-thumbs {
  position: relative;
  display: none;
  width: 100%;
  top: 0;
  background: $darkBlue;
  padding: .6em 1em .25em .5em;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
}
.flex-control-nav.flex-control-thumbs.active{
  display: block;
}
.flex-control-nav.flex-control-thumbs img{
    opacity: .5;
    cursor: pointer;
}
.flex-control-nav.flex-control-thumbs img.flex-active{
    opacity: 1;
    cursor: default;
}
.gallery-thumbnail-toggle {
  display:none;
  @media (min-width: em(1024)) {
  		display: block;
  	}
}

#block-student-work-sort .views-exposed-widgets{
    margin: 0 0 0 0;
    @media (min-width: em(320)) {
        display: none;
    }
    @media (min-width: em(1024)) {
        display: block;
    }
}
#block-student-work-sort .views-exposed-form{
    position: relative;
    float: left;
}
#block-student-work-sort .views-exposed-form .views-exposed-widget{
    width: auto;
    padding: 0 0;
}
#block-student-work-sort label{
    color: #fff;
    font-size: em(15);
    line-height: 45px;
    padding: 0 0 0 50px;
    margin: 0 0 0 0;
    display: block;
    background: url(../images/ui/ui-icons-sprites.png) no-repeat 20px -788px;
    border-right: 1px solid #fff;
    width: 300px;
    position: relative;
    cursor: pointer;
    @include box-sizing;
}
#block-student-work-sort label:after,
#block-student-work-sort label.active:after {
    content: "";
    display: block;
    background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    right: 0;
}
#block-student-work-sort label:after {
    background-position: 12px -972px;
}
#block-student-work-sort label.active:after {
    background-position: 12px -905px;
}
#block-student-work-sort label.active {
    background: rgba(0, 28, 37, 0.8) url(../images/ui/ui-icons-sprites.png) no-repeat 20px -788px;
}
#block-student-work-sort .form-type-select {
    display: none;
    border-top: 1px solid #334951;
    padding: em(20) em(50);
    background: rgba(0, 28, 37, 0.8);
    position: absolute;
    z-index: 100;
    width: 393px;
    @include box-sizing;
}
#block-student-work-sort .views-exposed-form .form-item a {
    font-family: "proxima-nova", Helvetica,Arial,sans-serif;
    line-height: 30px;
    font-size: em(10);
    letter-spacing: em(3);
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    padding: 3px 0 0 30px;
    background: url(../images/ui/ui-sort-sprites.png) no-repeat;
    background-position: 0 -791px;
    &:hover{
        color: $red;
    }
}
#block-student-work-sort .views-exposed-form .form-item.selected a {
    color: $red;
    background-position: 0 -862px;
}
.view-exhibitions {
    @media (min-width: em(768)) { min-height: 550px;}
}


/* Visual Studies Dept Student Work Sort Specific Styles
********************************************************/
.page-node-42 .sort-bar .filterable li:nth-child(1),
.page-node-42 .sort-bar .filterable li:nth-child(2) {
  display: none;
}
.page-node-42 .sort-bar .filterable li:nth-child(3) {
  color: #a8291e;
  background-color: rgba(255, 255, 255, 0.7);
}
.page-node-42 .sort-bar .filterable li:nth-child(3) a {
 color: #a8291e;
}

.page-node-42 .sort-bar .filterable li:nth-child(3) a:before {
    content: "";
    display: block;
    position: absolute;
    padding: 0;
    left: 40%;
    bottom: -9px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 8px solid #fff;
}