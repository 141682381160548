.form-item { /* Wrapper for a form element (or group of form elements) and its label */
  margin: 1.5em 0;
  input.error,
  textarea.error,
  select.error { /* Highlight the form elements that caused a form submission error */
    border: 1px solid #c00;
  }
  label { /* The label for a form element */
    display: block;
    font-size: 0.750em;
    font-family: "proxima-nova", Helvetica, Arial, sans-serif;
    font-weight: 800;
    letter-spacing: 0.125em;
    text-transform: uppercase;
    color: $darkRed;
  }
  label.option { /* The label for a radio button or checkbox */
    display: inline;
    font-weight: normal;
  }
  .form-required { /* The part of the label that indicates a required field */
    color: #c00;
  }
  .description { /* The descriptive help text (separate from the label) */
    margin: 10px 0 0 0;
    font-size: em(12);
    color: $darkBlue;
  }
  .form-checkboxes & ,
  .form-radios & { /* Pack groups of checkboxes and radio buttons closer together */
    margin: 0; /* Drupal core uses "0.4em 0" */
  }
}
.form-submit { /* The submit button */
  color: $lightGrey;
  padding: 9px 20px 9px 20px;
  font-size: 0.750em;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: $darkBlue;
  cursor: pointer;
}
.form-submit:hover {
  background-color: #013546;
}
.container-inline {
  div, label { /* Inline labels and form divs */
    /*display: inline;*/
  }
}
input {
  font-size: em(14);
}
body.page-printmail textarea{ min-height: 100px;}
body.page-printmail .form-submit { margin-right: 5px;}
input[type=text], input[type=password], input[type=email], textarea{
  padding: 0 10px;
  width: auto;
  height: 34px;
  color: #404040;
  background: white;
  border: 1px solid;
  border-color: #c4c4c4 #d1d1d1 #d4d4d4;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);
  @media (min-width: em(320)) {
    width: 95%;
  }
  @media (min-width: em(768)) {
    width: auto;
  }
}
input[type=text]:focus, input[type=password]:focus, textarea:focus {
  border-color: #7dc9e2;
}
.password-parent,
.confirm-parent {
  margin: 0;
}
#block-search-form,
.block-search { /* Wrapper for the search form */
  .form-item,
  .form-actions {
    margin: 0;
  }
}
form {
  th {
    text-align: left; /* LTR */
    padding-right: 1em; /* LTR */
    border-bottom: 3px solid #ccc;
  }

  tbody {
    border-top: 1px solid #ccc;
  }

  tr.even {
    background-color: #fff;
  }

  table .item-list ul {
    margin: 0;
  }
}
@if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
  .lt-ie8 tr.even,
  .lt-ie8 tr.odd {
    th,
    td {
      border-bottom: 1px solid #ccc; /* IE doesn't display borders on table rows */
    }
  }
}
td.menu-disabled {
  background: #ccc;
}
/* User login page */
.user-login-wrapper, .error-page-wrapper {
  position: relative;
  margin: 15% auto;
  padding: 0 25px 25px 25px;
  width: 61%;
  background: #fff;
  border-radius: 3px;
  -moz-box-shadow: 0 0 200px rgba(255, 255, 255, 0.7), 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 200px rgba(255, 255, 255, 0.7), 0 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 200px rgba(255, 255, 255, 0.7), 0 1px 2px rgba(0, 0, 0, 0.5);
  @media (min-width: em(320)) {
    width: 75%;
  }
  @media (min-width: em(768)) {
    width: 61%;
  }
}
.error-page-wrapper {
  padding: 0 25px 55px 25px;
  .content { text-align: center;}
}
.user-login-wrapper:before, .error-page-wrapper:before {
  content: '';
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  z-index: -1;
  background: rgba(0, 0, 0, 0.08);
}
.user-login-wrapper input[type=text], .user-login-wrapper input[type=password], 
.error-page-wrapper input[type=text], .error-page-wrapper input[type=password] {
  width: 94.55%;
}
.user-login-wrapper h1, .error-page-wrapper h1 {
  font-size: em(45);
  line-height: 1em;
  text-align: center;
  margin: 35px 0;
}
.user-login-wrapper a.external-link, .error-page-wrapper a.external-link {
  padding: 0 0 0 0;
}
.user-login-wrapper .logo, .error-page-wrapper .logo {
  width: 100%;
  height: auto;
  padding: 35px 25px 0 25px;
  margin: 0 0 0 -25px;
  border-radius: 3px 3px 0 0;
  background: url(../images/bg/bg-page-title-overlay.png) no-repeat;
  background-size: 100% 95%;
  min-height: 100px;
  @media (min-width: em(320)) {
    padding: 25px 25px 0 25px;
  }
  @media (min-width: em(768)) {
    padding: 35px 25px 0 25px;
  }
}
.user-login-wrapper .logo .logo-temple, .error-page-wrapper .logo .logo-temple {
  margin: 11px 25px 0 25px;
  width: 219px;
  height: 15px;
  background-size: 219px 15px;

  @media (min-width: em(320)) {
    width: 185px;
    height: 13px;
    background-size: 185px 13px;
    margin: 0 25px 10px 25px;
  }
  @media (min-width: em(835)) {
    margin: 9px 25px 0 25px;
  }
  @media (min-width: em(1024)) {
    margin: 11px 25px 0 25px;
    width: 219px;
    height: 15px;
    background-size: 219px 15px;
  }
}
.user-login-wrapper .logo .logo-tyler, .error-page-wrapper .logo .logo-tyler {
  width: 255px;
  height: 34px;
  border-left: 1px solid $grey;
  background: none;
  margin: 0 0 0 0;
  padding: 0 0 0 25px;

  @media (min-width: em(320)) {
    width: 207px;
    height: 28px;
    margin: 0 0 0 0;
    padding: 0 0 0 25px;
    background: none;
    border: none;
  }
  @media (min-width: em(835)) {
    border-left: 1px solid $grey;
  }
  @media (min-width: em(1024)) {
    width: 255px;
    height: 34px;
    border-left: 1px solid $grey;
  }
  img {
    float: left;
    width: 100%;
    height: auto;
  }
}
.lt-ie9 input[type=text], .lt-ie9 input[type=password] {
  line-height: 34px;
}
