.fullcalendar .ui-widget-content.fc-other-month * {
  height: auto;
}
/* Addmission Timeline - START
*********************************/
.bg-admission-blue {
	@media (min-width: em(320)) {
		background: #82d2df;
		moz-box-shadow: inset 0 0 20px #58b3c2;
		-webkit-box-shadow: inset 0 0 20px #58b3c2;
		box-shadow: inset 0 0 20px #58b3c2;
	}
	@media (min-width: em(768)) {
		moz-box-shadow: inset 0 0 20px #98c3cc;
		-webkit-box-shadow: inset 0 0 20px #98c3cc;
		box-shadow: inset 0 0 20px #98c3cc;
		background: #b7e8ee url("../images/bg/bg-admission-blue.jpg") no-repeat top left;
		@include background-cover;
	}
}
@media (min-width: 320px) {
	.toggle-timeline {
		display: none;
	}
	body.section-admission-process #main {
		padding-bottom: 30px;
	}
}
@media (min-width: 1024px) {
    .toggle-timeline {
		display: block;
	}
	body.section-admission-process #main {
		padding-bottom: 100px;
	}
}
body.section-admission-process #main {
	padding-bottom: 100px;
}
#block-admissions-timeline {
	padding: 50px 0 0 0;
}
#block-admissions-timeline h1 {
	z-index: 1;
}
.toggle-timeline {
	border-top: 3px solid white;
	top: -90px;
	position: absolute;
	width: 100%;
}
.toggle-timeline ul.toggle-switch {
	padding: 0;
	margin: 0;
}
#block-admissions-timeline .toggle-timeline ul.toggle-switch li {
	width: auto;
	margin: 0 30px 0 0;
	float: left;
	padding: 7px 0 0 0;
	font-family: "proxima-nova",Helvetica,Arial,sans-serif;
}
#block-admissions-timeline .toggle-timeline .container:after {
	content: '';
	display: block;
	width: 27.5%;
	height: 27px;
	background: #fff;
	z-index: 1;
	top: 0;
	right: 0;
	position: absolute;
}
#block-admissions-timeline .toggle-timeline ul.heading-key {
	width: 225px;
	height: 27px;
	padding-left: 15px;
	float: right;
	background-color: #fff;
	position: relative;
	z-index: 25;
	&:before {
		position: absolute;
		width: 20px; 
		height: 27px; 
		background: url("../images/ui/ui-left-angle.png") no-repeat 0 0px; 
		content: ""; 
		display: block;
		top: 0;
		left: -20px;
	}
	li {
		text-transform: uppercase;
		font-size: em(10);
		letter-spacing: 1px;
		font-weight: 700;
		display: inline-block;
		padding-left: 18px;
		margin: 0 0 0 0;
		&:first-child {margin: 0 20px 0 0;}
		&.blue-dot:before, &.red-dot:before {
			content: "";
			width: 10px;
			height: 10px;
			left: 0;
			background: url("../images/ui/sprite-timeline.png") no-repeat 0 -90px;
			display: block;
			position: absolute;
			cursor: pointer;
		}
		&.blue-dot:before { background-position: 0 -90px; }
		&.red-dot:before { background-position: 0 -78px; }
	}
}
#block-admissions-timeline .toggle-timeline ul.toggle-switch li.active {
	background: url("../images/ui/sprite-timeline.png") no-repeat 60% -44px;
}
#block-admissions-timeline .toggle-timeline ul.toggle-switch li a {
	padding: 0 0 0 13px;
	background: url("../images/ui/sprite-timeline.png") no-repeat 0 0;
	color: $darkBlue;
	text-transform: uppercase;
	font-size: em(10);
	letter-spacing: 1px;
}
#block-admissions-timeline .toggle-timeline ul.toggle-switch li a.timeline {
	background-position:  0 -234px;;
	&.active { background-position:  0 -3px; }
}
#block-admissions-timeline .toggle-timeline ul.toggle-switch li a.list {
	background-position:  0 -23px;
	&.active { background-position: 0 -200px; }
}

#block-admissions-timeline .list-container {
	display: none;
	padding: 0 0 20px 0;
	overflow: hidden;
	li p.description { display: none;}
}
#block-admissions-timeline .timeline-container {
	margin: -50px 0 0 0;
	padding: 0 0 0 1px;
	width: 100%;
	height: 335px;
	overflow: hidden;
}
#block-admissions-timeline .timeline-container .timeline > ul > li {
	width: 6%;
	float: left;
	height: 336px;
	margin: 0;
	padding: 0;
	overflow: visible !important;
	/*border-right: 1px solid #c0eaf1;*/
}
#block-admissions-timeline .timeline-container .timeline > ul > li.active {
	width: 33.9%;
}
#block-admissions-timeline .timeline-container .timeline > ul > li .month-name {
	height: 52px;
	font: bold 11px/52px "proxima-nova",Helvetica,Arial,sans-serif;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	background: url("../images/bg/bg-timeline-month.png") repeat 0 0;
	border-right: 1px solid #c0eaf1;
	cursor: pointer;
	&:hover { @include transitions-background-color; background-color: #3a5b62;}
}
#block-admissions-timeline .timeline-container .timeline > ul > li .month-name .count {
	color: #001c25;
	height: 23px;
	width: 23px;
	border-radius: 50% 50% 50% 50%;
	background: #fff;
	line-height: 23px;
	text-align: center;
	margin: 0 0 0 9px;
	display: none;
	-webkit-transition: all 0.15s ease-out 0.1s;
        -moz-transition: all 0.15s ease-out 0.1s;
        -o-transition: all 0.15s ease-out 0.1s;
        transition: all 0.15s ease-out 0.1s;
}
#block-admissions-timeline .timeline-container .timeline > ul > li.active .month-name {
	padding: 0 0 0 24px;
	text-align: left;
	cursor: default;
	background-color: #001c25;
	-webkit-transition-property: width;
  	-moz-transition-property: width;
  	transition-property: width;
  	-webkit-transition-duration: 0.2s, 0.2s;
  	-moz-transition-duration: 0.2s, 0.2s;
  	transition-duration: 0.2s, 0.2s;
}
#block-admissions-timeline .timeline-container .timeline > ul > li.active .month-name .count {
	display: inline-block;
	 -webkit-transition-delay: 0s;
	    -moz-transition-delay: 0s;
	    -o-transition-delay: 0s;
	    transition-delay: 0s;
	-webkit-transition-duration: 0.3s;
	    -moz-transition-duration: 0.3s;
	    -o-transition-duration: 0.3s;
	    transition-duration: 0.3s;
}
#block-admissions-timeline .timeline-container .timeline > ul > li.animated .month-name {
	padding: 0 0 0 24px;
	text-align: left;
}

#block-admissions-timeline .timeline-container .timeline > ul > li .events-cont {
	height: 283px;
	border-right: 1px solid #c0eaf1;
	position: relative;
}
#block-admissions-timeline .timeline-container .timeline > ul > li .events-cont .event {
	position: absolute;
	bottom: 10px;
	cursor: pointer;
	z-index: 3;
	width: 10px;
	height: 10px;
	display: block;
	background-color: $darkBlue;
	@include nav-circle;
	&:hover {
		-o-transition-duration: 0.3s, 0.3s;
		-webkit-transition-duration: 0.3s, 0.3s;
  		-moz-transition-duration: 0.3s, 0.3s;
  		transition-duration: 0.3s, 0.3s;
		width: 13px;
		height: 13px;
		margin-left: -2px;
	}
}
#block-admissions-timeline .timeline-container .timeline > ul > li .events-cont .event.graduate {
	background-color: $darkRed;
}
#block-admissions-timeline .timeline-container .timeline > ul > li .events-cont .event.active {
	background: none;
	width: 30px;
	height: 30px;
	margin: 0 0 -8px -11px;
	bottom: 0;
	z-index: 1;
	-o-transition-duration: 0;
	-webkit-transition-duration: 0;
	-moz-transition-duration: 0;
	transition-duration: 0;
}
#block-admissions-timeline .timeline-container .timeline > ul > li .events-cont .event .active-dot {
	display: none;
	width: 30px;
	height: 30px;
	background: url("../images/ui/sprite-timeline.png") no-repeat 0 -128px;
	z-index: 4;
	position: relative;
}
#block-admissions-timeline .timeline-container .timeline > ul > li .events-cont .event.graduate.active .active-dot {
	background-position: 0 -100px;
}

#block-admissions-timeline .timeline-container .timeline > ul > li .events-cont .event.active .active-dot {
	display: block;
	@include transitions;
}
#block-admissions-timeline .events-cont .event .popup {
	width: 160px;
	height: 230px;
	position: absolute;
	bottom: 14px;
	left: 15px;
	border-left: 1px solid #003f52;
	display: none;
	padding: 0 0 0 10px;
	z-index: 2;
	@include transitions;
}
#block-admissions-timeline .events-cont .event.active .popup {
	display: block;
	cursor: default;
}
#block-admissions-timeline .events-cont .event.active .popup a.close {
	width: 23px;
	height: 23px;
	display: inline-block;
	background: url("../images/ui/sprite-timeline.png") no-repeat -4px -159px;
	text-indent: -9999px;
	margin: 0 7px 0 0;
}
#block-admissions-timeline .events-cont .event.active .popup h2 {
	font-size: em(20);
	line-height: 24px;
	margin-bottom: 0.1em;
}
#block-admissions-timeline .events-cont .event.active .popup p.submitted {
	font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
	font-style: italic;
	font-size: em(12);
	color: #456a73;
	padding: 0;
}
#block-admissions-timeline .events-cont .event.active .popup p.body {
	font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
	color: #001c25;
	font-size: em(12);
}
#block-admissions-timeline .events-cont .event.active .popup .divider {
	color: #a5bbbe;
}
#block-admissions-timeline .events-cont .event.active .popup a.more {
	color: #001c25;
	font: "proxima-nova",Helvetica,Arial,sans-serif;
	font-size: em(18);
	background: url("../images/ui/ui-arrows-sprites.png") no-repeat 98% -431px;
	padding:0 1.5em 0 0;
}
#block-admissions-timeline .events-cont .event.active .popup .border-bottom {
	border-bottom: 1px solid #003F52;
	bottom: 0;
	height: 1px;
	left: 0;
	position: absolute;
	width: 0;
}