#content-bottom {
  position: relative;
}


/* Basic Carousel */

#block-blog {
  display: none;
}

.no-js #block-events,
.no-js #block-blog {
  display: block;
}


/* Events Block */

.front #block-events {
  padding-bottom: 30px;
  @media (min-width: em(320)) {
    padding-bottom: 0;
  }
  @media (min-width: em(768)) {
    overflow: inherit;
    padding-bottom: 30px;
  }
}

#block-events {
  @media (min-width: em(320)) {
    visibility: hidden;
    position: relative;
    width: 100%;
    height: auto;
    background: none;
    margin: -25px 0 100px 0;
  }
  @media (min-width: em(768)) {
    overflow: hidden;
    visibility: hidden;
    margin: 0;
    padding-bottom: 65px;
    background: #5a9156 url("../images/bg/bg-events-section.jpg") no-repeat;
    @include background-cover;
  }
}

#block-events h5 {
  color: #fff;
}

#block-events ul.event-info li {
  color: #0e1619;
}

#block-events .grid {
  padding-left: 0;
}

#block-events .col-one-half {
  @media (min-width: em(580)) {
    width: 100%;
  }
  @media (min-width: em(768)) {
    width: 50%;
  }
}

#block-events .flex-slide,
#block-blog .flexCarousel {
  @media (min-width: em(320)) {
    max-width: 940px;
    min-width: 320px;
    width: 100%;
  }
  @media (min-width: em(768)) {
    width: 92%;
  }
  @media (min-width: em(1024)) {
    width: 93%;
  }
}

#block-events ul.slides {
  @media (min-width: em(320)) {
    padding: 21px 0 0 0;
  }
  @media (min-width: em(768)) {
    padding: 35px 0 0 0;
  }
}

#block-events ul.slides li.item {
  position: relative;
  height: auto;
  width: 100%;
  @media (min-width: em(1024)) {
    margin-right: em(25);
  }
  a:link,
  a:visited {
    color: $darkBlue;
  }
  a:hover,
  a:focus {
    color: #000;
    text-decoration: underline;
  }
}

#block-events .featured-content {
  padding-right: em(25);
  @media (min-width: em(320)) {
    display: none;
  }
  @media (min-width: em(768)) {
    display: block;
    width: 40%;
  }
  @media (min-width: em(1024)) {
    width: 50%;
  }
}

#block-events .featured-content .title a {
  &:link,
  &:visited {
    line-height: 1.250em;
    font-size: 1.250em;
    font-weight: 700;
    margin-bottom: 0.313em;
  }
}

#block-events .list-content {
  @media (min-width: em(320)) {
    width: 100%;
  }
  @media (min-width: em(768)) {
    width: 50%;
  }
  @media (min-width: em(1024)) {
    width: 49%;
  }
}

#block-events .list-content ul li {
  @media (min-width: em(320)) {
    clear: both;
    height: auto;
    padding: 20px 25px;
    margin-bottom: 2px;
    background: #f0f1f0;
    overflow: hidden;
    h4 {
      font-size: 1.125em;
    }
    h5.title {
      display: none;
    }
    .title a {
      font-size: em(16);
    }
  }
  @media (min-width: em(768)) {
    clear: none;
    padding: 0;
    margin-bottom: 0;
    background: none;
    overflow: visible;
    h4 {
      font-size: 0.938em;
    }
    &:first-child h5.title {
      display: block;
    }
    h5.title {
      display: none;
    }
    .title a {
      font-size: 0.875em;
    }
  }
}

#block-events .list-content ul li .contextual-links-wrapper ul li {
  background: none;
  padding: 0 0 0 0;
}

#block-events ul li ul.event-info li {
  padding: 0;
}

#block-events ul li ul.event-info li:after {
  @media (min-width: em(320)) {
    color: #dadada;
  }
  @media (min-width: em(768)) {
    color: #396836;
  }
}

#block-events .list-content p.description {
  @media (min-width: em(320)) {
    width: auto;
    padding: 0.625em 0 0 0;
  }
  @media (min-width: em(768)) {
    padding: 0.625em 0 1.250em 0;
  }
  @media (min-width: em(1024)) {
    width: 90%;
  }
}

#block-events .featured-content h5 {
  display: block;
  width: 100%;
}

#block-events h5 {
  padding: 1.250em 0;
  border-top: 3px solid #fff;
  @media (min-width: em(320)) {
    display: none;
  }
  @media (min-width: em(768)) {
    display: block;
  }
}

#block-events .flex-slide ul.flex-direction-nav {
  @media (min-width: em(320)) {
    top: 24.175em;
    right: 1.250em;
  }
  @media (min-width: em(768)) {
    top: 8em;
  }
}

#block-events .slide-control {
  position: absolute;
  top: -18px;
  right: 0;
  width: 318px;
  height: 321px;
  display: block;
  z-index: 51;
  background: url(../images/bg/bg-events-section-control.png) no-repeat;
  @media (min-width: em(320)) {
    width: 100%;
    height: 37px;
    background: url(../images/bg/bg-mobile-heading-sprites.png) no-repeat;
    background-position: -100px 0;
    display: block;
    z-index: 20;
    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 3px;
      background: url(../images/bg/bg-mobile-heading-sprites.png) repeat-x;
      background-position: -1% -70px;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
  }
  @media (min-width: em(768)) {
    width: 318px;
    height: 321px;
    top: -18px;
    background: url(../images/bg/bg-events-section-control.png) no-repeat;
    background-position: 0 0;
    z-index: 51;
    &:before {
      display: none;
    }
  }
}

#block-events .slide-control h1 {
  @media (min-width: em(320)) {
    color: #313131;
    text-transform: uppercase;
    width: 50%;
    font-size: 11px;
    text-align: left;
    float: left;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 1px;
    margin: 0px 0 0 25px;
    span {
      display: inline-block;
    }
  }
  @media (min-width: em(768)) {
    width: 70%;
    font-size: 2.813em;
    text-align: right;
    float: right;
    font-weight: 100;
    line-height: 1.04em;
    letter-spacing: none;
    margin: 52px 20px 0 0;
    span {
      display: none;
    }
  }
}

#block-events .slide-control a.button {
  border: 1px solid #5890a0;
  margin: 10px 20px 0 0;
  padding-right: 34px;
  float: right;
  @media (min-width: em(320)) {
    display: none;
  }
  @media (min-width: em(768)) {
    display: block;
  }
}

#block-events a.calendar {
  background: #ccd8cc url(../images/ui/ui-icons-sprites.png) no-repeat;
  background-position: 106% -197px;
  width: auto;
}

#block-events .slide-control a.button:hover,
#block-events .slide-control a.button:focus {
  background-color: #fff;
}


/* Blog Block */

.node-type-academic-program #block-blog,
.node-type-landing-page #block-blog {
  @media (min-width: em(320)) {
    overflow: hidden;
    border-top: none;
  }
  @media (min-width: em(768)) {
    border-top: 2px solid #fff;
  }
}

.front #block-blog {
  @media (min-width: em(768)) {
    border-top: 2px solid #e3e3e2;
  }
}

#block-blog {
  @media (min-width: em(320)) {
    position: relative;
    height: auto;
    margin-bottom: 100px;
  }
  @media (min-width: em(768)) {
    height: 325px;
    padding-bottom: 100px;
    background: #c5c5c5;
    @include background-cover;
    margin-bottom: 0;
  }
}

#block-blog .flexCarousel {
  @media (min-width: em(320)) {
    margin: 0 0 0 0;
    padding: 35px 0 0 0;
    height: auto;
  }
  @media (min-width: em(768)) {
    margin: 0 0 0 -20px;
    padding: 0 0 0 0;
  }
}

#block-blog ul.slides {
  @media (min-width: em(320)) {
    position: relative;
    padding: 4px 0 0 0;
    height: auto;
  }
  @media (min-width: em(768)) {
    padding: 52px 0 0 0;
  }
}

#block-blog ul.slides>li,
#block-blog ul.slides>li.item {
  @media (min-width: em(320)) {
    position: relative;
    cursor: pointer;
    clear: both;
    height: auto;
    margin-bottom: 2px;
    padding-bottom: 0;
    background: #f0f1f0;
    overflow: hidden;
  }
  @media (min-width: em(768)) {
    clear: none;
    margin-bottom: 0;
    padding-bottom: 10px;
    background: none;
    overflow: visible;
  }
}

#block-blog ul.slides li .image {
  @media (min-width: em(320)) {
    display: none;
    z-index: 20;
  }
  @media (min-width: em(768)) {
    display: block;
  }
}

#block-blog ul.slides li .item-content {
  @media (min-width: em(320)) {
    position: relative;
    z-index: 30;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0 20px 0;
    border-top: none;
    h3 {
      font-size: 1.125em;
    }
  }
  @media (min-width: em(768)) {
    width: 100%;
    padding: 20px 0 0 0;
    border-top: 3px solid #fff;
    h3 {
      font-size: 1.250em;
    }
    p {
      font-size: em(12);
    }
  }
}

#block-blog .tag-label {
  color: #484848;
  display: inline-block;
  padding-right: 0.625em;
  line-height: 1.500em;
  font-size: 0.688em;
  font-weight: 700;
  font-style: italic;
  @media (min-width: em(320)) {
    display: none;
  }
  @media (min-width: em(768)) {
    display: inline-block;
  }
}

#block-blog ul.field-tags {
  @media (min-width: em(320)) {
    display: none;
  }
  @media (min-width: em(768)) {
    display: inline-block;
  }
}

#block-blog ul.slides li .share-content {
  display: none;
}

#block-blog ul.slides li:hover .share-content {
  @media (min-width: em(320)) {
    display: none;
  }
  @media (min-width: em(768)) {
    display: block;
  }
}

#block-blog ul.slides li .share-content .tyler-share {
  display: inline-block;
  padding-left: 10px;
  margin: 0 0 0 0;
  li a {
    &.facebook {
      &:hover {
        background-position: -1px -131px;
      }
    }
    &.twitter {
      &:hover {
        background-position: -1px -452px;
      }
    }
  }
}

#block-blog .flexCarousel ul.flex-direction-nav {
  @media (min-width: em(320)) {
    top: 22.975em;
    right: 1.250em;
  }
  @media (min-width: em(768)) {
    top: 11.875em;
  }
}

#block-blog .slide-control {
  position: absolute;
  top: 0;
  right: 0;
  width: 350px;
  height: 350px;
  display: block;
  z-index: 50;
  background: url(../images/bg/bg-blog-section-control.png) no-repeat;
  @media (min-width: em(320)) {
    width: 100%;
    height: 37px;
    background: url(../images/bg/bg-mobile-heading-sprites.png) no-repeat;
    background-position: -100px 0;
    position: absolute;
    top: 0;
    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 3px;
      background: url(../images/bg/bg-mobile-heading-sprites.png) repeat-x;
      background-position: -1% -70px;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
  }
  @media (min-width: em(768)) {
    width: 350px;
    height: 350px;
    background: linear-gradient(to top right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, #bfbfbf 50%, #bfbfbf 100%);
    background-position: 0 0;
    &:before {
      display: none;
    }
  }
}

#block-blog .slide-control h1 {
  @media (min-width: em(320)) {
    text-transform: uppercase;
    width: 50%;
    font-size: 11px;
    text-align: left;
    float: left;
    color: #313131;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 1px;
    margin: 0px 0 0 25px;
  }
  @media (min-width: em(768)) {
    width: 70%;
    font-size: 2.813em;
    text-align: right;
    float: right;
    color: #313131;
    font-weight: 100;
    line-height: 1.04em;
    letter-spacing: none;
    margin: 52px 20px 0 0;
  }
}

#block-blog .slide-control a.button {
  border: 1px solid #313131;
  margin: 10px 20px 0 0;
  padding-right: 34px;
  float: right;
  @media (min-width: em(320)) {
    display: none;
  }
  @media (min-width: em(768)) {
    display: block;
  }
}

#block-blog a.blog {
  background: rgba(255,255,255,0) url(/sites/tyler/themes/tyler/images/ui/ui-icons-sprites.png) no-repeat;
  background-position: 110% -525px;
}

#block-blog .slide-control a.button:hover,
#block-blog .slide-control a.button:focus {
  background-color: #fff;
}

#block-blog ul.flex-direction-nav li a.flex-prev:hover {
  background-position: 40% 7px;
}

#block-blog ul.flex-direction-nav li a.flex-next:hover {
  background-position: 70% -409px;
}

#block-blog ul.flex-direction-nav li a:hover {
  background-color: $darkBlue;
}

#block-events .mobile-link {
  @media (min-width: em(320)) {
    background: #fff;
    position: absolute;
    bottom: -50px;
    width: 100%;
    height: 50px;
    display: block;
    a.calendar {
      width: 100%;
      display: block;
      line-height: 50px;
      margin: 0 25px;
      padding: 0 0 0 28px;
      background-color: transparent;
      background-position: 0 -184px;
    }
  }
  @media (min-width: em(768)) {
    display: none;
  }
}

#block-blog .mobile-link {
  @media (min-width: em(320)) {
    background: #fff;
    position: absolute;
    bottom: -50px;
    width: 100%;
    height: 50px;
    display: block;
    a.blog {
      width: 100%;
      display: block;
      line-height: 50px;
      margin: 0 25px;
      padding: 0 0 0 28px;
      background-color: transparent;
      background-position: 0 -511px;
    }
  }
  @media (min-width: em(768)) {
    display: none;
  }
}


/* Region Bottom Highlight */

#bottom-highlight {
  @media (min-width: em(320)) {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    background: #fff;
  }
  @media (min-width: em(768)) {
    min-height: 145px;
  }
  .container {
    @media (min-width: em(768)) {
      width: 100%;
    }
  }
}

.node-type-webform #bottom-highlight,
.node-type-basic #bottom-highlight {
  margin-top: 15px;
}


/* Contact Block */

.block-cont-ed-contact,
.block-temple-con-contact {
  @media (min-width: em(320)) {
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding-bottom: 20px;
    float: none;
    border-right: none;
  }
  @media (min-width: em(1024)) {
    width: 62.55%;
    height: 110px;
    margin: 0 0;
    float: left;
    border-right: 1px solid #e7e7e7;
  }
  h2 {
    margin: 0 0;
    padding: 1.875em 0 0.625em 0;
    color: $darkBlue;
    line-height: 1.14286em;
    font-size: 0.750em;
    letter-spacing: 0.125em;
    font-weight: 800;
    text-transform: uppercase;
    font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  }
  ul.contact-info li {
    @media (min-width: em(320)) {
      font-size: em(13);
    }
    @media (min-width: em(768)) {
      font-size: em(15);
    }
    display: inline-block;
    background: url(../images/ui/ui-share-sprites.png) no-repeat;
    background-position: 0 -25px;
    padding: 0 0 2px 24px;
    margin: 10px 0 0 0;
    height: 14px;
    line-height: 20px;
    &.phone {
      background-position: -1px -62px;
    }
    &.fax {
      background-position: -1px -190px;
    }
    &.email {
      background-position: -1px -696px;
      a:link,
      a:visited {
        color: $darkRed;
        font-size: 15px;
      }
      a:hover {
        color: $darkBlue;
      }
    }
    &:after {
      content: "/";
      color: $grey;
      width: 10px;
      margin: 0 10px 0 10px;
    }
    &.last,
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.block-cont-ed-contact {
  border-right: none;
}

.page-node-10646,
.page-node-11976,
.page-node-15236 {
  #block-program-degree {
    @media (min-width: em(768)) {
      max-width: 940px;
      margin: 0 auto;
    }
  }
}


/* Program Degree Block & Admission Timeline Block List*/

#block-program-degree {
  overflow: hidden;
}

#block-program-degree,
#block-admissions-timeline {
  @media (min-width: em(320)) {
    width: auto;
    height: auto;
    margin: 0 0 0 0;
    padding: 30px 0 20px 0;
  }
  @media (min-width: em(768)) {
    padding: 50px 0 72px 0;
  }
}

#block-program-degree .content,
#block-admissions-timeline .content {
  @media (min-width: em(320)) {
    margin-left: 0;
  }
  @media (min-width: em(768)) {
    margin-left: -20px;
  }
}

#block-program-degree h5,
#block-admissions-timeline h5 {
  color: #fff;
  width: 100%;
  padding: 1.25em 0;
  border-top: 3px solid #fff;
}

#block-program-degree ul li,
#block-admissions-timeline ul li {
  margin: em(20) 0 em(20) 0;
  position: relative;
}

#block-program-degree .grid,
#block-admissions-timeline .grid {
  @media (min-width: em(320)) {
    padding: 0 20px;
  }
  @media (min-width: em(768)) {
    padding: 0 0 0 1.250em;
  }
}

#block-program-degree .image img {
  @media (min-width: em(320)) {
    width: 100%;
  }
  @media (min-width: em(1024)) {
    width: 440px;
  }
}

#block-program-degree a.link {
  padding-left: em(10);
  margin-left: em(10);
  border-left: 1px solid #9bce83;
}

.callout-link {
  width: auto;
  height: 50px;
  padding: 0 20px 0 0;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  a {
    font-size: 14px;
    line-height: 3.625em;
    padding: 0 1.5em 0 1.25em;
    display: inline-block;
  }
  a.more {
    background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
    background-position: 100% -415px;
  }
  a.external-link {
    background-position: 100% -39px;
  }
}

#block-program-degree a.button {
  border: 1px solid #5e6d15;
  &:hover {
    background-color: #fff;
  }
}

.bg-lime {
  @media (min-width: em(320)) {
    background: #73a85e;
    moz-box-shadow: inset 0 0 20px #5b874b;
    -webkit-box-shadow: inset 0 0 20px #5b874b;
    box-shadow: inset 0 0 20px #5b874b;
  }
  @media (min-width: em(768)) {
    background: #73a85e url("../images/bg/bg-degree-green.jpg") no-repeat;
    @include background-cover;
  }
}

.bg-cream {
  @media (min-width: em(320)) {
    background: #fffbdd url("../images/bg/bg-degree-cream.jpg") no-repeat;
    @include background-cover;
    moz-box-shadow: inset 0 0 20px #6b8882;
    -webkit-box-shadow: inset 0 0 20px #6b8882;
    box-shadow: inset 0 0 20px #6b8882;
    background-size: 170%;
    background-position: 35% 0;
  }
  @media (min-width: em(768)) {
    @include background-cover;
    background-position: 0 0;
  }
}

.bg-cream h5 {
  @media (min-width: em(320)) {
    border-top: 3px solid $darkBlue;
    color: $darkBlue;
  }
  @media (min-width: em(768)) {
    border-top: 3px solid #fff;
    color: #fff;
  }
}


/* Alumni Spotlight Block */

#block-spotlight {
  height: auto;
  padding: 50px 0;
  margin: 0 0;
  color: #bbb6af;
  h1 {
    margin: 0px 0 -22px 0;
    color: $grey;
  }
  p {
    @media (min-width: em(320)) {
      color: #bbb6af;
      font-size: em(14);
    }
    @media (min-width: em(1024)) {
      font-size: em(15);
    }
  }
}

#block-spotlight .container {
  @media (min-width: em(320)) {
    width: 90%;
  }
}

#block-spotlight.bg-green h5 {
  color: #fff;
}

#block-spotlight.border {
  border-bottom: 0px solid white;
}

#block-spotlight .image {
  @media (min-width: em(320)) {
    width: 100%;
    position: inherit;
    margin: 0 0 0 0;
  }
  @media (min-width: em(768)) {
    width: 30.333%;
    margin: 0 0 0 -20px;
  }
}

#block-spotlight .bio-text {
  @media (min-width: em(320)) {
    width: 100%;
    padding-top: 25px;
    float: left;
    border-top: none;
  }
  @media (min-width: em(768)) {
    width: 69.666%;
    border-top: 3px solid $darkBlue;
  }
}

#block-spotlight .bio-text li {
  @media (min-width: em(320)) {
    float: left;
    width: 100%;
    padding-left: 0;
  }
  @media (min-width: em(1024)) {
    width: 46%;
    padding-left: em(20);
  }
}

#block-spotlight a.link {
  margin: 0 0 0 em(10);
  padding-left: em(10);
  border-left: 1px solid $darkBlue;
  color: #fff;
  &:hover,
  &:focus {
    color: $red;
  }
}


/* Spotlight Slidehow Block */

.page-event-calendar #block-spotlight-slide h1,
.page-events-list #block-spotlight-slide h1 {
  color: $grey;
}

body.node-type-landing-page .main-content {
  padding-bottom: 0;
}

body.node-type-landing-page.section-admission-process #page #block-spotlight-slide {
  padding-top: 50px;
  margin: 0 0 0 0;
}

body.node-type-landing-page #page #block-spotlight-slide {
  padding: 0px 0 50px 0;
  margin-top: -10px;
  @media (min-width: em(320)) {
    border: none;
  }
  @media (min-width: em(768)) {
    border-top: none;
    border-bottom: none;
    border-left: 20px solid #fff;
    border-right: 20px solid #fff;
  }
}

body.node-type-landing-page #block-spotlight-slide .container {
  @media (min-width: em(320)) {
    width: 90%;
  }
  @media (min-width: em(1024)) {
    width: 940px;
  }
}

#block-spotlight-slide {
  height: auto;
  padding: 50px 0 50px 0;
  margin: 0 0 0 0;
  h1 {
    margin: 0px 0 -22px 0;
  }
  .block-label {
    color: $darkBlue;
    margin-bottom: 5px;
  }
}

#block-spotlight-slide .container {
  @media (min-width: em(320)) {
    width: 100%;
  }
  @media (min-width: em(1024)) {
    width: 940px;
  }
}

#block-spotlight-slide .flex-caption {
  bottom: 0;
  left: 0;
  @media (min-width: em(768)) {
    position: relative;
    min-width: 100%;
    max-width: 100%;
    padding: 0 0 0 0;
  }
  @media (min-width: em(1024)) {
    position: absolute;
    min-width: 220px;
    max-width: 66%;
  }
}

#block-spotlight-slide .slide-control,
#instructor-slide .slide-control {
  @media (min-width: em(320)) {
    width: 180px;
    height: 175px;
    background-position: 83% -1357px;
  }
  @media (min-width: em(768)) {
    background-position: 98% -1357px;
  }
}

#block-spotlight-slide .flexslider .flex-direction-nav {
  @media (min-width: em(320)) {
    top: 35px;
    right: -15px;
  }
  @media (min-width: em(768)) {
    right: 0;
  }
  @media (min-width: em(1024)) {
    right: 4%;
  }
}

#block-spotlight-slide .flexslider img {
  padding-top: 22px;
}

#block-spotlight-slide .flexslider ul.flex-direction-nav li a:hover,
#instructor-slide .flexslider ul.flex-direction-nav li a:hover,
#block-spotlight-slide .flexslider ul.flex-direction-nav li a:focus,
#instructor-slide .flexslider ul.flex-direction-nav li a:focus {
  background-color: transparent;
}

#block-spotlight-slide .flexslider ul.flex-direction-nav li a.flex-prev,
#instructor-slide .flexslider ul.flex-direction-nav li a.flex-prev {
  background-position: 80% 7px;
}

#block-spotlight-slide .flexslider ul.flex-direction-nav li a.flex-next,
#instructor-slide .flexslider ul.flex-direction-nav li a.flex-next {
  background-position: 50% -409px;
}

#block-spotlight-slide .flexslider ul.flex-direction-nav li a.flex-prev:hover,
#instructor-slide .flexslider ul.flex-direction-nav li a.flex-prev:hover,
#block-spotlight-slide .flexslider ul.flex-direction-nav li a.flex-prev:focus,
#instructor-slide .flexslider ul.flex-direction-nav li a.flex-prev:focus {
  background-position: 80% -1782px;
}

#block-spotlight-slide .flexslider ul.flex-direction-nav li a.flex-next:hover,
#instructor-slide .flexslider ul.flex-direction-nav li a.flex-next:hover,
#block-spotlight-slide .flexslider ul.flex-direction-nav li a.flex-next:focus,
#instructor-slide .flexslider ul.flex-direction-nav li a.flex-next:focus {
  background-position: 50% -1865px;
}

#block-spotlight-slide .flex-control-nav,
#instructor-slide .flex-control-nav {
  @media (min-width: em(320)) {
    top: 40px;
  }
}

#block-spotlight-slide .field-body {
  @media (min-width: em(320)) {
    padding-top: 30px;
    border-top: 3px solid #fff;
    margin-left: 0;
  }
  @media (min-width: em(768)) {
    margin-left: -20px;
  }
  p {
    font-size: em(15);
  }
  a {
    padding: 0 25px 0 10px;
    background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
    background-position: 100% -432px;
    display: inline;
  }
}

#block-admissions-timeline h1 {
  @media (min-width: em(320)) {
    position: absolute;
    margin: -95px 0 0 20px;
  }
  @media (min-width: em(768)) {
    margin: -95px 0 0 0;
  }
}

#block-admissions-timeline .title {
  font-size: em(20);
}

#block-admissions-timeline .undergraduate a {
  &:link,
  &:visited {
    color: #063a54;
  }
}

#block-admissions-timeline .graduate a {
  &:link,
  &:visited {
    color: #ae102a;
  }
}

#block-admissions-timeline .undergraduate a,
#block-admissions-timeline .graduate a {
  &:hover,
  &:focus {
    color: $darkBlue;
  }
}

#block-admissions-timeline .event-info {
  margin-bottom: 0;
  &>li {
    color: #365862;
  }
}


/* Newsletter Block */

.block-newsletter {
  @media (min-width: em(320)) {
    width: 100%;
    float: none;
    border-top: 1px solid #e7e7e7;
    height: 40px;
    position: relative;
    padding-bottom: 15px;
    span {
      display: inline-block;
    }
  }
  @media (min-width: em(768)) {
    height: 100px;
  }
  @media (min-width: em(1024)) {
    height: auto;
    right: 0;
    top: 0;
    width: 40%;
    float: left;
    position: absolute;
    border-top: none;
    padding-bottom: 0;
    span {
      display: none;
    }
  }
}

.block-newsletter .subscribe-label {
  display: inline-block;
  font-size: 0.875em;
  text-transform: capitalize;
  @media (min-width: em(320)) {
    display: none;
  }
  @media (min-width: em(768)) {
    display: inline-block;
    margin: 18px 0 0 40px;
  }
  @media (min-width: em(1024)) {
    margin: 18px 0 0 20px;
  }
}

.block-newsletter form .form-type-textfield:before {
  background: url("../images/bg/bg-top-menu-sprites.png") no-repeat scroll 0 -120px transparent;
  content: "";
  display: block;
  height: 50px;
  margin-left: -52px;
  position: absolute;
  width: 55px;
}

.block-newsletter form .form-type-textfield {
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  width: 55%;
  @media (min-width: em(320)) {
    width: 86%;
  }
  @media (min-width: em(768)) {
    width: 80%;
  }
  @media (min-width: em(1024)) {
    width: 75%;
  }
}

.block-newsletter form input.form-text {
  padding: 5px 25% 4px 10px;
  width: 75%;
}

.block-newsletter input[type="submit"],
.block-newsletter form input.form-submit {
  right: 20px;
}

.block-newsletter div.form-item {
  margin: 0;
}

.block-newsletter form label {
  color: #FFFFFF;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-size: 0.75em;
  font-weight: 800;
  height: 40px;
  left: 10px;
  letter-spacing: 0.125em;
  line-height: 40px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
}

.block-newsletter form input.form-text {
  background-color: #001C25;
  border: medium none;
  color: #FFFFFF;
  font-size: 13px;
  font-style: italic;
  height: 31px;
  line-height: 31px;
  padding: 5px 40px 4px 10px;
  width: 100%;
}

.block-newsletter form input.form-submit {
  background: url("../images/ui/ui-arrows-sprites.png") no-repeat scroll -1px -1051px transparent;
  border: 0 none;
  cursor: pointer;
  display: block;
  height: 19px;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 10px;
  text-indent: -9999px;
  top: 10px;
  width: 19px;
}
