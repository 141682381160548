.no-js .front .masonry-content,
.no-js #block-program-masonry .masonry-content,
.no-js #audiencePrevNext li .text-wrap {
  display: block;
  visibility: visible;
}

.iframe-block {
  &.iframe-masonry {
    padding: 0 5%;
    box-sizing: border-box;
    max-width: 940px;
    @media screen and (min-width: 1024px){
      padding: 0;
    }
    iframe {
      width: 100%;
      text-align: center;
    }
    
    margin: auto;
  }
  p {
    padding: 0;
    text-align: center;
  }
  iframe {
    width: 100vw;
    @media screen and (min-width: 768px){
      height: calc(100vh - 152px);
    }
    @media screen and (min-width: 1024px){
      height: calc(100vh - 165px);
      padding: 0;
    }
  }
}
// .front .masonry-content {
//   visibility: hidden;
// }
//
// #block-program-masonry .masonry-content {
//   display: none;
// }

.masonry-content {
  @media (min-width: em(320)) {
    width: 100%;
    margin: 0 auto;
  }
}

.front .container {
  margin: 0 auto;
  max-width: 1200px;

  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 92%;
  }

  @media (min-width: em(1024)) {
    width: 940px;
  }
}

.front #audience .container {
  @media (min-width: em(320)) {
    width: 100%;
  }

  @media (min-width: em(768)) {
    width: 93.5%;
  }

  @media (min-width: em(1024)) {
    width: 900px;
  }

  @media (min-width: em(1075)) {
    width: 940px;
  }

  @media (min-width: em(1600)) {
    width: 93%;
  }
}

.m-block {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;

  &.col4 {
    @media (min-width: em(320)) {
      width: 100%;
      background: white;
    }
  }

  &.col3 {
    @media (min-width: em(320)) {
      position: relative;
      width: 100%;
    }

    @media (min-width: em(1024)) {
      width: 74.4%;
    }

    @media (min-width: em(1600)) {
      width: 700px;
    }
  }

  &.col2 {
    @media (min-width: em(320)) {
      width: 100%;
      overflow: hidden;
    }

    @media (min-width: em(480)) {
      margin-left: 5%;
      width: 90%;
    }

    @media (min-width: em(768)) {
      margin-left: 0;
      width: 65.88%;
    }

    @media (min-width: em(1024)) {
      width: 48.8%;
    }

    @media (min-width: em(1600)) {
      width: 460px;
    }
  }

  &.col1 {
    @media (min-width: em(320)) {
      overflow: hidden;
      margin-left: 5%;
      width: 90%;
    }

    @media (min-width: em(768)) {
      width: 31.55%;
      margin-left: 0;
    }

    @media (min-width: em(1024)) {
      width: 23.43%;
    }

    @media (min-width: em(1600)) {
      width: 220px;
    }
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    -ms-interpolation-mode: bicubic;
  }
}

#audience {
  width: 100%;
  min-height: 500px;
  overflow: hidden;
  margin: 0 auto;
  background: url("../images/bg/bg-page-top-shadow.png") repeat-x top;
}

#audience .audienceFloor {
  @media (min-width: em(320)) {
    padding: 0 0 0 0;
    height: 100% !important;
    height: auto;
  }

  @media (min-width: em(768)) {
    padding: 3.000em 0 0 0;
  }
}

#audience .centerSlide {
  .container {
    float: none;
    margin: 0 auto;
    padding: 0 0 0 0;
  }

  @media (min-width: em(320)) {
    overflow: hidden;
    border-right: none;
    border-left: none;
  }

  @media (min-width: em(768)) {
    background: url(../images/bg/bg-home-diagonal-1.png) no-repeat;
    background-size: 100% auto;
    border-right: 1px solid #dfdede;
    border-left: 1px solid #ececec;
    -webkit-box-shadow: 0 1px 16px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 16px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 16px 0px rgba(0, 0, 0, 0.3);
  }
}

#audience .leftSlide {
  .container {
    float: right;
    margin: 0 0 0 0;
    padding: 0 em(25);
  }

  @media (min-width: em(1600)) {
    border-right: 1px solid #fff;
  }
}

#audience .rightSlide {
  .container {
    float: left;
    margin: 0 0 0 0;
    padding: 0 em(25);
  }

  @media (min-width: em(1600)) {
    border-left: 1px solid #fff;
  }
}

.audienceOverlay {
  z-index: 25;
  float: left;
  display: none;
  background: url(../images/bg/bg-transparent-green.png) repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -50px;
  zoom: 1;

  @media (min-width: em(1600)) {
    background: url(../images/bg/bg-transparent-white.png) repeat;
  }
}

#audience h2 {
  color: #fff;
}

.left-fade,
.right-fade {
  width: 4%;
  height: 100%;
  position: absolute;
  display: none;
  background: rgba(0, 28, 37, 0.5);
  opacity: 1;

  @media (min-width: em(1600)) {
    width: 2.5%;
  }
}

#audiencePrevNext {
  z-index: 50;
  margin: 0 0 340px 0;
  position: absolute;
  width: 100%;
  text-transform: uppercase;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.125em;
  text-align: center;

  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(768)) {
    display: block;
  }
}

#audiencePrevNext li {
  width: 4%;
  display: block;
  cursor: pointer;

  @media (min-width: em(1600)) {
    width: 2.5%;
  }
}

#audiencePrevNext li .text-wrap {
  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(768)) {
    background-color: #fff;
    overflow: hidden;
    cursor: pointer;
    margin: 7.313em 0 0 0;
    height: 50px;
  }

  @media (min-width: em(1024)) {
    margin: 5.313em 0 0 0;
  }
}

.no-js #audiencePrevNext li .text-wrap {
  display: block;
  visibility: visible;
}

#audiencePrevNext li.right {
  position: absolute;

  @media (min-width: em(768)) {
    right: -2px;
  }

  @media (min-width: em(1600)) {
    right: 5.4%;
  }
}

#audiencePrevNext li.left {
  position: absolute;

  @media (min-width: em(768)) {
    left: 0px;
  }

  @media (min-width: em(1600)) {
    left: 4.5%;
  }
}

#audiencePrevNext a.audienceLinkNext {
  width: 100%;
  height: 50px;
  margin: 0 auto;
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: 75% -579px;
  display: inline-block;
  cursor: pointer;
  @include transitions-background-color;

  @media (min-width: em(768)) {
    background-position: 5px -579px;
  }

  @media (min-width: em(1024)) {
    background-position: 75% -579px;
  }
}

#audiencePrevNext a.audienceLinkPrev {
  width: 100%;
  height: 50px;
  margin: 0 auto;
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: 55% -492px;
  display: inline-block;
  cursor: pointer;
  @include transitions-background-color;
}

#audiencePrevNext #textNextNav {
  font-size: em(15);
  margin: 40px 0 0 50%;
  width: 200px;
  height: 20px;
  line-height: 20px;
  @include rotate-90deg;
}

#audiencePrevNext #textPrevNav {
  font-size: em(15);
  margin: 240px 0 0 50%;
  width: 200px;
  height: 20px;
  line-height: 20px;
  @include rotate-270deg;
}

#audiencePrevNext a.audienceLinkPrev.prospective,
#audiencePrevNext a.audienceLinkNext.prospective {
  background-color: $green;
  color: $darkGreen;

  &:focus {
    background-color: $darkGreen;
  }
}

#audiencePrevNext a.audienceLinkPrev.current,
#audiencePrevNext a.audienceLinkNext.current {
  background-color: $cyan;
  color: $darkCyan;

  &:focus {
    background-color: $darkCyan;
  }
}

#audiencePrevNext a.audienceLinkPrev.alumni,
#audiencePrevNext a.audienceLinkNext.alumni {
  background-color: $orange;
  color: $darkOrange;

  &:focus {
    background-color: $darkOrange;
  }
}

.prospective {
  .container {
    padding-top: 3rem;
  }
}

.prospective span.label {
  border-color: transparent transparent $green transparent;

  &:hover,
  a:focus {
    border-color: transparent transparent $darkGreen transparent;
  }
}

.current span.label {
  border-color: transparent transparent $cyan transparent;

  &:hover,
  a:focus {
    border-color: transparent transparent $darkCyan transparent;
  }
}

.alumni span.label {
  border-color: transparent transparent $orange transparent;

  &:hover,
  a:focus {
    border-color: transparent transparent $darkOrange transparent;
  }
}

#audience .col1 a.button:hover,
#audience .col1 a.button:focus,
#audience .col2 a.button:hover,
#audience .col2 a.button:focus {
  color: $red;
}

#audience .col2 {
  position: relative;
  overflow: hidden;
}

.col3-slide .flex-caption {
  bottom: 0;
  left: 0;
}

.col2 .slides {
  min-height: 340px;
}

.col2-slide .flex-caption {
  z-index: 60;
  max-width: 150px;
  height: auto;
  min-height: auto;
  top: 24%;
  right: 25px;
  background: transparent;

  @media (min-width: em(320)) {
    background: #fff;
    height: 3.125em;
    min-height: 3.125em;
    max-width: 100%;
    left: 0;
  }

  @media (min-width: em(768)) {
    position: relative;
  }

  @media (min-width: em(1024)) {
    position: absolute;
    background: transparent;
    max-width: 150px;
    min-height: auto;
    right: 25px;
    left: inherit;
  }
}

.col2 .slides a.button.more {
  @media (min-width: em(320)) {
    float: left;
    background-color: transparent;
    background-position: 100% -415px;
    border: none;
    font-size: em(14);
    line-height: 3.625em;
    padding: 0 1.5em 0 1.25em;
    display: inline-block;
  }

  @media (min-width: em(1024)) {
    z-index: 100;
    float: right;
    background-color: rgba(138, 43, 35, 0.8);
    background-position: 98% -429px;
    border: 1px solid $darkBlue;
    line-height: 22px;
    padding-right: 25px;
  }
}

.col2 .slides a.button.more {
  @media (min-width: em(320)) {
    color: $darkBlue;

    &:hover {
      color: $red;
    }
  }

  @media (min-width: em(1024)) {
    color: #fff;

    &:hover {
      color: $darkBlue;
    }
  }
}

.col2 .slides a.button.more:hover,
.col2 .slides a.button.more:focus,
#audience .prospective .col2 .slides a.button.more:hover,
#audience .current .col2 .slides a.button.more:hover,
#audience .alumni .col2 .slides a.button.more:hover,
#audience .prospective .col2 .slides a.button.more:focus,
#audience .current .col2 .slides a.button.more:focus,
#audience .alumni .col2 .slides a.button.more:focus {
  background-color: rgba(255, 255, 255, 1);
}

#audience .prospective .col2 .slides a.button.more {
  @media (min-width: em(320)) {
    background-color: transparent;
    border: none;
  }

  @media (min-width: em(1024)) {
    z-index: 100;
    background-color: rgba(191, 212, 81, 0.6);
    border: 1px solid $green;
  }
}

#audience .current .col2 .slides a.button.more {
  @media (min-width: em(320)) {
    background-color: transparent;
    border: none;
  }

  @media (min-width: em(1024)) {
    z-index: 100;
    background-color: rgba(249, 249, 248, 0.4);
    border: 1px solid $cyan;
  }
}

#audience .alumni .col2 .slides a.button.more {
  @media (min-width: em(320)) {
    background-color: transparent;
    border: none;
  }

  @media (min-width: em(1024)) {
    z-index: 100;
    background-color: rgba(252, 141, 74, 0.6);
    border: 1px solid $orange;
  }
}

.col2 ul.flex-direction-nav {
  @media (min-width: em(320)) {
    top: 33%;
    right: 23px;
  }

  @media (min-width: em(768)) {
    top: 23%;
  }

  @media (min-width: em(1024)) {
    top: 36%;
    right: 7%;
  }
}

/* Audience Callout Block */

#audience .block-hero-callout {
  @media (min-width: em(320)) {
    max-height: 395px;
    height: 240px;
    overflow: hidden;
    width: 100%;
    padding-left: 0;

    img {
      width: 100%;
    }
  }

  @media (min-width: em(480)) {
    height: auto;

    img {
      width: 100% !important;
    }
  }

  @media (min-width: em(1024)) {
    width: 72.444%;
    //padding-left: 1.155em;
  }

  @media (min-width: em(1600)) {
    width: 700px;
  }
}

#audience .block-hero-callout .caption {
  width: 100%;

  &.hero-callout-title-left {
    left: 0;
  }

  &.hero-callout-title-right {
    right: 0;
  }
}

@media (min-width: 20em) {
  #audience .block-hero-callout .caption {
    right: 0;
  }

  #audience .block-hero-callout .caption p {
    display: none;
  }
}

@media (min-width: 48em) {
  #audience .block-hero-callout .caption {
    left: 0;
  }

  #audience .block-hero-callout .caption p {
    display: block;
  }
}

@media (min-width: 320px) {
  #audience .block-hero-callout {
    height: 250px;
  }

  #audience .block-hero-callout .caption {
    padding: 10px 0;
  }

  #audience .block-hero-callout .caption h2 {
    font-size: 1.125em;
    padding-left: 20px;
  }

  #audience .block-hero-callout .caption a.button {
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

@media (min-width: 37.5em) {
  #audience .block-hero-callout {
    height: 300px;
  }

  #audience .block-hero-callout .caption {
    width: 180px;
    padding: 20px;
    top: 46%;
    bottom: inherit;
  }

  #audience .block-hero-callout .caption h2 {
    font-size: 1.125em;
    padding-left: 0;
  }

  #audience .block-hero-callout .caption a.button {
    margin-left: 0;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  #audience .block-hero-callout {
    height: 350px;
  }

  #audience .block-hero-callout .caption {
    top: 56%;
  }
}

#audience .prospective .block-hero-callout .caption {
  background-color: $green;
}

#audience .current .block-hero-callout .caption {
  background-color: $cyan;
}

#audience .alumni .block-hero-callout .caption {
  background-color: $orange;
}

#audience .prospective a.button {
  border: 1px solid #728124;
}

#audience .current a.button {
  border: 1px solid #167c70;
}

#audience .alumni a.button {
  border: 1px solid #855510;
}

/* Section Block Title */

.block-audience-title {
  float: left;
}

.block-audience-title h1 {
  text-indent: -9999px;
  height: 406px;
  line-height: 0;

  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(768)) {
    display: block;
    height: 390px;
  }

  @media (min-width: em(1024)) {
    height: 406px;
  }
}

.block-audience-title h1.title-prospective {
  background: url(../images/bg/bg-prospective-title-1024.png) no-repeat;
  background-position: top right;
}

.block-audience-title h1.title-current {
  background: url(../images/bg/bg-current-title-1024.png) no-repeat;
  background-position: top right;
}

.block-audience-title h1.title-alumni {
  background: url(../images/bg/bg-alumni-title-1024.png) no-repeat;
  background-position: top right;
}

.block-audience-title h1.title-prospective,
.block-audience-title h1.title-current,
.block-audience-title h1.title-alumni {
  @media (min-width: em(768)) {
    background-size: 208px 100%;
    background-position: top center;
  }

  @media (min-width: em(1024)) {
    background-size: 212px 100%;
    background-position: top center;
  }

  @media (min-width: em(1075)) {
    background-size: inherit;
    background-position: top right;
  }
}

.block-audience-title h1.title-prospective,
.block-audience-title h1.title-current,
.block-audience-title h1.title-alumni {
  @media (min-width: em(1024)) {
    background-position: top center;
  }

  @media (min-width: em(1075)) {
    background-position: top right;
  }
}

#audience .col2 a.button {
  color: $darkBlue;
}

/* Block Image */

.block-img {
  max-width: 100% !important;
  height: auto !important;
  display: inline-block;
  position: relative;
  overflow: hidden;

  @media (min-width: em(320)) {
    max-height: 200px;
  }

  @media (min-width: em(768)) {
    max-height: 100% !important;
  }
}

.block-img .caption {
  width: 100%;
  height: 100%;
  padding: 0;
  background: url("../images/bg/bg-img-top-shadow.png") repeat-x;
  background-color: transparent none;
  position: absolute;
  top: 0;

  a.imagelink {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
}

.block-img h2 {
  top: 0;
  position: absolute;
  z-index: 4;
  margin-left: 8%;
  margin-top: 10%;
  width: 80%;
  text-align: left;
}

.block-img a.imagelink .overlay,
.block-img a.imagelink .overlay.focus {
  -webkit-transition: background 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: background 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transition: background 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: background 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition: background 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.block-img a.imagelink:hover .overlay,
.block-img a.imagelink .overlay.focus {
  background: rgba(0, 0, 0, 0.7);
}

/* Block Half Split */

#audience .half-split {
  @media (min-width: em(320)) {
    background: #dddddd url(../images/bg/bg-pattern.png) repeat;
  }

  @media (min-width: em(768)) {
    background: none;
  }
}

.half-split {
  @media (min-width: em(320)) {
    position: relative;
    height: auto;
  }

  @media (min-width: em(768)) {
    height: 360px;
  }
}

.half-split img {
  width: 95%;
  padding-top: 4.5%;
  padding-left: 5%;
}

.half-split .content {
  @media (min-width: em(320)) {
    width: 48%;
    position: absolute;
    right: 15px;
    bottom: 0;
    display: block;
    background: #fff;
    height: 40%;

    h3 {
      font-size: 1.125em;
    }

    h2 {
      font-size: em(18);
      margin-top: 15px;
    }
  }

  @media (min-width: em(480)) {
    height: 50%;
    width: 42%;

    h2 {
      font-size: em(20);
      margin-top: 0;
    }
  }

  @media (min-width: em(1024)) {
    width: 40%;
  }
}

.half-split .content p,
.half-split .content .field-action-link {
  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(480)) {
    display: block;
  }
}

.half-split .content a.link.more {
  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(480)) {
    display: inline-block;
  }
}

#audience .half-split .content h2,
#block-program-masonry .half-split .content h2 {
  color: #001c25;
}

#audience .prospective .half-split .col2-bg {
  display: block;
  background: url(../images/bg/bg-half-split-prospective.png) no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-size: 100% 100%;
}

#audience .current .half-split .col2-bg {
  display: block;
  background: url(../images/bg/bg-half-split-current.png) no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-size: 100% 100%;
}

#audience .alumni .half-split .col2-bg {
  display: block;
  background: url(../images/bg/bg-half-split-alumni.png) no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-size: 100% 100%;
}

/* Block Calendar */

.block-calendar {
  display: block;
  min-height: 200px;
}

/* Block Events/Blog */

.block-event-blog {
  background: #fff;
  position: relative;

  h5 {
    padding: 1.25em 0 1.25em 20px;
    border-top: 3px solid $red;
    color: $red;
  }

  .content {
    padding: 0px 20px 35px 20px;
  }

  .image {
    max-height: 215px;
    overflow: hidden;
    margin: 0 0 0 0;
  }

  img {
    @media (min-width: em(320)) {
      display: none;
    }

    @media (min-width: em(768)) {
      display: block;
    }
  }
}

.block-event-blog h2 {
  line-height: 1.250em;
  font-weight: 700;
  margin-bottom: 0.313em;
  color: #001c25;
  font-size: em(17);

  @media (min-width: em(768)) {
    font-size: em(20);
  }
}

.block-event-blog .blog-tag,
.block-event-blog .event-tag {
  padding: 1.25em 0 1.25em 20px;
  border-top: 3px solid $red;
  color: $red;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  line-height: 1.14286em;
  font-size: 0.750em;
  letter-spacing: 0.125em;
  color: $darkRed;
  font-weight: 800;
  text-transform: uppercase;
}

.prospective .block-event-blog {

  .blog-tag,
  .event-tag {
    border-top: 3px solid $green;
    color: $darkGreen;
  }
}

.current .block-event-blog {

  .blog-tag,
  .event-tag {
    border-top: 3px solid $cyan;
    color: $darkCyan;
  }
}

.alumni .block-event-blog {

  .blog-tag,
  .event-tag {
    border-top: 3px solid $orange;
    color: $darkOrange;
  }
}

.block-event-blog .blog-date,
.block-event-blog .event-date {
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  line-height: 1.25em;
  font-size: 0.688em;
  font-weight: 700;
  font-style: italic;
  color: #c23022;
}

/* Block Text Callout */

.block-text-callout {
  background-color: $deepGrey;
  display: block;

  .content {
    padding: 20px 20px;
  }

  h2 {
    margin-bottom: 15px;
  }

  p {
    padding: 0 0 0.625em 0;
  }
}

.prospective .block-text-callout {
  background-color: $green;
}

.current .block-text-callout {
  background-color: $cyan;
}

.alumni .block-text-callout {
  background-color: $orange;
}

/* Block Callout Links */

#audience .block-quick-links,
#block-program-masonry .block-quick-links {
  background-color: $darkBlue;

  @media (min-width: em(320)) {
    min-height: inherit;
    height: auto;

    h2 {
      margin-bottom: 0;
    }

    .field-body {
      display: none;
      height: auto;
      opacity: 0;
    }
  }

  @media (min-width: em(768)) {
    margin-left: 0;
    min-height: auto;
    height: auto;

    h2 {
      margin-bottom: 15px;
    }

    .field-body {
      display: block;
      opacity: 1;
    }
  }

  .content ul li {
    color: #fff;
    font-size: em(15);
    font-weight: 700;

    a {

      &:link,
      &:visited {
        font-size: 0.965em;
        color: #fff;
      }

      &:hover,
      &:focus,
      &:active {
        color: $lightRed;
      }
    }
  }

  ul.expanded li {
    a {

      &:link,
      &:visited {
        color: #bbc0c0;
        font-weight: 400;
      }

      &:hover,
      &:focus,
      &:active {
        color: $lightRed;
      }
    }
  }
}

#audience .node-157.block-quick-links {
  @media (min-width: em(768)) {
    min-height: 390px;
  }

  @media (min-width: em(1024)) {
    min-height: auto;
  }
}

#mobile-quicklink-toggle {
  @media (min-width: em(320)) {
    position: relative;
    padding: 13px;
    margin: 15px 0 0 0;
    cursor: pointer;
    height: auto;
    background: #fff url(../images/ui/ui-arrows-sprites.png) no-repeat;
    background-position: 96% -730px;

    a {
      color: $grey;
    }

    &.active {
      background-position: 96% -820px;

      a {
        color: $red;
      }
    }
  }

  @media (min-width: em(768)) {
    display: none;
  }
}

#audience .prospective .block-quick-links h2 {
  color: $green;
}

#audience .current .block-quick-links h2 {
  color: $cyan;
}

#audience .alumni .block-quick-links h2 {
  color: $orange;
}

/* Block Video */

.block-video {
  position: relative;
}

.block-video .overlay-content {
  width: 100%;
  height: 100%;
  padding: 0;
  background: url("../images/bg/bg-img-top-shadow.png") repeat-x;
  background-color: transparent none;
  position: absolute;
  top: 0;

  h2 {
    text-align: right;
    margin-top: 20px;
    margin-right: 20px;
  }
}

a.play-btn {
  position: absolute;
  overflow: hidden;
  text-indent: -9999px;
  display: block;
  width: 95px;
  height: 96px;
  left: 40%;
  top: 35%;
  background: url("../images/ui/ui-video-sprites.png") no-repeat;
  background-position: 0 0;
}

a.play-btn:hover {
  background-position: 0 -152px;
}

.block-video .author-caption {
  max-width: 459px;
  height: auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  bottom: 0;
  left: 0;

  h3 {
    margin-bottom: 0;
    color: $red;
  }
}

.block-video .title {
  font-size: em(14);
}

/* Jump Section */

#jump-section {
  position: absolute;
  top: 70px;
  left: 7%;
  z-index: 50;

  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(1125)) {
    display: block;
  }

  @media (min-width: em(1600)) {
    left: 9%;
  }
}

#jump-section.hide {
  display: none;

  @media (min-width: em(320)) {
    display: none;
  }
}

#jump-section.show {
  display: block;

  @media (min-width: em(320)) {
    display: none;
  }

  @media (min-width: em(1125)) {
    display: block;
  }
}

#jump-section>li {
  margin-bottom: 15px;
  position: relative;
}

#jump-section .tool-tip {
  width: 100px;
  position: absolute;
  background: $red;
  color: #fff;
  top: 0;
  left: 0px;
  margin-left: -25px;
  font-size: em(12);
  opacity: 0;
}

#jump-section .tool-tip.active {
  opacity: 1;
  margin-left: 28px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

#jump-section>li a {
  background: url("../images/ui/ui-icons-sprites.png") no-repeat;
  width: 19px;
  height: 19px;
  cursor: pointer;
  text-indent: -9999px;
  display: block;

  &.sec-masonry {
    background-position: -1px -871px;

    &:hover {
      background-position: -1px -937px;
    }
  }

  &.sec-events {
    background-position: -1px -67px;

    &:hover {
      background-position: -1px -199px;
    }
  }

  &.sec-blog {
    background-position: -1px -399px;

    &:hover {
      background-position: -1px -529px;
    }
  }
}

#jump-section>li.active a {
  &.sec-masonry {
    background-position: -1px -937px;
  }

  &.sec-events {
    background-position: -1px -199px;
  }

  &.sec-blog {
    background-position: -1px -529px;
  }
}

/* Program-Masonry */

.node-feature-block h2 {
  color: #fff;
}

#block-program-masonry {
  h2 {
    color: #fff;
  }

  p {
    font-size: 0.75em;
  }
}

#block-program-masonry.border {
  border-top: 4px solid #fff;
  border-bottom: none;
}

#block-program-masonry .wrapper {
  width: 93%;
  margin: 0 auto;
  max-width: 940px;

  @media (min-width: em(320)) {
    width: 100%;
    margin: 0 auto;
    max-width: 940px;
  }

  @media (min-width: em(768)) {
    width: 92%;
  }
}

#block-program-masonry .masonry-content {
  margin-top: 50px;
  padding-bottom: 80px;
}

#block-program-masonry .masonry-title {
  width: 100%;
  position: relative;
}

#block-program-masonry .masonry-title:before {
  content: '';
  display: block;
  width: 40%;
  height: 37px;
  background: #fff;
  top: 0;
  left: 0;
  position: absolute;
}

#block-program-masonry .masonry-title .block-label {
  @media (min-width: em(320)) {
    color: $darkBlue;
    font-weight: 600;
    font-size: em(10);
    line-height: 37px;
    height: 37px;
    background-color: #fff;
    position: relative;
    width: 80%;
    margin: 0 auto;
  }

  @media (min-width: em(768)) {
    width: 75%;
    margin: 0 0;
  }

  @media (min-width: em(1024)) {
    width: 45%;
  }
}

#block-program-masonry .masonry-title .block-label:after {
  content: '';
  display: block;
  width: 25px;
  height: 37px;
  background: url("../images/bg/bg-bar-diagonal.png") no-repeat;
  top: 0;
  right: -25px;
  position: absolute;
}

#block-program-masonry .block-quick-links h2 {
  color: $red;
}

.masonry-content a.button {
  border: 1px solid $darkBlue;
}

.node-feature-block.half-split .col2-bg {
  display: block;
  background: url(../images/bg/bg-half-split-red.png) no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-size: 100% 100%;
}

#block-program-masonry a.link {
  border-left: none;
  padding-left: 0;
  margin-left: 0;
}

.img-container {
  :first-child {
    @media screen and (max-width: 767px){
      display: none;
    }
  }
  :nth-child(2) {
    @media screen and (min-width: 768px){
      display: none;
    }
  }
}