/* ===NON-SEMANTIC HELPER CLASES=== */
.page-hider,
.gallery-hider,
.breadcrumb-hider {
	width: 100%;
	height: 100%;
	position: absolute;
	display: none;
	top: 0;
	left: 0;
}
.page-hider {
  z-index: 70;
  background: url(../images/bg/bg-transparent-black.png) repeat;
}
.gallery-hider{
  z-index: 70;
  background: url(../images/bg/bg-transparent-green-2.png) repeat;
}
.breadcrumb-hider {
  z-index: 99;
  background: url(../images/bg/bg-transparent-grey.png) repeat;
}
.right 	{ float: right}
.left 	{ float: left; }
.hide 	{ display: none; }

.block { display: block; }
.clearfix:after { height: 0; content: "."; display: block; clear: both; visibility: hidden; }
.clearfix 		{ display: block; zoom: 1; clear: both;}
.fixed {
  position: fixed;
  top: 0;
  z-index: 1001;
}
#top {
    display: none;
    right: 3%;
    bottom: 4%;
    cursor: pointer;
    width: 50px;
    height: 50px;
    overflow: hidden;
    display: block;
    position: fixed;
    z-index: 2000;
    border: 2px solid #fff;
    background: rgba(0, 28, 37, 0.7) url("../images/ui/ui-arrows-sprites.png") no-repeat;
    background-position: 50% -661px;
    @include nav-circle;
    -webkit-transition-duration: 0.30s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-duration: 0.30s;
    -moz-transition-timing-function: ease-out;
}
#top:hover {
    background: $darkBlue url("../images/ui/ui-arrows-sprites.png") no-repeat;
    background-position: 50% -661px;
}