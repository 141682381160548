.view-event-exhibitions .view-filters,
.view-exhibitions .view-filters,
.view-event-calendar .view-filters,
.fc-button-today,
.fc-header-right * {
  display:none;
}
.fullcalendar .fc-header tbody { border-top: 0 solid #ccc; }
.fullcalendar .ui-widget-content.fc-other-month * { height: auto;}
/* Datepicker - START
*********************************/
.feature-block-calendar .ui-corner-all {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.feature-block-calendar .ui-widget-content {
  border: 0px solid #aaaaaa;
}
#main #ui-datepicker-div {
  font-size: 100%;
  background: none;
  border-right:none;
  border-bottom:none;
  z-index: 9999;
}
#main .ui-datepicker {
  width: auto;
  background: #001c25;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: em(14);
  padding: 10px;
}
#main .ui-datepicker .ui-datepicker-header { padding: 0 0; }
#main .ui-datepicker th,
#main .ui-datepicker td {
  padding: 0 7px;
  text-align: left;
  line-height: 22px;
  font-size: em(14);
}
#main .ui-datepicker th {
  color: $lightRed;
  font-weight: 700;
  padding-bottom: 5px;
}
#main .ui-datepicker tbody {
  border: 0;
}
#main .ui-datepicker td span,
#main .ui-datepicker td a {
  padding: 0;
  color: #798589;
  text-align: left;
}
#main .ui-datepicker .ui-state-default {
  color: #fff;
  background: none;
  border: 0;
  opacity: 1;
}
#main .ui-datepicker .ui-state-disabled {
  opacity: 1;
  filter:Alpha(Opacity=100);
}
#main .ui-datepicker td a {
  color: #fff !important;
  font-weight: 800 !important;
  font-size: em(15) !important;
}
#main .ui-datepicker td.ui-datepicker-unselectable {
  font-weight: 400 !important;
}
#main .ui-datepicker .ui-state-default.ui-state-active,
#main .ui-datepicker .event a {
  color: $lightRed !important;
}
#main .ui-datepicker .event a {
  opacity:0.8;
  filter:Alpha(Opacity=80);
}
#main .ui-datepicker .event:hover a {
  opacity:1;
  filter:Alpha(Opacity=100);
}
#main .ui-datepicker .ui-widget-header {
  background: none;
  border: 0;
  color: #fff;
  padding: 0;
  margin: 0 0 10px 0;
}
#main .ui-datepicker .ui-datepicker-title {
  cursor:pointer;
  margin: 5px 60px 0 7px;
  text-align: left;
  overflow: hidden;
  font: 700 23px/28px "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
}

#main .ui-datepicker .ui-datepicker-title a, {
  color: #fff;
  margin: 0;
}

#main .ui-datepicker .ui-datepicker-title .ui-datepicker-year {
  display: none;
}
#main .ui-datepicker .ui-datepicker-prev,
#main .ui-datepicker .ui-datepicker-next {
  background: url(../images/ui/ui-arrows-sprites-2.png) no-repeat 0 -416px;
  width: 20px;
  height: 28px;
  right: 0;
  top: 2px;
  cursor: pointer;
}
#main .ui-datepicker .ui-datepicker-prev {
  background-position: 0 0;
  left: auto;
  right: 33px;
}
#main .ui-datepicker .ui-datepicker-prev:hover {
  background-position: 0 -82px;
  border: 0;
}
#main .ui-datepicker .ui-datepicker-next:hover {
  background-position: 0 -498px;
  border: 0;
}
#main .ui-datepicker .ui-datepicker-prev span,
#main .ui-datepicker .ui-datepicker-next span{
  display: none;
}
/* Hide switch grid option for mobile and tablet */
.page-events-list .sort-bar ul.view-switch a#gridview{
  @media (min-width: em(1024)) {
    display: block;
  }
}
.view-event-calendar .sort-bar ul li#tid-87,
.view-event-calendar .sort-bar ul li#tid-86 {
  border-bottom: none;
  padding: 0 0 0 0;
  display: none;
  visibility: hidden;
}
.view-event-calendar .filter-dropdown {
  width: 28%;
}
.section-event-calendar .view-event-calendar .view-footer a {
  float: right;
  display: inline-block;
  font-size: em(14);
  line-height: em(14);
  padding: 0.375em em(40) 0.313em em(20);
  border-radius: em(10);
  border: 1px solid #8b8885;
  background: url(../images/ui/ui-icons-sprites.png) no-repeat;
  background-position: 96% -1624px;
  margin-top: 15px;
  color: #fff;
  &:hover, &:focus {
    background-color: #fff;
    color: $red;
  }
}
#page .fullcalendar .tooltip .tooltip-holder a,
#page .fullcalendar .tooltip .tooltip-holder .event-title a,
#page .fullcalendar .tooltip .tooltip-holder .event-more-link a {
  color: #fff;
  &:hover { color: #fff; }
}
#page .fullcalendar .tooltip .tooltip-holder .event-time { color: $grey;}
#page .fullcalendar .fc-header-left, #page .fullcalendar .fc-header-right {
  display: none;
}
#page .fullcalendar table thead tr th.ui-widget-header{
  border:none;
  background:none;
  text-align:left;
  font-family: "proxima-nova", Helvetica,Arial,sans-serif;
  line-height: 1.14286em;
  font-size: 0.750em;
  letter-spacing: 0.125em;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 0 5px 10px;
}
#page .fullcalendar .ui-widget-content.fc-other-month{
  background:#cbc7c2;
}
#page .fullcalendar .ui-widget-content{
  border-color:#22383e;
}
#page .fullcalendar .ui-widget-content .fc-day-number{
  padding:9px 12px 11px 12px;
}
#page .fullcalendar .ui-widget-content .fc-day-content{
  padding:0px 2px 6px 2px;
}
#page .fullcalendar .ui-widget-content.fc-other-month *{
  visibility:hidden !important;
}
#page .fullcalendar .fc-day-number{
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  color:#6b6865;
  font-size: em(26);
  line-height:26px;
  font-weight:300;
  float:left;
}
#page .fc-event,
#page .fc-event a{
  font-size: em(12);
  line-height:16px;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  font-weight:bold;
  margin:0;
  border:none;
  background:none;
  color:#1f273a;
}
#page .fc-event-default.featured,
#page .fc-event-default.featured .fc-event-skin,
#page .fc-event-default.featured .fc-event-time,
#page .fc-event-default.featured a,
#page .fc-event-default.deadline,
#page .fc-event-default.deadline .fc-event-skin,
#page .fc-event-default.deadline .fc-event-time,
#page .fc-event-default.deadline a {
  border-style: solid;
  color: #FFFFFF;
  font-size: em(12);
  line-height:16px;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  font-weight:bold;
  border:none;
  background:none;
}
#page .fullcalendar .fc-event-default.featured,
#page .fullcalendar .fc-event-default.deadline{
  background:#ff0606;
  border-radius:12px;
}
#page .fullcalendar .fc-event.few-days{
  background:#e6e3df;
  border-radius:8px;
  .fc-event-title {
    color: $darkBlue;
  }
}
#page .fullcalendar .fc-event-inner{
  margin:0;
  padding:0;
  border:none;
  background:none;
}
#page .fullcalendar .fc-event-default,
#page .fullcalendar .fc-event-default,
#page .fullcalendar .fc-event-skin,
#page .fullcalendar .fc-event-default .fc-event-time,
#page .fullcalendar .fc-event-default a{
  background: none;
  color: #1f273a;
  border:none;
  padding:0;
  margin:0;
  font-size: em(14);
  line-height:16px;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  font-weight:bold;
}
#page .fullcalendar .fc-event{
  padding:0px 8px;
  margin-bottom:7px;
}
#page .fullcalendar .fc-event.few-days,
#page .fullcalendar .fc-event.featured,
#page .fullcalendar .fc-event.deadline{
  padding: 5px 0px 5px 8px;
  margin-left: 2px;
}
#page .fullcalendar .fc-event .fc-event-time{
  color:#c60000;
  padding-right:2px;
}
#page .fullcalendar .fc-event.featured .fc-event-time,
#page .fullcalendar .fc-event.deadline .fc-event-time{
  color:#001c25;
}
#page .fc-event-default .fc-event-inner:hover{
  color:#da3a2b;
}
#page .fc-event-default.featured .fc-event-inner:hover,
#page .fc-event-default.deadline .fc-event-inner:hover{
  color:#fff;
}
#page .fc-event-default .fc-event-inner:hover .fc-event-title{
  text-decoration:underline;
}
#page .fullcalendar .fc-button.fc-button-prev,
#page .fullcalendar .fc-button.fc-button-next {
  visibility: hidden;
  display: none;
}
.nav-event-list { display: none;}
.cal-nav-control a,
.cal-list-nav-control a {
  text-indent: -9999px;
  cursor: pointer;
  width:35px;
  height:49px;
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-color: $red;
  border:none;
  border-radius:0;
  position: absolute;
  top:50%;
  margin:0;
  padding:0;
}
.cal-nav-control a {
  @media (min-width: em(320)) {
    background-color: transparent;
    top: -25px;
  }
  @media (min-width: em(768)) {
    background-color: $red;
    top:50%;
  }
}
.cal-list-nav-control a {
  @media (min-width: em(320)) {
    top: 50px;
  }
  @media (min-width: em(768)) {
    top:50%;
  }
}
.cal-nav-control a.next,
.cal-list-nav-control a.next{
  @media (min-width: em(320)) {
    background-position: 8px -579px;
    right:0;
  }
  @media (min-width: em(768)) {
    right:-7.22%;
  }
}
.cal-list-nav-control a.prev{
  @media (min-width: em(320)) {
    background-position: 7px -492px;
    right: 40px;
    left:inherit;
  }
  @media (min-width: em(768)) {
    left:-7.22%;
  }
}
.cal-nav-control a.prev{
  @media (min-width: em(320)) {
    background-position: 7px -492px;
    left:0;
  }
  @media (min-width: em(768)) {
    left:-7.22%;
  }
}
.cal-list-nav-control a.next:hover,
.cal-list-nav-control a.prev:hover,
.cal-list-nav-control a.next:focus,
.cal-list-nav-control a.prev:focus,
.cal-nav-control a.next:hover,
.cal-nav-control a.prev:hover,
.cal-nav-control a.next:focus,
.cal-nav-control a.prev:focus{
  background-color: #001c25;
}
#page .fullcalendar .fc-button.ui-state-default .ui-icon-circle-triangle-w,
#page .fullcalendar .fc-button.ui-state-default .ui-icon-circle-triangle-e{
  background:none;
}
#page .fullcalendar,
#page .events-list{
  position:relative;
}
.page-events-list .block-page-title h1,
.page-event-calendar .block-page-title h1{
  @media (min-width: em(320)) {
    background:url(../images/ui/ui-icons-sprites.png) no-repeat;
    background-position: 0 -112px;
    padding-left:24px;
    line-height: 20px;
    font-size: 0.750em;
    letter-spacing: 0.125em;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    font-family: "proxima-nova", Helvetica,Arial,sans-serif;
  }
  @media (min-width: em(768)) {
    background-position: 0 -133px;
  }
}
.page-events-list #block-events .slide-control h1,
.page-event-calendar #block-events .slide-control h1 {
  background: none;
}
/* Event List */
.page-events-list h1{
  margin-bottom:0;
}
.events-list .event-title,
.event-calendar .event-title {
  a:link, a:visited {
    color: #fff;
  }
  a:hover, a:focus {
    color: #fff;
    text-decoration: underline;
  }
}
.events-list a.button {
  border-color: #8b8885;
  color: #fff;
  &:hover {
    color: $red;
  }
}
.page-student-life-the-what.page-views .events-list .event-title {
  a:link, a:visited {
    color: $darkBlue;
  }
  a:hover, a:focus {
    color: $red;
  }
}
.page-student-life-the-what.page-views .events-list a.button {
  border-color: $darkBlue;
  color: $darkBlue;
  &:hover {
    color: $red;
  }
}
.fc-header-title h2,
h2.current-month{
  @media (min-width: em(320)) {
    font-size: em(60);
    color:$grey;
    font-weight:300;
    font-family: "proxima-nova",Helvetica,Arial,sans-serif;
    text-transform:uppercase;
    margin:0;
    padding:0;
    width: 55%;
    font-size:45px;
    line-height:50px;
  }
  @media (min-width: em(768)) {
    width: 100%;
    font-size: em(60);
    line-height:60px;
  }
}
.fc-header-title{
  left: 0;
  position: absolute;
  top: -185px;
  @media (min-width: em(320)) {
     top: -225px;
  }
  @media (min-width: em(768)) {
     top: -185px;
  }
}
body.page-events-list .sort-bar .filter-dropdown ul,
body.page-event-calendar .sort-bar .filter-dropdown ul{
  z-index:1000;
  width: 285px;
}
.events-list .node-event .date-event,
.event-row.node-event .date-event{
  word-wrap: break-word;
  @media (min-width: em(320)) {
    background:#817e7b;
    color:#fff;
    font-family: "proxima-nova",Helvetica,Arial,sans-serif;
    text-align:center;
    float:left;
    padding:9px 5px 10px 5px;
    border-right:1px solid #fff;
    vertical-align: middle;
    display: block;
    position: relative;
    width: 13%;
    font-size: em(18);
    font-weight:400;
    min-height: 110px;
    @include box-sizing;
  }
  @media (min-width: em(768)) {
    width: 18%;
    font-size: em(38);
    font-weight:300;
    min-height: 100px;
  }
  @media (min-width: em(1024)) {
    font-size: em(58);
    min-height: 92px;
  }
}
.events-list .node-event .event-name,
.event-row.node-event .event-name{
  @media (min-width: em(320)) {
    display:inline-block;
    padding: 15px 15px;
    font-size: em(14);
    width: 87%;
    @include box-sizing;
  }
  @media (min-width: em(768)) {
    width: 63%;
    min-height: 100px;
    border-right: 1px solid #fff;
  }
  @media (min-width: em(1024)) {
    width: 68.55%;
    min-height: 92px;
  }
}
.events-list .node-event .event-name h5{
  color: #da3a2b;
  margin-bottom: 10px;
}
.events-list .node-event .event-more-link,
.event-row.node-event .event-more-link{
  width:120px;
  float:right;
  text-align:center;
  padding:30px 0;
  @media (min-width: em(320)) {
    display: none;
  }
  @media (min-width: em(768)) {
    display: block;
    width:17%;
  }
  @media (min-width: em(1024)) {
    width:13%;
  }
}
.events-list .views-row,
.events-list .views-row.views-row-last,
.event-row.node-event {
  @media (min-width: em(320)) {
    border-top:1px solid #fff;
    border-right:1px solid #fff;
    border-left:1px solid #fff;
  }
  @media (min-width: em(768)) {
    border-right:none;
    border-left:none;
  }
}
.events-list .views-row.views-row-last,
li.event-row:last-child{
  border-bottom:1px solid #fff;
}
.events-list .event-name .event-time,
.event-row .event-name .event-time {
  font-size: em(14);
  line-height:18px;
  letter-spacing:0.02em;
  font-weight:bold;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
}
.events-list .event-name .event-time {
  color:$grey;
}
/* The WHAT - Calendar List - START */
.page-student-life-the-what .events-list .event-time,
.event-row .event-name .event-time {
  color: $darkRed;
}
.page-student-life-the-what #breadcrumb {
  background: $deepCyan;
  -moz-box-shadow: inset 0 0 10px #135750;
  -webkit-box-shadow: inset 0 0 10px #135750;
  box-shadow: inset 0 0 10px #135750;
}
.page-student-life-the-what .events-list {
  @media (min-width: em(320)) {
    margin-top: 20px;
  }
  @media (min-width: em(768)) {
    margin-top: 35px;
  }
}
/* Block Spotlight Callout */
.page-event-calendar #block-spotlight-slide,
.page-events-list #block-spotlight-slide{
  margin-top: 20px;
  h2, h5, .block-label { color: #fff; }
  p { color: #d8d4cf; }
  .field-body { border-color: $darkBlue;}
  .field-body a { color: #fff;}
  .field-body a:hover { color: $red;}
}


@media screen and (max-width: em(767)) {
  .sort-bar ul.view-switch li {
    width: 100%;
    #gridview, .active {
      display: none;
    }
  }
}