.node-type-faculty {
  .node-faculty {

    .description {
      @media (min-width: em(1024)) {
        max-width: 75%;
      }

      h5 {
        margin-bottom: .9rem;
        font-size: .9375rem;
        line-height: 1.2rem;
      }

      h6 {
        margin-bottom: .75rem;
        font-size: .8rem;
        line-height: 1.1rem;
        letter-spacing: .1875em;
        font-style: normal;
        color: $darkBlue;
        text-transform: uppercase;
        font-family: proxima-nova,Helvetica,Arial,sans-serif;
      }
    }

    .field-profile-photo {
      img {
        display: block;
        max-width: 100%;
      }

      margin-bottom: 1.9rem;
    }

    .faculty-title-label {
      margin-bottom: 1rem;
    }

    .node-title {
      margin-bottom: 1.2rem;
    }

    .contact-info {
      margin-bottom: 1.875rem;
    }

    .field-body {
      padding: 0 0 0.625em 0;

      p:first-child {
        padding-top: 0;
      }
    }
  }
}

.node-type-faculty-page {
  .block-page-title {
    display: none;
  }
}

.node-faculty-page {
  #main {
    padding-bottom: 80px;
  }

  h1 {
    padding: 5px 0 20px 0;
    margin-bottom: 40px;
    border-bottom: 3px solid #cfcfcf;
  }

  h5 {
    margin-bottom: .5rem;
    font-size: .9375rem;
    line-height: 1.2rem;
    letter-spacing: em(3);
  }

  h6 {
    margin-bottom: .75rem;
    font-size: .8rem;
    line-height: 1.1rem;
    letter-spacing: .1875em;
    font-style: normal;
    color: $darkBlue;
    text-transform: uppercase;
    font-family: proxima-nova,Helvetica,Arial,sans-serif;
  }

  h2 {
    margin: 0 0 .6rem 0;
  }

  .field-profile-photo {
    img {
      display: block;
      max-width: 100%;
    }

    margin-bottom: 1.5rem;
  }

  .profile-box {
    .description {
      padding: 0;

      &.col-two-thirds {
        @media (min-width: em(768)) {
          padding: 0 0 0 3rem;
        }
      }
    }

    ul.contact-info {
      padding-top: 0;

      li {
        margin-top: .25rem;

        strong {
          padding-right: .25rem;
        }

        strong, a:link, a:visited {
          font-size: 1rem;
        }
      }
    }
  }

  .node-faculty {
    padding: em(25);

    .field-body {
      padding: .625rem 0 0;
    }

    .button.more {
      color: black;
      margin-top: 1rem;
      text-decoration: none;
    }

    .callout-header {
      padding: 0 0 em(40) 0;
      margin-bottom: em(60);
      border-bottom: 3px solid #cfcfcf;
      float: left;
      .text,
      .image { float: left; margin: 0;}
      .text {
        p {
          line-height: 1.250em;
          font-size: 1.250em;
          font-weight: 700;
          margin-bottom: 0.313em;
          color: $darkBlue;
          font-size: 1.250em;
          padding: 0;
        }
      }

      &.text-only {
        padding: 0 0 em(40) 0;
        margin-bottom: 0;
        border-bottom: none;
        float: none;
      }
    }

    .region-sidebar-second {
      @media (min-width: em(320)) {
        border-top: 2px solid $red;
      }
      @media (min-width: em(1024)) {
        border-top: none;
      }
    }

    ul.field-title {
      padding: em(6) 0;

      li {
        margin: em(10) 0;
      }
    }
  }
}
